// * * * * * * * * * *
// Libraire React
import React from "react";
// * * * * * * * * * *
// Libs
import AudioWrapper from "./libs/AudioWrapper"
// * * * * * * * * * *
// Style
import styled, { keyframes } from "styled-components";
// * * * * * * * * * *
// Composant externe
import CoddingDialog from "./CoddingDialog";
import SHA1 from "sha1";
// * * * * * * * * * *
// Thème de codding man
import theme from "../assets/theme.mp3";
// * * * * * * * * * *
// Fonction débloqué
import HarlemShake from "./HarlemShake";
import RedCarpet from "./RedCarpet";
import ManyClicks from "./ManyClicks";
import manager from "./libs/WarriorManager";
import Quest from "./libs/Quest";
import Clue from "./libs/Clue";
import play from "./Play";

/**
 * Quête principale - Mission 1
 * Codding se matérialise pour ensuite apparaître devant nous sur un thème rétro épique !
 * Puis disparaît en débutant toutes les quêtes
 */
export default class CoddingAnimation extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            coddingPieces: [], // tableau contenant l'information des balles de coddingman
            color: ["#cd4442", "#1787B9", "#FFFFFF"], // les couleurs disponibles pour les balles
            finishedCountdown: false, // booléen qui dit si le countdown de hover est fini ou pas
            mounting: null, // timer pour créer les balles
            centering: null, // timer pour centrer les balles
            divCentered: 0, // nombre de balles centrées
            shouldFlash: false, // booléen qui se met à true quand on doit flash
            audioFade: null, // timer pour baisser le volume petit à petit jusqu'à zéro
            numDialog: 0 //index du dialogue actuelle de coddingMan
        };
        this.reference = React.createRef(); // référence au conteneur
        this.audio = new AudioWrapper(theme); // audio thème
        this.audio.loop = true;
        this.harlem = null; // conteneur du harlem
        this.redcarpet = null; // conteneur du redcarpet
        this.manyclicks = null; // conteneur du manyclicks
        this.flashActive = false;
        this.finishDispear = false;
        this.quest = new Quest(
            "Début d'une grande aventure",
            [SHA1("Armure du débutant"), SHA1("Console de jeu")],
            [
                new Clue(
                    1,
                    "Que l'aventure commence",
                    "Après son apparition, Gonzague ne vous laisse aucune indication. Cependant vous entendez comme un frisson dans l'air qui vous appelle. Vous en êtes désormais sûr, des tas de secrets se cachent par ici.",
                    2
                ),
                new Clue(
                    2,
                    "Nostalgie dans l'air",
                    "Vous avez envie de jouer à des jeux rétro comme Gradius ou Contra, des jeux d'époque très durs. Pas moyen de les terminer sans cheat code...",
                    1
                ),
                new Clue(
                    3,
                    "La popularité n'attend plus que vous",
                    "Vous êtes sous le feu des projecteurs, plus qu'à cliquer sur tout ce qui est rouge comme le tapis de Cannes. Attention, les paparazzix vous suivent au moindre pas...",
                    1
                ),
                new Clue(
                    4,
                    "En un clic",
                    "Combien d'Artisans compte Coddity ?",
                    1
                )
            ]
        );
        //si codding man est apparu, on fait apparaitre directement les easter egg qui ont besoin de la reference de CoddingAnimation
        if (localStorage.getItem("coddingMan1")) {
            this.harlem = new HarlemShake(this.reference, this.newDialog); // conteneur du harlem
            //on active ces easter egg ici, car on est sûr que les éléments à cliquer seront présent
            //vu que le render de l'app est déjà appelé
            if (!this.redcarpet) {
                this.redcarpet = new RedCarpet(this.newDialog);
            }
            if (!this.manyclicks) {
                this.manyclicks = new ManyClicks(this.newDialog);
            }
        }
    }
    //cette fonction est appelée à chaque que l'on finit une quête, elle va déclencher le nouveau dialogue de CoddingMan
    newDialog = () => {
        const listQuest = JSON.parse(localStorage.getItem("quest"));
        this.setState({ numDialog: 1 });
        if (listQuest.length === 4) {
            this.setState({ shouldFlash: false });
            setTimeout(() => {
                this.setState({ shouldFlash: true });
                setTimeout(() => {
                    this.removeDiv();
                    this.audio.play();
                    this.setState({ coddingAppear: true });
                }, 4000);
            }, 1000);
        }
    };

    /**
     * Appelé lors du début de l'animation
     * Instancie le timer de création de balle (crée l'information d'une balle toutes les .05s)
     */
    start = () => {
        this.setState({
            mounting: setInterval(this.createDiv, 50)
        });
    };

    /**
     * Instancie l'information d'une balle si le tableau contenant l'information des balles ne dépasse pas 50 informations
     * Sinon stop le timer de création et lance celui de centrage
     * Une information contient: position initiale (x et y) ainsi que la couleur de la balle
     */
    createDiv = () => {
        if (this.state.coddingPieces.length < 50) {
            this.setState({
                coddingPieces: [
                    ...this.state.coddingPieces,
                    {
                        color: this.state.color[Math.floor(Math.random() * 3)],
                        x: window.innerWidth * Math.random(),
                        y: window.innerHeight * Math.random()
                    }
                ]
            });
        } else {
            clearInterval(this.state.mounting);
            this.setState({
                centering: setInterval(this.centerDiv, 40)
            });
        }
    };

    /**
     * Si le nombre de div centrées est inférieur à la taille du tableau d'informations des balles,
     * centre la balle à l'index du nombre de balles centrées
     * Sinon passe lance le signal du flash et la suppression des balles tandis que débute le thème.
     */
    centerDiv = () => {
        if (this.state.divCentered < this.state.coddingPieces.length) {
            const newTab = this.state.coddingPieces;
            newTab[this.state.divCentered].x = window.innerWidth / 2 - 15;
            newTab[this.state.divCentered].y = window.innerHeight / 2 - 15;
            this.setState(prevState => {
                return {
                    coddingPieces: newTab,
                    divCentered: prevState.divCentered + 1
                };
            });
        } else {
            clearInterval(this.state.centering);
            this.setState({ shouldFlash: true });
            setTimeout(() => {
                this.removeDiv();
                this.audio.play();
                this.setState({ coddingAppear: true });
            }, 4000);
        }
    };

    /**
     * Réinitialise le tableau de balles
     */
    removeDiv = () => {
        this.setState({
            coddingPieces: []
        });
    };

    /**
     * Lorsque les propriétés passées par le parent changent, si start diffère du précédent et qu'il est actuellement à true,
     * alors on lance l'animation en appelant start()
     */
    componentDidMount = () => {
        document.addEventListener("starteasters", this.start);
    };

    /**
     * Renvoie un flashdiv (voir plus bas) puis au bout de 8s, l'enlève
     */
    flash = () => {
        this.flashActive = true;
        setTimeout(() => {
            this.setState({ shouldFlash: false, flashActive: false });
        }, 8000);

        return <FlashDiv width={window.innerWidth} height={window.innerHeight} />;
    };

    /**
     * Lorsque l'animation est fini, lance un flash, et abaisse le volume du thème petit à petit
     * Puis crée un nouveau personnage s'il n'est pas déjà instancié
     * Instancie les fonctions accessibles à partir de cette mission
     * Au bout de quelques secondes, reset tout, lance la fonction stop du parent
     * Plutard, coupe la musique
     */
    handleDisapearring = () => {
        if (!this.finishDispear) {
            this.finishDispear = true;
            setTimeout(() => {
                this.setState({
                    shouldFlash: true,
                    coddingPieces: [],
                    finishedCountdown: false,
                    centering: null,
                    divCentered: 0,
                    audioFade: setInterval(() => {
                        this.audio.volume > 0.02 ? (this.audio.volume = this.audio.volume - 0.015) : (this.audio.volume = 0);
                    }, 100)
                });
            }, 800);
            if (!localStorage.getItem("coddingMan1")) {
                //lorsque le dialogue est fini, on le signale dans le local storage
                localStorage.setItem("coddingMan1", true);
                //on prend la récompense de la quete
                manager.completed(this.quest);
                //liste vide qui va stocker les quêtes compléter
                let listQuest = [];
                listQuest.push(this.quest);
                //On stocke la liste des quêtes completé dans le local storage
                localStorage.setItem("quest", JSON.stringify(listQuest));
                if (!this.harlem) {
                    this.harlem = new HarlemShake(this.reference, this.newDialog);
                }
                if (!this.redcarpet) {
                    this.redcarpet = new RedCarpet(this.newDialog);
                }
                if (!this.manyclicks) {
                    this.manyclicks = new ManyClicks(this.newDialog);
                }
                //on affiche dans le DOM  le composant REACT qui permet de joueur aux casse brique
                //pour y jouer, l'utilisateur doit entre le mot "brique" ou "brick" sur l'écran
                play();
            }
            setTimeout(() => {
                clearInterval(this.state.audioFade);
                this.audio.pause();
                this.audio.volume = 1;
                this.audio.currentTime = 0;
                this.setState(prevState => ({
                    coddingAppear: false,
                    numDialog: prevState.numDialog + 1
                }));
                this.finishDispear = false;
            }, 6000);
        }
    };

    render() {
		//si l'utilisateur est sur la page principale du site
		//alors on peut lancer le easter egg
        if (localStorage.getItem("coddingMan1") && this.props.isOnSectionPage) {
            delete this.redcarpet;
            this.redcarpet = null;
            this.redcarpet = new RedCarpet(this.newDialog);
		}
		//si l'utilisateur a dejà commencé la quête redCarpet et quitte la page
		//alors on lui indique qu'il ne peut plus faire la quête
        if (localStorage.getItem("coddingMan1") && !this.props.isOnSectionPage && this.redcarpet.isTouchedOnce) {
            console.log("Vous quittez la scène, vous allez devoir recommencer la quête");
        }
        const coddingMount = this.state.coddingPieces.map((element, key) => {
            return <CoddingDivPieces key={key} color={element.color} x={element.x} y={element.y} />;
        });
        const flashing = this.state.shouldFlash ? this.flash() : null;
        return (
            <div style={{ zIndex: 3 }} ref={this.reference}>
                {coddingMount}
                {flashing}
                {this.state.coddingAppear && <CoddingDialog onDisappear={this.handleDisapearring} num={this.state.numDialog} />}
            </div>
        );
    }
}

/**
 * Style des balles de coddingman
 */
const CoddingDivPieces = styled.div`
    top: ${props => props.y}px;
    left: ${props => props.x}px;
    background-color: ${props => props.color};
    border-radius: 40px;
    position: fixed;
    width: 40px;
    height: 40px;
    z-index: 3;
    transition: top 4s cubic-bezier(1, 0.07, 0.97, 0.27), left 4s cubic-bezier(1, 0.07, 0.97, 0.27);
    -moz-box-shadow: inset 5px -5px 5px 0 rgba(0, 0, 0, 0.5);
    -webkit-box-shadow: inset 5px -5 5px 0 rgba(0, 0, 0, 0.5);
    -o-box-shadow: inset 5px -5 5px 0 rgba(0, 0, 0, 0.5);
    box-shadow: inset 5px -5px 5px 0 rgba(0, 0, 0, 0.5);
`;

/**
 * 0% transparent
 * 60% blanc opaque
 * 100% transparent
 */
const flashAnim = keyframes`
    0% {
        background-color: rgba(255,255,255,0);
    }

    60% {
        background-color: rgba(255,255,255,1);
    }

    100% {
        background-color: rgba(255,255,255,0);
    }
`;

/**
 * Div au dessus de tous éléments de la page qui flash avec l'animation au dessus
 * Pour voir la courbe d'animation, aller sur le site http://cubic-bezier.com/
 */
const FlashDiv = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    width: ${props => props.width}px;
    height: ${props => props.height}px;
    background-color: rgba(255, 255, 255, 0);
    z-index: 5;
    animation: ${flashAnim} 8s cubic-bezier(0.81, 0.01, 0.26, 1.02) 1;
`;
