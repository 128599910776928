import React from "react";
import "./GameBoard.css";
export default class GameBoard extends React.Component {
    chooseColors = () => {
        switch (this.props.HP) {
            case 1:
                return "#1686b8";
            case 2:
                return "#cd4442";
            case 3:
                return "silver";
            default:
                return "#333";
        }
    };

    render() {
        return (
            <div
                className='brick'
                style={{
                    position: "absolute",
                    top: this.props.coordY,
                    left: this.props.coordX,
                    backgroundColor: this.chooseColors()
                }}
            />
        );
    }
}
