import React from 'react';

const QualiteSvg = () => (

   <div className="logotech">
      <svg
         width="40%"
         viewBox="-4 -10 140 140"
         version="1.1">
      <g
         id="layer1"
         transform="translate(-8.3793118,-6.1537364)">
         <g
            id="g441"
            transform="matrix(0.35277777,0,0,-0.35277777,68.218009,42.268126)">
            <path
               d="m 0,0 c -4.276,-2.121 -7.125,-4.213 -10.705,-7.489 -4.339,-3.976 -9.024,-4.882 -15.133,-2.95 -4.425,1.399 -7.005,5.902 -6.277,10.674 0.716,4.681 4.498,9.298 11.534,8.051 -0.79,0.024 -9.643,5.902 -17.363,-3.973 -7.725,-9.883 -4.316,-28.138 20.884,-32.232 13.278,-2.153 42.334,0.596 42.334,18.64 C 25.274,1.049 7.865,3.896 0,0"
               id="path443" />
         </g>
         <g
            id="g445"
            transform="matrix(0.35277777,0,0,-0.35277777,83.756776,42.268126)">
            <path
               d="m 0,0 c 4.276,-2.121 7.129,-4.213 10.709,-7.489 4.335,-3.976 9.023,-4.882 15.129,-2.95 4.425,1.399 7.005,5.902 6.277,10.674 -0.716,4.681 -4.498,9.298 -11.534,8.051 0.79,0.024 9.647,5.902 17.363,-3.973 7.725,-9.883 4.32,-28.138 -20.881,-32.232 -13.277,-2.153 -42.334,0.596 -42.334,18.64 C -25.271,1.049 -7.861,3.896 0,0"
               id="path447" />
         </g>
         <g
            id="g449"
            transform="matrix(0.35277777,0,0,-0.35277777,70.506584,24.117287)">
            <path
               d="m 0,0 c 0,0 -7.787,-6.533 -15.562,-6.533 -7.771,0 -16.215,6.533 -16.215,6.533 0,0 7.242,-2.195 15.016,-2.195 C -8.989,-2.195 0,0 0,0"
               id="path451" />
         </g>
         <g
            id="g453"
            transform="matrix(0.35277777,0,0,-0.35277777,103.02381,14.831717)">
            <path
               d="m 0,0 c 1.681,0.5 2.58,1.39 2.58,2.796 0,8.863 -35.471,21.803 -79.217,21.803 -43.75,0 -79.22,-12.94 -79.22,-21.803 0,-1.406 0.899,-2.296 2.584,-2.796 8.903,8.182 39.839,17.336 76.636,17.336 C -39.84,17.336 -8.904,8.182 0,0"
               id="path455" />
         </g>
         <g
            id="g457"
            transform="matrix(0.35277777,0,0,-0.35277777,102.47742,15.90589)">
            <path
               d="m 0,0 c -8.725,-2.594 -39.034,5.491 -75.088,5.491 -36.054,0 -66.363,-8.085 -75.088,-5.491 -1.645,-1.514 -2.529,-2.993 -2.529,-4.374 0,-8.684 34.75,2.746 77.617,2.746 42.863,0 77.613,-11.43 77.613,-2.746 C 2.525,-2.993 1.646,-1.514 0,0"
               id="path459" />
         </g>
         <g
            id="g461"
            transform="matrix(0.35277777,0,0,-0.35277777,75.988081,12.857008)">
            <path
               d="M 0,0 C 36.797,0 67.732,-8.247 76.637,-5.598 67.732,2.584 36.797,11.738 0,11.738 -36.797,11.738 -67.733,2.584 -76.637,-5.598 -67.733,-8.247 -36.797,0 0,0"
               id="path463" />
         </g>
         <g
            id="g465"
            transform="matrix(0.35277777,0,0,-0.35277777,73.077664,21.10213)">
            <path
               d="m 0,0 c 0,0 4.117,-28.93 0.187,-36.707 -3.93,-7.776 -4.883,-12.5 -1.891,-12.756 2.992,-0.256 3.433,0.961 5.311,0.876 1.88,-0.085 2.082,-1.194 4.643,-1.368 H 8.246 c 2.565,0.174 2.767,1.283 4.643,1.368 1.882,0.085 2.323,-1.132 5.311,-0.876 2.992,0.256 2.039,4.98 -1.891,12.756 C 12.379,-28.93 16.192,0 16.192,0 Z"
               id="path467" />
         </g>
         <g
            id="g469"
            transform="matrix(0.35277777,0,0,-0.35277777,94.004547,24.117287)">
            <path
               d="m 0,0 c 0,0 -10.705,-8.549 -18.48,-8.549 -7.771,0 -16.352,8.549 -16.352,8.549 0,0 8.581,8.547 16.352,8.547 C -10.705,8.547 0,0 0,0"
               id="path471" />
         </g>
         <g
            id="g473"
            transform="matrix(0.35277777,0,0,-0.35277777,40.353398,61.886557)">
            <path
               d="m 0,0 v -54.476 c 0,0 -64.461,4.996 -64.461,-42.219 v -33.594 c 0,0 3.223,-0.274 9.768,0.6 3.097,0.416 9.214,-4.268 11.44,-6.284 0.557,-0.5 1.145,-1.217 0.907,-1.867 -0.556,-1.539 -3.646,-2.555 -7.791,-0.959 -1.237,0.475 -4.37,1.669 -5.584,1.397 -4.592,-1.037 -4.739,-3.378 -5.428,-5.822 -1.012,-3.562 -0.583,-5.623 1.56,-9.234 0.88,-1.482 2.732,-6.827 5.954,-10.483 1.222,-1.389 6.273,-2.704 8.02,-4.012 1.985,-1.49 1.93,-2.606 1.814,-3.631 -0.148,-1.363 -4.133,-0.412 -6.203,-0.154 -2.133,0.269 -5.674,0.909 -7.16,2.238 -2.315,2.068 -3.985,5.758 -3.985,5.758 0,0 1.031,-5.608 2.168,-7.727 1.284,-2.393 5.958,-3.859 7.213,-5.297 1.371,-1.562 0.655,-3.63 -0.404,-3.478 -1.533,0.218 -7.911,0.811 -10.44,2.42 -2.374,1.512 -6.884,9.028 -6.884,9.028 0,0 -0.338,-3.582 0.296,-7.59 0.396,-2.53 5.841,-5.813 5.603,-7.64 -0.217,-1.683 -0.731,-2.478 -2.043,-2.12 -1.938,0.53 -7.662,3.667 -9.079,4.996 -2.229,2.091 -3.338,8.488 -3.338,8.488 0,0 -0.276,-2.276 -0.218,-7.28 0.035,-2.941 3.587,-7.219 3.332,-9.417 -0.2,-1.682 -1.565,-0.955 -1.97,-1.361 -1.21,-1.212 -8.775,8.472 -10.589,19.255 -0.711,4.222 -0.914,12.106 1.207,19.367 1.137,3.889 1.447,9.215 5.035,13.999 12.258,16.344 -41.672,84.755 23.61,127.113 C -38.646,2.346 0,0 0,0"
               id="path475" />
         </g>
         <g
            id="g477"
            transform="matrix(0.35277777,0,0,-0.35277777,109.329,81.021293)">
            <path
               d="m 0,0 v 54.477 c 0,0 64.464,-4.995 64.464,42.219 l -0.763,31.997 c 0,0 -8.487,-4.593 -16.191,-4.541 -4.429,0.034 -10.64,4.159 -12.791,6.81 -5.9,7.263 -0.756,8.172 5.148,5.901 1.906,-0.736 6.234,-4.043 7.981,-3.978 3.172,0.118 6.436,1.655 7.413,3.678 1.393,2.892 3.292,6.96 -3.518,9.531 -4.611,1.743 -11.977,-9.289 -18.788,-8.229 -3.284,0.512 8.67,14.873 9.897,15.869 3.704,3.028 14.355,6.137 14.355,6.137 0,0 -0.588,2.467 -5.355,7.029 -3.323,3.182 -7.487,2.448 -12.678,3.855 -2.879,0.78 -5.231,4.403 -3.277,5.452 1.95,1.051 12.678,1.063 13.912,0.754 8.207,-2.027 15.741,-8.17 15.741,-8.17 0,0 -0.608,5.511 -2.701,8.773 -1.362,2.118 -11.351,3.233 -11.729,7.85 -0.081,1.008 -0.4,1.832 0.985,2.063 5.448,0.898 17.904,-2.512 19.57,-4.949 2.327,-3.395 5.004,-7.22 5.778,-9.948 0.988,-3.486 2.137,-4.125 2.137,-4.125 0,0 0.63,3.549 0.182,6.994 -0.311,2.413 -1.657,4.229 -2.494,6.811 -0.634,1.949 -0.382,4.539 1.436,4.994 1.315,0.327 1.809,-0.858 2.268,-1.741 1.059,-2.024 3.125,-4.806 4.737,-8.761 0.739,-1.83 1.385,-3.911 1.786,-6.287 1.004,-5.971 5.28,-20.891 -5.647,-35.415 C 69.573,118.724 122.936,52.345 57.654,9.987 38.648,-2.344 0,0 0,0"
               id="path479" />
         </g>
         <path
            d="M 43.396883,99.33466 H 106.28693 V 97.51962 H 43.396883 Z"
            id="path481" />
         <path
            d="M 43.396883,88.017547 H 106.28693 V 86.283291 H 43.396883 Z"
            id="path483" />
         <path
            d="M 106.28693,101.25659 H 43.396883 v 1.89512 h 62.890047 z"
            id="path485" />
         <path
            d="M 106.28693,112.65343 H 43.396883 v 1.81539 h 62.890047 z"
            id="path487" />
         <path
            d="m 43.396883,110.73185 h 62.890047 v -1.92123 H 43.396883 Z"
            id="path489" />
         <g
            id="g491"
            transform="matrix(0.35277777,0,0,-0.35277777,43.396777,116.39107)">
            <path
               d="m 0,0 v -5.296 l 178.271,0.051 V 0 Z"
               id="path493" />
         </g>
         <g
            id="g495"
            transform="matrix(0.35277777,0,0,-0.35277777,106.287,84.361323)">
            <path
               d="m 0,0 v 66.483 h -14.769 c 0,0 -10.965,-59.923 -74.367,-59.923 V 6.509 c -63.398,0 -74.368,59.923 -74.368,59.923 h -14.767 V 0 Z"
               id="path497" />
         </g>
         <path
            d="m 43.396883,106.88904 h 62.890047 v -1.81539 H 43.396883 Z"
            id="path499" />
         <path
            d="M 43.396883,91.754169 H 106.28693 V 89.940186 H 43.396883 Z"
            id="path501" />
         <path
            d="M 43.396883,95.598036 H 106.28693 V 93.676455 H 43.396883 Z"
            id="path503" />
      </g>
      </svg>
   </div>

);

export default QualiteSvg;