import React from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { 
    faServer,
    faHeartbeat, 
    faWrench, 
    faCheckCircle, 
    faFlask,
    faPauseCircle,
    faParachuteBox,
    faGhost
    } from '@fortawesome/free-solid-svg-icons';
import { faOsi } from '@fortawesome/free-brands-svg-icons';

const Project = ({ SvgComponent, name, type, url, children }) => {

    const ProjectStatus = {
        commercialise : { className: "blue", svgIcon: faParachuteBox, text: "Commercialisé" },
        assemblage : { className: "lightred", svgIcon: faWrench, text: "En cours d'assemblage" },
        prototype : { className: "blue", svgIcon: faCheckCircle, text: "Prototype fonctionnel" },
        tobeimproved : { className: "lightred", svgIcon: faFlask, text: "Prototype à améliorer" },
        prod : { className: "blue", svgIcon: faServer, text: "En prod" },
        live : { className: "blue", svgIcon: faHeartbeat, text: "En live" },
        suspendu : { className: "grey", svgIcon: faPauseCircle, text: "Suspendu" },
        opensource : { className: "blue", svgIcon: faOsi, text: "Open source" },
        pasencore : { className: "lightred", svgIcon: faGhost, text: "Même pas encore sur l'Etabli" },
    };

    const { className, svgIcon, text } = ProjectStatus[type];
    
    
    return (
        <div className="center">
            <SvgComponent />
            <h1>
                {name}<br />
                <span className={className}>
                    <FontAwesomeIcon style={{fontSize:"1rem"}} icon={svgIcon}/>
                    &nbsp;{text}{url ? <a href={url}> ici</a> : ""}</span>
            </h1>
            <p className="pad-text">
                {children}
            </p>
        </div>
    );
}

export default Project;