import React from "react";
import "./GameBoard.css";

export default class Rectangle extends React.Component {
    render() {
        const taille = 100 + this.props.height;
        return (
            <div
                className='rect'
                style={{
                    position: "absolute",
                    bottom: 60,
                    left: this.props.coord,
                    width: taille
                }}
            />
        );
    }
}
