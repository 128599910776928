import React from 'react';

const DanilSvg = () => (
	<div className="logotech">
		<svg width="40%" 	viewBox="0 0 900 900" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fillRule="evenodd" clipRule="evenodd" d="M722.35 808.256C639.14 711.882 588.817 586.318 588.817 449C588.817 312.256 638.72 187.168 721.308 90.9533C645.876 33.8649 551.893 0 450 0C352.888 0 262.96 30.7618 189.43 83.0727C276.128 180.289 328.817 308.492 328.817 449C328.817 590.097 275.686 718.785 188.339 816.148C262.069 868.933 352.407 900 450 900C552.362 900 646.742 865.822 722.35 808.256Z" fill="#A8A8A8" fillOpacity="0.4"/>
			<path d="M35.8164 535C314.816 483 450.816 314 809.316 233.5C822.816 248.833 853.416 304.6 867.816 405C609.816 417 324.316 575 126.816 721C79.3164 677.5 51.8164 625.5 35.8164 535Z" fill="#00D2AD" fillOpacity="0.67"/>
			<path d="M35.8173 523C231.317 491 599.817 509 855.817 561C879.317 496 883.993 415.655 869.817 417C506.317 451.5 426.317 449.5 34.3171 386C19.317 437.5 26.3173 486.5 35.8173 523Z" fill="#E31A7B" fillOpacity="0.67"/>
			<path d="M196.816 786.001C394.816 594.5 529.316 596.5 716.816 775.001C725.816 771.834 763.116 740.4 786.316 704C506.316 546.5 386.816 523.5 126.866 721.479C140.366 745.979 162.816 766.501 196.816 786.001Z" fill="#F5BF2C" fillOpacity="0.67"/>
			<path d="M33.8164 386C181.316 398 586.316 598.5 785.816 704C797.15 697.833 826.416 663.8 852.816 577C539.816 522.5 302.316 300 95.8164 224C53.8164 279.5 43.3164 298.5 33.8164 386Z" fill="#F5BF2C" fillOpacity="0.67"/>
			<path d="M196.817 115C378.817 258.5 591.317 255.5 716.817 119C803.817 194.5 809.817 233.5 809.817 233.5C533.817 304 297.317 311 95.8181 224C133.297 165.808 153.752 149.446 195.083 116.387L196.817 115Z" fill="#00D2AD" fillOpacity="0.67"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M457.853 0.068571C362.837 -1.58993 269.735 26.8743 191.89 81.3817L209.097 105.956C281.752 55.0827 368.648 28.5161 457.329 30.064C546.011 31.6119 631.927 61.1949 702.762 114.573L720.816 90.6141C644.922 33.4232 552.869 1.72707 457.853 0.068571Z" fill="#B1AFAF"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M38.578 534.453C59.1431 634.638 115.558 723.877 197.237 785.427L179.183 809.386C91.6688 743.439 31.2248 647.826 9.19075 540.485L38.578 534.453Z" fill="#808080"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M31.3117 285.074C0.895752 362.29 -7.57494 446.412 6.83614 528.142L36.3804 522.932C22.93 446.652 30.836 368.137 59.2242 296.069C87.6125 224.002 135.377 161.188 197.237 114.573L179.183 90.614C112.904 140.559 61.7277 207.859 31.3117 285.074Z" fill="#808080"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M884.051 568.743C897.763 518.619 902.69 466.499 898.613 414.693L868.705 417.047C872.511 465.399 867.912 514.044 855.114 560.827L884.051 568.743Z" fill="#E31A7B"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M735.508 797.83C802.999 742.431 852.916 668.593 879.173 585.318L850.561 576.297C826.055 654.02 779.466 722.935 716.474 774.641L735.508 797.83Z" fill="#F5BF2C"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M457.853 897.504C362.837 899.163 269.735 870.699 191.89 816.191L209.097 791.617C281.752 842.49 368.648 869.057 457.329 867.509C546.011 865.961 631.927 836.378 702.762 783L720.816 806.959C644.922 864.15 552.869 895.846 457.853 897.504Z" fill="#B1AFAF"/>
			<path fillRule="evenodd" clipRule="evenodd" d="M896.352 402.072C883.876 283.378 824.761 174.501 732.011 99.3937L713.131 122.708C799.698 192.809 854.872 294.427 866.516 405.207L896.352 402.072Z" fill="#00D2AD"/>
		</svg>
	</div>
);

export default DanilSvg;
