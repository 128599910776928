import React from "react";
import "./Parametre.css";
import retour from "../../asset/image/retour.png";
import retourHover from "../../asset/image/retourHover.png";

export default class Param extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            hoverExit: false
        };
    }
    timeToString = () => {
        if (this.props.timer <= 9) {
            return "00:0" + this.props.timer.toString();
        } else {
            return "00:" + this.props.timer.toString();
        }
    };
    render() {
        return (
            <div>
                <div className='param'>
                    <p style={{ margin: 0 }}>Niveau : {this.props.level} </p>
                    <p style={{ margin: 0 }}>
                        Vie{this.props.life > 1 && "s"} restante{this.props.life > 1 && "s"} : {this.props.life}
                    </p>
                </div>
                <div className='profile'>
                    <p style={{ margin: 0 }}>Score : {this.props.score}</p>

                    {this.props.timer !== null && <p style={{ margin: 0 }}>Temps : {this.timeToString()}</p>}
                    {this.props.viseur > 0 && (
                        <p style={{ margin: 0 }}>
                            Sniper{this.props.viseur > 1 && "s"} restant{this.props.viseur > 1 && "s"} :{" "}
                            {this.props.viseur}
                        </p>
                    )}
                    <img
                        src={!this.state.hoverExit ? retour : retourHover}
                        alt='exit'
                        onMouseOver={() => {
                            this.setState({ hoverExit: true });
                        }}
                        onMouseLeave={() => {
                            this.setState({ hoverExit: false });
                        }}
                        onClick={this.props.returnMenu}
                    />
                </div>
            </div>
        );
    }
}
