// -------------------------------------------------------------------------
// Librairies
import React, { Component } from "react";
import { BrowserRouter as Router, Route } from "react-router-dom";
// -------------------------------------------------------------------------
// Style
import "./css/App.css";
import "./css/nav-menu.css";
import "./css/Sections.css";
import "./css/fonts.css";
import "./css/lightgrid.css";
import "./css/style.css";

// -------------------------------------------------------------------------
// Composants externes
import Header from "./components/Header.js";
import Footer from "./components/Footer.js";
import Sections from "./components/Sections";
import Atelier from "./components/Atelier";
import Contact from "./components/Contact";
import Join from "./components/Join";
import gameStarter, { load } from "./easteregg/GameStarter";
import ScrollTop from "./components/ScrollToTopIcon";
import Mentions from "./components/MentionsLegales";
import AutoScrollToTop from "./AutoScrollToTop";

// -------------------------------------------------------------------------
import play from "./easteregg/Play";
/**
 * Application principale rendu par index.js (qui lui-même est appelé par le compilateur)
 * Sert de routeur de navigation.
 * Router écoute l'URI du client et appelle la route correspondante (cf. https://reacttraining.com/react-router/web/api/BrowserRouter)
 * Route appelle le composant correspondant à sa propriété "component" (cf. https://reacttraining.com/react-router/web/api/Route)
 * Les composants Header, et Footer (cf. dossier 'components') sont communs à toutes les pages et ne seront pas rechargés au cours de la navigation
 */
class App extends Component {
    constructor(props) {
        super(props);
        //localStorage.clear();
        //si coddingMan est apparu, alors on ne va pas le faire apparaitre à nouveau
        if (localStorage.getItem("coddingMan1")) {
            this.appears = true;
        } else {
            this.appears = false;
        }
        //propriété qui vont contenir la référnce des easter egg
        this.redCarpet = null;
        this.ManyClicks = null;
    }
    componentDidMount() {
        const element = document.getElementById("easteregg-gamestarter");
        //si on a pas encore vu cooding man, alors on peut appeler le gamestarter
        if (element !== null && !this.appears) {
            gameStarter(element);
        }
        //cette fonction permet d'afficher les éléments utiles pour les easter eggs
        load();
        if (localStorage.getItem("coddingMan1")) {
            play();
        }
    }

    render() {
        return (
            <div className='App'>
                <Router>
                    <div>
                        <AutoScrollToTop>
                            <Header />
                            <Route exact path={"/"} component={Sections} />
                            <Route path={"/atelier"} component={Atelier} />
                            <Route path={"/contact"} component={Contact} />
                            <Route path={"/mentions-legales"} component={Mentions} />
                            <Route path={"/devenir-artisan"} component={Join} />
                            <ScrollTop />
                            <Footer />
                        </AutoScrollToTop>
                    </div>
                </Router>
            </div>
        );
    }
}

export default App;
