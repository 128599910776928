import React from 'react';

export default function LabelloSvg(){
    return(
        <div className="logotech">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 199.614 199.614" xmlnsXlink="http://www.w3.org/1999/xlink"
            enableBackground="new 0 0 199.614 199.614"
            width="40%" height="40%">
                <g>
                    <g>
                    <path d="M133.611,13.567c-0.001,0.002-0.001,0.004-0.002,0.007c-0.47,1.927-5.312,18.347-33.802,18.347
							c-28.49,0-33.332-16.42-33.802-18.347c-0.001-0.002-0.001-0.004-0.002-0.007c-0.018-0.073-0.031-0.132-0.037-0.16
							c-0.008-0.036-0.004-0.072-0.01-0.108c-0.041-0.228-0.064-0.456-0.065-0.684c0-0.046-0.005-0.091-0.004-0.136
							c0.007-0.258,0.042-0.513,0.098-0.764c0.013-0.058,0.03-0.115,0.046-0.172c0.06-0.221,0.137-0.438,0.235-0.647
							c0.016-0.035,0.028-0.071,0.045-0.105c0.117-0.232,0.261-0.451,0.423-0.66c0.016-0.021,0.026-0.045,0.042-0.066
							c0.023-0.029,0.054-0.048,0.078-0.076c0.057-0.067,0.107-0.138,0.17-0.201C67.415,9.389,76.739,0,88.059,0
							c5.772,0,9.443,1.143,11.748,2.436C102.112,1.143,105.783,0,111.555,0c11.32,0,20.645,9.389,21.036,9.788
							c0.062,0.063,0.112,0.135,0.17,0.201c0.024,0.028,0.054,0.048,0.078,0.076c0.017,0.021,0.026,0.045,0.042,0.066
							c0.163,0.208,0.307,0.427,0.423,0.659c0.018,0.035,0.029,0.071,0.046,0.106c0.098,0.209,0.175,0.425,0.234,0.646
							c0.016,0.058,0.033,0.114,0.046,0.172c0.056,0.251,0.091,0.505,0.098,0.763c0.001,0.046-0.004,0.091-0.004,0.137
							c-0.002,0.228-0.024,0.456-0.065,0.683c-0.007,0.036-0.003,0.073-0.01,0.108C133.642,13.435,133.629,13.494,133.611,13.567z
								M192.116,103.53l-2.812,15.666c-3.36,18.718-26.644,39.531-45.562,51.667c-20.972,13.453-41.425,27.875-41.629,28.019
							c-0.691,0.488-1.499,0.732-2.307,0.732s-1.615-0.244-2.307-0.732c-0.204-0.144-20.663-14.569-41.629-28.019
							c-18.918-12.137-42.202-32.95-45.562-51.667L7.498,103.53c-0.252-1.406,0.264-2.84,1.355-3.762c1.092-0.923,2.593-1.193,3.935-0.709
							c0.083,0.029,8.657,3.062,16.751,3.062c5.807-0.001,9.634-1.521,11.375-4.52c5.233-9.013,5.772-40.081,5.522-53.294
							c-1.958-1.929-5.898-5.932-8.755-9.639c-3.858-5.005-11.75-19.286-12.084-19.892c-1.067-1.935-0.364-4.368,1.57-5.435
							c1.933-1.066,4.367-0.364,5.435,1.569c0.079,0.144,7.928,14.35,11.415,18.872c3.201,4.153,8.159,8.958,8.992,9.757
							c0.005,0.004,0.01,0.007,0.015,0.011c4.201,3.654,16.361,13.785,22.098,16.731c4.286,2.201,14.751,2.984,23.116,2.984
							c0.919,0,1.463-0.011,1.485-0.011c0.055-0.002,0.111-0.002,0.167,0c0.022,0,0.565,0.011,1.485,0.011
							c8.365,0,18.831-0.783,23.116-2.984c5.742-2.948,17.898-13.077,22.098-16.731c0.225-0.196,0.472-0.354,0.728-0.492
							c1.699-1.666,5.609-5.599,8.28-9.064c3.486-4.522,11.336-18.729,11.415-18.872c1.068-1.935,3.501-2.637,5.435-1.569
							s2.637,3.5,1.57,5.435c-0.334,0.605-8.226,14.887-12.084,19.892c-2.859,3.709-6.803,7.715-8.76,9.643
							c-0.24,13.333,0.321,44.113,5.526,53.077c1.741,2.999,5.568,4.52,11.375,4.52c8.138,0,16.667-3.031,16.752-3.063
							c1.345-0.482,2.844-0.212,3.935,0.71S192.369,102.124,192.116,103.53z M89.353,122.403c-1.563-1.563-4.095-1.563-5.657,0
							c-0.03,0.029-4.678,2.786-19.063,1.186c-17.27-1.919-34.208-8.182-34.377-8.245c-2.071-0.769-4.374,0.28-5.146,2.35
							c-0.772,2.069,0.279,4.373,2.348,5.146c0.727,0.271,18.008,6.67,36.291,8.701c3.524,0.392,6.586,0.556,9.245,0.556
							c11.278,0,15.278-2.955,16.359-4.036C90.915,126.498,90.915,123.965,89.353,122.403z M174.91,117.693
							c-0.771-2.069-3.075-3.118-5.146-2.35c-0.169,0.063-17.107,6.326-34.377,8.245c-14.385,1.601-19.033-1.156-19.063-1.186
							c-1.563-1.563-4.095-1.563-5.657,0c-1.562,1.562-1.562,4.095,0,5.656c1.081,1.081,5.081,4.036,16.359,4.036
							c2.658,0,5.721-0.164,9.245-0.556c18.283-2.031,35.564-8.43,36.291-8.701C174.631,122.066,175.682,119.763,174.91,117.693z"/>
                    </g>
                </g>
            </svg>					
        </div>
    );
}