import React from 'react';

import Form from "./Form";

const Contact = () => (
    <div className="section">
        <div className="content">
            <h1 className="sectiontitle"><span className="red">D</span>es questions ?</h1>
            <div className="row">
                <p className="center">Une question ? Passez nous voir dans le 11ème,
                écrivez-nous à <a href="mailto:contact@coddity.com" target="_blank" rel="noopener noreferrer">contact@coddity.com</a> ou
                complétez le formulaire suivant :</p>
                <Form typeFormulaire="contact" />
            </div>
        </div>
    </div>
);

export default Contact;
