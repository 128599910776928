import React from 'react';

const DogeSvg = () => (
	<div className="logotech">
		<svg width="40%" viewBox="0 0 512 512" xmlns="http://www.w3.org/2000/svg">
			<path d="m104.4005585 464.8573608c-2.5067902 6.76828-4.5661774
			12.3285522-6.884613 18.5882263-6.1061554-1.7572327-11.8286209-3.4041443-18.6327591-5.3622742
			-.1979599-3.3196716-.4162292-6.9796753-.6305923-10.5743103-2.9187164-2.1094055-6.5987549-3.8318787
			-8.956131-6.6577454-5.839119-6.9994812-10.8578224-14.6797485-16.6301575-21.7398376-3.5596695
			-4.3538513-6.8585968-9.1578369-14.0451355-8.8080139-2.5536728.1242676-5.7383728-3.0839844
			-7.8657742-5.4597778-10.5983505-11.8355713-18.7038593-24.6434326-13.5342789-41.3385925-4.5244389
			-3.7358398-8.7020273-7.1852722-13.0331345-10.7615051 3.1595097-6.2552795 5.7109861-11.3067627
			8.7509832-17.3254395-4.5508307-.6607361-8.1858253-1.1885071-12.9389657-1.8786621
			4.4491434-4.9837952 8.4323692-9.4456787 13.1322079-14.7102661-4.0307236-1.3666992-6.8872347
			-2.3352661-11.58356-3.9276428 3.1448145-1.6670837 4.5967469-2.6271362 6.1820264-3.2451782
			8.1244545-3.1673279 8.7317152-6.1863098 2.4554272-13.7366333 11.3938704-6.9725647.5179043-16.3291321
			2.4484129-24.5564575.9051876-3.8576965-.7344542-8.3124695-1.3825035-13.88797 6.7685432 1.0174561
			12.0992794 1.8187866 19.6729088 2.9572449-2.5023308-3.8508911-3.4083843-6.6245728-5.3192692-7.9550476
			-10.0476379-6.9956665-15.9677258-22.0235443-12.6618452-33.2155914 4.9071379-16.6130524 10.8342791
			-21.4269257 28.0681419-20.1677856 6.3826599.4662933 11.4032364-.8468323 12.8094711-6.3198547
			1.9560242-7.6127167 8.0683365-8.8930969 13.0827408-8.8335114 8.517746.1011505 18.9373856-7.1797333
			24.8387604 3.7983093 5.6066132-1.2304688 10.8340378-3.6520081 15.8463364-3.2677307 29.1464615
			2.2345581 52.9091263 16.7301788 74.9861069 34.5249634 2.1072693 1.6985168 4.2227631 4.7214661
			4.3897247 7.2575836.663208 10.0728912 3.3722229 21.2556.0665894 30.0516205-4.875 12.9720154-7.1330566
			25.9439697-7.8595428 42.1141663-2.0134125-3.6878967-2.7114716-4.9664612-4.5003815-8.2431335-6.06073
			7.0762024-11.5919037 13.5341187-17.4798279 20.4085999 3.235199 1.4689636 6.4844666 2.9443054
			10.8432922 4.9234619-3.4504089 3.8753662-6.1213379 6.8752747-10.2784271 11.544342 6.8917999
			1.7264709 12.1488037 3.0434265 17.3935089 4.3572693-2.9944153 11.7615967-4.9463348
			12.3153076-20.4935913 3.1691589 5.2170105 9.6467285 8.8882294 16.4351807 12.8433838
			23.7486267 6.035965-1.5957031 12.158905-6.8200684 20.5906677-2.8502197-3.2819977
			8.3049622-16.3473969 11.9755249-9.6671906 24.1542053 3.1827545-.760498 6.6185608-1.5814209
			12.6226501-3.0159607-3.8486328 3.1719055-5.8826752 4.8483276-8.5867004 7.0769348 3.7296448 
			7.763916 11.4987183 9.7439575 18.5918274 11.2279053 8.4725342 1.7727051 16.220871 3.410553
			18.0694275 15.7364197 13.5189972-13.8437805 29.4171448-12.4489746 43.8971405-14.8560486
			14.2629395-2.3709717 28.0819397-9.6168213 43.191864-5.4461365 7.0362244-10.1262207
			18.5503235-2.3778687 27.6075439-6.4936829 3.4064026-1.5479126 10.8546143 5.3505249
			16.0411377 8.994812.428009.3007202-1.8896484 7.1759338-3.7463379 7.6246338-11.2302856
			2.7137451-14.0484924 9.8140259-11.0935974 19.8457642-7.9262085 1.1943665-14.697998
			2.2147522-22.7059021 3.4214172.9687195 2.9961243 2.0403748 6.3105164 3.7246399
			11.5197144-13.1368408-.9091797-26.3423462-3.2729187-40.2380676 1.8940125 2.6125793 2.6647034
			4.8166504 4.9127502 7.0700989 7.2111511-1.4019165.7115173-2.7010803 1.6646729-2.9389038
			1.4487915-6.4645996-5.8687134-14.0121765-5.9333496-22.3583374-6.3655701-8.9453278-.4632263
			-17.7780151-1.5664062-26.8019714-.6591187-4.9913788.5018921-8.8307343 1.3995667-12.4329681
			5.7655334-5.7051849 6.9147949-12.8651733 11.1980286-22.4845734 5.5032043-2.3886414-1.4141235
			-6.3265686-.2112122-10.9071198-.2112122-5.3878937-14.9341125-21.4282532-3.7201538-31.8413086
			-11.9254761-5.0728302 5.662262-9.703064 10.7939148-14.2914276 15.9627686-4.2392426 4.7754822
			-9.2050171 5.0021667-13.7310257.4346313-1.7408066-1.7567749-3.0315399-3.9960632-4.8950119
			-5.584137-1.7804718-1.5172424-4.0387726-2.4737854-6.7623367-4.0734253-.2015076 3.591217-.3847733
			6.8570557-.5680161 10.1228638zm22.3178405-11.6922302c-1.2011566 1.2654724-2.402298 2.5309143
			-3.6034546 3.7963562 1.963913 1.4117737 3.9278259 2.8235474 5.8917389 4.2352905.8905334-1.0127869
			1.7810822-2.0255127 2.6716003-3.0382996-1.653305-1.6644286-3.3065795-3.3289184-4.9598846
			-4.9933471zm375.7206574-330.3711624c3.069458-1.7799988 5.1394653-2.9804382 8.4008484
			-4.8717194.3491211 3.4621964 1.9002686 7.0718613.7301636 9.1474457-7.701355 13.6609726-19.2186584
			24.1081772-30.4312744 34.8184891-8.1609192 7.7953186-17.6864319 12.699585-28.5372925
			15.2960052-.9372559.2242737-2.2094727-.0164795-2.813385.5277252-11.8557434 10.683609-26.837677
			4.4447479-40.1842041 6.9185791-7.2171631 1.3377075-15.5861816-2.7545471-23.2776794-4.9503174
			-10.6206665-3.0319977-21.1304626-6.4727936-31.601593-9.9946442-2.6877441-.904007-6.6783447
			-2.2620392-7.3055725-4.2735901-4.6252136-14.833313-18.0763855-16.9592285-29.7619324-21.7688293
			-1.2558594-.5168915-2.1803589-1.8390656-3.9285583-3.377121 7.4411926-9.7098236 16.8925476
			-17.1204376 28.0446167-20.6946335 6.2365723-1.9988098 8.555603-5.2463455 10.3296204-10.4500809
			11.2545471-.0366821 20.4628296-4.6051407 29.4771729-10.7107086 12.2593689-8.3035583
			25.2010803-15.5997543 36.0275879-22.2144012 33.5669248 8.8620835 63.9018247 18.4193345
			84.8314817 46.5978013zm-67.809845-12.8411026c-17.7796631-11.8608475-29.1851807-12.255249-40.70755
			-.129303-5.8080139 6.1122131-9.8919373 14.131218-13.611908 21.8398438-1.7275085 3.5798492-2.0203552
			8.9718933-.6133728 12.6412964 1.5201721 3.9645386 5.2695618 8.1190491 9.0794678 9.6975861
			1.769104.7329712 6.1976624-4.2208252 8.9891052-7.0260773 1.2200928-1.2261047 1.2008972-3.6028748
			2.0132446-5.3307648 4.6073608-9.8000031 9.2960205-19.5617523 13.5293884-28.4396744 8.0413819
			-.6987534 14.9536744 4.5511093 21.3216248-3.2529068zm-346.4127121 6.9586868c3.7069473-6.7971878
			8.2986069-12.084259 9.3529282-18.0015793 1.1294785-6.3391953-2.5187073-13.5105972-1.4664536
			-19.8872299 1.4431839-8.7456284 5.3492126-17.0848503 8.4991608-26.4922752-8.9922104-.5801926
			-10.2860794 10.2002869-19.2142105 12.7120934.322876-4.0745277-.3706055-8.1832581 1.0542603
			-11.3340302 15.9060822-35.1728592 59.5555267-29.4995251 75.5047607-8.6931686 3.4962463 4.5609856
			7.7498322 8.5414238 11.3716888 12.4814186-2.4222565 37.0013008-27.2721405 54.6963921-56.9057465
			67.9609985-3.6514435 1.6344604-9.0723801.3424988-13.3347549-.8271942-2.2952576-.6298828-3.5560303
			-4.4038162-5.8648224-5.7935181-2.1244888-1.2787819-5.0273208-1.2644462-8.9968109-2.125515zm23.10849
			-55.7060356c8.5774155 2.6017532 10.396431-1.3463821 10.7388916-8.3415337-6.8542557-2.0882568
			-9.1081848 1.358883-10.7388916 8.3415337zm42.8129654-.864872c-4.3417206-3.4872131-7.938385
			-3.325737-10.5850067 1.2121696-.538559.9234276.614212 4.0361824 1.6879578 4.4931145 5.2236633
			2.2229157 7.6824798-.5112075 8.8970489-5.7052841zm-70.510498 47.4829331c6.188385-10.283226
			-3.2300034-18.8229446-2.6489029-28.4797516-7.2239075 10.3989029 2.1839981 18.9397964 2.6489029 28.4797516z" />
		</svg>
		
	</div>
);

export default DogeSvg;
