import React, { useState } from 'react';

const EquipeSvg = () => {
   
  const [active, setActive] = useState(false);
  
    return(
      <div className="logo-tech">
        <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
          width="40%" viewBox="0 0 300 300" enableBackground="new 0 0 300 300" xmlSpace="preserve"
          onMouseEnter={() => setActive(true)}
          onMouseLeave={() => setActive(false)}
          >
          <g>
            <g>
              <path d="M153.977,86.122l-3.818,3.489l-5.04-4.746c-1.046-0.176-2.071-0.399-3.072-0.687c-2.083,0.943-4.564,2.231-7.127,3.745
              c0.187-1.104,0.179-2.22-0.04-3.307c1.139-0.664,2.262-1.276,3.361-1.854c-5.881-2.69-10.645-7.375-13.447-13.198
              c-1.55,0.945-3.07,1.907-4.521,2.889c-2.122-1.215-4.166-2.374-5.774-3.285c-2.712,5.873-7.413,10.623-13.245,13.402
              c4.772,2.481,16.384,8.915,19.61,11.193c0.706,0.493,1.467,0.835,2.248,1.105v47.033c0,4.041,0.649,7.886,1.861,11.398
              c-0.035,0.369-0.057,0.747-0.057,1.135v10.561h0.069c-0.004,0.152-0.044,0.295-0.044,0.447l0.004,92.889
              c0,6.333,5.133,11.466,11.465,11.466s11.463-5.129,11.463-11.466l-0.002-92.889c0-0.152-0.041-0.295-0.045-0.447h1.723
              c-0.004,0.152-0.045,0.295-0.045,0.447l-0.006,92.889c0,6.333,5.133,11.466,11.462,11.466l0,0c6.333,0,11.466-5.129,11.466-11.462
              l0.005-92.889c0-0.156-0.041-0.299-0.046-0.451h0.134l0.012-11.998c0,0,2.027-2.721,2.027-12.569
              c0-5.288,0.534-25.016,0.534-25.016l0.054-21.569c-0.119-0.059-16.406-8.986-16.406-8.986L153.977,86.122z"/>
              <path d="M36.272,100.677c6.566,7.363,14.839,14.244,24.418,17.243c1.363,0.427,2.732,0.743,4.102,0.997
              c0.155,5.962,0.44,17.47,0.44,21.329c0,9.846,2.028,12.562,2.028,12.562l0.008,12.006h0.176c-0.004,0.153-0.044,0.305-0.044,0.461
              l0.002,92.893c0,6.329,5.133,11.462,11.465,11.462c6.333,0,11.463-5.133,11.463-11.466l-0.002-92.889
              c0-0.156-0.041-0.308-0.046-0.461h1.727c-0.006,0.153-0.047,0.305-0.047,0.461l-0.006,92.889
              c-0.002,6.333,5.131,11.466,11.463,11.466c6.331,0,11.464-5.133,11.464-11.462v-92.893c0-0.156-0.041-0.303-0.045-0.461h0.028
              v-10.561c0-0.387-0.024-0.765-0.055-1.138c1.211-3.51,1.86-7.358,1.86-11.396V93.803c-4.121,0.333-16.535-8.676-18.059-9.37
              c-1.006,0.292-2.91,0.955-3.952,1.127l-5.04,3.864L85.8,85.932c-8.348,3.806-18.383,8.545-18.52,8.611
              c-1.458,0.689-3.015,1.039-4.619,1.039c-1.775,0-3.488-0.466-5.016-1.268l-0.036,0.037c-2.07-1.661-4.066-3.582-5.953-5.632
              c-1.557-1.692-3.04-3.473-4.428-5.268L36.272,100.677z"/>
              <path d="M62.35,92.5c1.292,0,2.605-0.278,3.847-0.87c0.128-0.059,8.172-3.859,15.85-7.386
              c-6.862-1.905-12.641-6.388-16.251-12.346c-3.989,1.863-6.987,3.279-7.304,3.429c-4.503,2.129-6.428,7.502-4.304,12.005
              C55.729,90.591,58.971,92.5,62.35,92.5z"/>
              <circle cx="150.161" cy="58.097" r="24.986"/>
              <circle cx="89.902" cy="58.097" r="24.986"/>
              <path d="M217.781,84.123c7.754,3.561,15.984,7.448,16.113,7.507c1.246,0.59,2.559,0.866,3.854,0.866
              c3.372,0,6.611-1.905,8.15-5.165c2.127-4.5,0.209-9.878-4.297-12.005c-0.336-0.159-3.598-1.701-7.879-3.697
              C230.207,77.588,224.538,82.118,217.781,84.123z"/>
              <circle cx="210.419" cy="58.097" r="24.986"/>
            </g>
            <path d="M252.838,83.514c-1.389,1.794-2.871,3.575-4.428,5.268c-1.887,2.05-3.883,3.971-5.953,5.632l-0.035-0.037
            c-1.528,0.802-3.242,1.268-5.017,1.268c-1.604,0-3.161-0.35-4.618-1.039c-0.138-0.066-10.173-4.805-18.521-8.611l-3.821,3.492
            l-5.04-3.864c-1.042-0.172-2.946-0.835-3.952-1.127c-0.374,0.17-1.406,0.843-2.808,1.753c-1.151-0.53-3.351-0.85-3.51-1.633
            c1.138-0.664,2.261-1.276,3.36-1.854c-5.881-2.69-10.645-7.375-13.447-13.198c-1.549,0.945-3.068,1.907-4.52,2.889
            c-2.121-1.215-4.164-2.374-5.775-3.285c-2.708,5.873-7.413,10.623-13.244,13.402c4.771,2.481,16.389,8.915,19.61,11.193
            c0.706,0.493,1.466,0.835,2.247,1.105l0.027-0.014v46.928c0,4.037,0.648,7.886,1.859,11.397c-0.03,0.373-0.055,0.75-0.055,1.137
            v10.561h0.028c-0.004,0.158-0.045,0.305-0.045,0.461v92.893c0,6.33,5.133,11.463,11.464,11.463c6.332,0,11.465-5.133,11.463-11.467
            l-0.006-92.889c0-0.156-0.041-0.307-0.047-0.461h1.727c-0.005,0.154-0.046,0.305-0.046,0.461l-0.002,92.889
            c0,6.334,5.13,11.467,11.463,11.467c6.332,0,11.466-5.133,11.466-11.463l0.002-92.893c0-0.156-0.041-0.307-0.045-0.461h0.176
            l0.008-12.006c0,0,2.029-2.717,2.029-12.562c0-3.859,0.284-15.367,0.439-21.329c1.37-0.254,2.738-0.57,4.102-0.997
            c9.58-2.999,17.853-9.88,24.419-17.243L252.838,83.514z"/>
          </g>
          <path className={ active ? "rightarm rightarmmove":"rightarm" } d="M248.959,90.196c-2.524,4.387-1.014,9.992,3.375,12.518l0,0c4.389,2.522,9.993,1.012,12.517-3.378
          c0,0,3.363-4.373,4.341-5.932c3.283-5.23,11.613-21.806,11.613-21.806c2.525-4.389,1.013-9.994-3.376-12.517l0,0
          c-4.388-2.525-9.992-1.013-12.517,3.376L248.959,90.196z"/>
          <path className={ active ? "leftarm leftarmmove":"leftarm" } d="M51.108,90.134c2.524,4.387,1.013,9.992-3.375,12.518l0,0c-4.389,2.522-9.994,1.012-12.517-3.378
          c0,0-3.363-4.373-4.34-5.932c-3.283-5.23-11.614-21.806-11.614-21.806c-2.525-4.389-1.013-9.994,3.376-12.517l0,0
          c4.388-2.525,9.992-1.013,12.517,3.376L51.108,90.134z"/>
        </svg>
      </div>
    );
}

export default EquipeSvg;