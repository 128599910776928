import React from 'react';

export default function GrowdditySvg(){
	return(
		<div className="logotech">
			<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 459.944 459.944"
			xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" 
			height="40%" width="40%"
			enableBackground="new 0 0 459.944 459.944" 
	 		xmlSpace="preserve">
				<g>
					<path d="M441.346,205.161L240.578,4.393c-5.857-5.857-15.355-5.857-21.213,0L18.598,205.161c-4.29,4.29-5.573,10.741-3.252,16.347
					c2.322,5.605,7.791,9.26,13.858,9.26h44.774v214.177c0,8.284,6.716,15,15,15h281.986c8.284,0,15-6.716,15-15V230.767h44.774
					c6.067,0,11.536-3.654,13.858-9.26C446.919,215.902,445.636,209.451,441.346,205.161z M370.965,200.767c-8.284,0-15,6.716-15,15
					v214.177H250.187c-3.934-71.474,4.328-116.966,12.219-142.918c4.456-14.653,9.235-24.744,12.9-31.172
					c3.299-5.786,2.4-13.05-2.285-17.784l-0.326-0.33c-6.865-6.937-18.446-5.432-23.305,3.031c-4.467,7.779-9.686,18.794-14.503,33.747
					c-0.707,2.194-1.384,4.431-2.043,6.692c-2.533-4.767-5.697-9.071-9.359-12.61c-30.11-29.088-70.178-22.828-71.869-22.548
					c-6.518,1.082-11.561,6.303-12.416,12.854c-0.223,1.7-5.095,41.96,25.016,71.048c0,0,0,0,0.001,0.001
					c9.489,9.166,23.639,14.772,37.22,14.772c7.201,0,14.243-1.579,20.343-5.039c-2.853,27.112-3.403,57.263-1.642,90.255H103.979
					V215.767c0-8.284-6.716-15-15-15H65.417L229.972,36.212l164.555,164.555H370.965z M185.057,308.377
					c-10.66-10.299-14.442-23.284-15.701-32.758c9.527,0.932,22.63,4.265,33.284,14.558c3.124,3.018,5.692,8.304,6.545,13.466
					c0.678,4.11,0.183,7.732-1.263,9.229C204.531,316.382,191.448,314.548,185.057,308.377z"/>
				</g>
			</svg>
		</div>
	);
}
