import React from 'react';

const CreditLine = (props) => {

    const { authorUrl, authorName, refUrl, refName } = props;

    return (
        <li className="noMarginLi">
            <a className="blue" target="_blank" rel="noopener noreferrer"
            href={authorUrl}>{authorName}</a>
            &nbsp;-&nbsp; 
            <a className="blue" target="_blank" rel="noopener noreferrer"
            href={refUrl}>{refName}</a>
        </li>
    );
}

const Credits = () => {

    const creditsList = [
        { authorUrl: "https://www.flaticon.com/authors/vitaly-gorbachev", authorName: "Vitaly Gorbachev", refUrl: "https://www.flaticon.com/", refName: "flaticon.com" },
        { authorUrl: "https://www.flaticon.com/authors/freepik", authorName: "Freepik", refUrl: "https://www.flaticon.com/", refName: "flaticon.com" },
        { authorUrl: "https://www.flaticon.com/authors/smashicons", authorName: "Smashicons", refUrl: "https://www.flaticon.com/", refName: "flaticon.com" },
        { authorUrl: "https://www.shareicon.net/author/martin-svoboda", authorName: "Martin Svoboda", refUrl: "https://www.shareicon.net/", refName: "shareicon.net" },
        { authorUrl: "https://www.shareicon.net/author/first-styles", authorName: "First Styles", refUrl: "https://www.shareicon.net/", refName: "shareicon.net" },  
    ];

    return (
        <ul className="center small">
            Crédits Visuels :
            {creditsList.map((el, idx) =>
                <CreditLine key={idx} authorUrl={el.authorUrl} authorName={el.authorName} refUrl={el.refUrl} refName={el.refName} />
            )}
        </ul>
    );
}

export default Credits;

