import React from 'react';
import { NavHashLink as Link } from 'react-router-hash-link';

import { JobItem } from "./JobItem"
import Form from "./Form"

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faGithub } from "@fortawesome/free-brands-svg-icons";
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';

import StagePO from "../jobOffers/2024-02 - Stagiaire Chef de projet PO - Feb2024.pdf"


const Join = () => (
    <>
        <div className="section">
            <div className="content">
                <h1 className="sectiontitle"><span className="red">V</span>ocation d'Artisan ?</h1>
                
                <div className="row">
                    <p className="center pad-text">
                        Nous restons une petite équipe qui se connait bien mais sommes ouverts à toute rencontre !
                        <br />
                        <br />
                        Seul test technique : une participation à <a href="https://movaicode.fr" target="_blank" rel="noopener noreferrer">
                            <b>movaicode</b>
                        </a>
                    </p>

                    <p className="center pad-text">
                        Nous postons nos offres d'emploi&nbsp;
                        <a href="https://github.com/CoddityTeam/crew/issues" target="_blank" rel="noopener noreferrer">
                            <span className="red">sur notre github<FontAwesomeIcon icon={faGithub} className="iconMargin" />
                            </span>
                        </a>
                    </p>
                    <div className="center">
                        <JobItem contract="CDI" title="Chef de projet IA" url="https://github.com/CoddityTeam/crew/issues/36" date="Mars 2024" />
                        <JobItem contract="Stage" title="Assistant chef de projet" file={StagePO} date="Feb2024" />
                    </div>

                    <p className="center">
                        <Link to="/devenir-artisan#form" smooth duration={500} >
                            <FontAwesomeIcon size="2x" icon={faAngleDown} />
                        </Link>
                    </p>
                </div>
            </div>
        </div>

        <div id="form" className="section dark">
            <div className="content">
                <h1 className="sectiontitle"><span className="red">C</span>andidater ?</h1>
                <div className="row">
                    <p className="center">Passez nous voir dans le 11ème, écrivez-nous à contact@coddity.com ou complétez le formulaire suivant :</p>
                    <Form typeFormulaire="candidat" />
                </div>
            </div>
        </div>
    </>
);

export default Join;
