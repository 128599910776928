import React from "react";
import "../GameBoard/GameBoard.css";
import plus from "../../asset/image/plus.jpg";
import ball from "../../asset/image/newBall.png";
import life from "../../asset/image/life.jpg";
import viseur from "../../asset/image/viseur3.png";
import fire from "../../asset/image/feu.png";

const TAILLE = 15;
const BORDER_WIDTH=3;
export default class Bonus extends React.Component {
  
    render() {
        const { type, coordX, coordY } = this.props;
        return (
            <div>
                <div className='bonus' style={{ position: "absolute", top: coordY, left: coordX }}></div>
                {type === "multi" && (
                    <img
                        width={TAILLE}
                        height={TAILLE}
                        src={ball}
                        alt='multi'
                        style={{ margin: 0, position: "absolute", top: coordY + BORDER_WIDTH, left: coordX + BORDER_WIDTH }}
                    />
                )}
                {type === "large" && (
                    <img
                        width={TAILLE}
                        height={TAILLE}
                        src={plus}
                        alt='plus'
                        style={{ margin: 0, position: "absolute", top: coordY + BORDER_WIDTH, left: coordX + BORDER_WIDTH }}
                    />
                )}
                {type === "addLife" && (
                    <img
                        width={TAILLE}
                        height={TAILLE}
                        src={life}
                        alt='life'
                        style={{ margin: 0, position: "absolute", top: coordY + BORDER_WIDTH, left: coordX + BORDER_WIDTH }}
                    />
                )}
                {type === "viseur" && (
                    <img
                        width={TAILLE}
                        height={TAILLE}
                        src={viseur}
                        alt='viseur'
                        style={{ margin: 0, position: "absolute", top: coordY + BORDER_WIDTH, left: coordX + BORDER_WIDTH }}
                    />
                )}
                {type === "brickDestroyer" && (
                    <img
                        width={TAILLE}
                        height={TAILLE}
                        src={fire}
                        alt='fire'
                        style={{ margin: 0, position: "absolute", top: coordY + BORDER_WIDTH, left: coordX + BORDER_WIDTH }}
                    />
                )}
            </div>
        );
    }
}
