import React, { useState, useEffect } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import '../css/Home.css';

const ScrollToTopIcon = () => {
    
    const [isScrollEnough, setIsScrollEnough] = useState(document.documentElement.scrollTop > 500);
    
    const handleClick = () => {
        window.scrollTo({top: 0, behavior: 'smooth'});
    }

    const displayScroll = () => {
        setIsScrollEnough(document.documentElement.scrollTop > 500);
    }

    useEffect(() => {
        // ComponentDidMount
        window.addEventListener("scroll", displayScroll);

        // ComponentWillUnmount
        return () => {
            window.removeEventListener("scroll", displayScroll)    
        };
    }, []);    

    return (
        <div className={`scrollToTop ${isScrollEnough ? "visible" : ""}`} onClick={handleClick}>
            <FontAwesomeIcon size="3x" icon={faAngleUp}/>
        </div>
    )
}

export default ScrollToTopIcon;