import React, { useState } from 'react';

export default function ParkourSvg(){
    const [active, setActive] = useState(false);

	return(
		<div className="logotech">
			<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="-00 -40 460 460"
			xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" 
			height="40%" width="40%"
			enableBackground="new 0 0 450 450" 
	 		xmlSpace="preserve"
             onMouseEnter={() => setActive(true)}
             onMouseLeave={() => setActive(false)} 
             >
				<g className={ active ? "parkour parkourmove":"stick" } >
                <path d="M173.798,116.44c19.77,2.311,37.669-11.84,39.979-31.609c2.312-19.766-11.838-37.668-31.606-39.979
				    c-19.769-2.313-37.67,11.84-39.98,31.607C139.88,96.229,154.028,114.128,173.798,116.44z"/>
                <circle color="green" fill="currentColor" cx="417" cy="439" r="3"/>

                <path d="M389.85,204.94c-18.597-55.252-45.918-99.563-81.206-131.701c-2.229-2.029-4.495-4.01-6.796-5.949
                    c1.642-6.363-0.583-13.383-6.192-17.502c-5.604-4.113-12.933-4.166-18.501-0.717c-27.228-17.504-58.465-29.828-93.306-36.756
                    c-26.571-5.283-55.508-7.582-87.618-6.928c-3.45-4.668-9.694-6.684-15.384-4.516c-2.823,1.076-5.054,3.02-6.532,5.402
                    c-7.535,0.441-15.22,1.014-23.103,1.752c-2.49,0.232-4.319,2.441-4.087,4.932c0.233,2.49,2.447,4.322,4.931,4.086
                    c6.934-0.65,13.74-1.18,20.428-1.596c0.143,0.854,0.37,1.707,0.69,2.549l43.684,114.611l-14.484,120.029L20.606,374.471
                    c-4.466,6.637-3.323,15.713,2.919,21.008c6.705,5.688,16.749,4.863,22.436-1.84l99.355-117.113l32.725,3.006l-31.719,53.572
                    c-4.064,6.924-2.376,16.006,4.219,20.895c7.062,5.232,17.028,3.75,22.262-3.311l57.311-77.338
                    c1.628-2.217,2.889-4.881,3.505-7.744c2.342-10.867-4.57-21.574-15.438-23.916l-21.534-4.641l10.177-84.33l53.317-23.102
                    c2.96-1.277,5.578-3.432,7.426-6.367l30.02-47.695c1.668,1.438,3.325,2.891,4.961,4.379
                    c72.686,66.199,109.541,185.498,109.541,354.588c0,2.5,2.027,4.529,4.528,4.529s4.528-2.029,4.528-4.529
                    C421.144,343.88,410.615,266.637,389.85,204.94z M240.437,97.055l-26.891,9.594c-8.299,10.402-21.061,16.98-35.213,16.98
                    c-0.001,0,0,0-0.002,0c-1.755,0-3.531-0.104-5.283-0.309c-24.045-2.811-41.491-24.162-39.728-48.082l-32.153-60.857
                    C170.692,13.61,226.024,27.07,270.83,55.654L240.437,97.055z"/>
				</g>
			</svg>
		</div>
	);
}
