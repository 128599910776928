/**
 * Classe Quête
 * Objet ayant:
    * un nom, 
    * les objets reçus lors de la complétion, 
    * les indices pour les missions qui en découlent;
 */
export default class Quest{
    constructor(name, objects, clues){
    // le nom de la quête
        this.name = name;

    // instanciation des objets
        this.objects = [];
        // si la variable objects n'est pas indéfinie et que c'est un tableau 
        typeof objects !== "undefined" && Array.isArray(objects) ? 
            // alors this.objects vaut le tableau d'objects passé en paramètre
            this.objects = objects : 
            // sinon on pousse l'objet dans le tableau this.objects
            this.objects.push( objects );

    // instanciation des indices
        this.clues = [];
        // si les indices passés en paramètre sont définis
        if(typeof clues !== "undefined"){
            // si les indices sont un tableau d'indices
            if(Array.isArray(clues)){
                // pour chaque indices du tableau clues
                for(let idx = 0; idx < clues.length ; idx++){
                    let clue = clues[idx];
                    // on vérifie que chaque indice contient bien un id, un titre, une phrase, et un niveau
                    if(typeof clue.id === "number" && typeof clue.title === "string" &&
                    typeof clue.sentence === "string" && typeof clue.level === "number"){
                        // ensuite on le pousse dans le tableau d'indice de l'objet
                        this.clues.push(clue);
                    }
                }
            }
            // sinon (si ce n'est pas un tableau)
            else {
                // on vérifie que l'indice contient bien un id, un titre, une phrase, et un niveau
                if(typeof clues.id === "number" && typeof clues.title === "string" &&
                 typeof clues.sentence === "string" && typeof clues.level === "number"){
                    this.clues.push(clues);
                }
            }
        }
    }
}