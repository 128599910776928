import React from "react";
import { NavHashLink as Link } from 'react-router-hash-link';

import "../css/project-grid.css";

import { PhilosophieSvg, EquipeSvg, SaintLeoSvg } from "../assets/svg/";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTerminal } from '@fortawesome/free-solid-svg-icons';

const Maison = ({ striped }) => (
  <div id="maison" className={`section ${striped}`}>
    <div className="content">
      <h1 className="sectiontitle">
        <span style={{ letterSpacing: "3px" }}>
          <span className="red">C</span>ODDITY <span className="red">?</span>
        </span>
      </h1>
      <div className="block-content">
        <div className="project-grid">

          <div className="project-grid-item center">
            <PhilosophieSvg id="easteregg-gamestarter" />
            <h1>
              <span className="red">P</span>hilosophie
            </h1>
            <p className="center pad-text">
              <span className="red">C</span>oddity est une entreprise de services du numérique engagée et éditrice de <Link to="/#produits" smooth duration={500}>logiciels</Link>.
              Nous aimons l'idée de réaliser nos prestations <b>avec soin</b>, tels des Artisans.
              Nous combinons nos savoir-faire en <b>développement</b> et <b>IA</b> pour produire des solutions complètes.
            </p>
          </div>

          <div className="project-grid-item center">
            <EquipeSvg />
            <h1>
              <span className="red">É</span>quipe
            </h1>
            <p className="center pad-text">
              A l'origine, un <b>noyau de passionnés</b> mettant en oeuvre leurs compétences, leurs expériences et leurs idées
              dans l'aventure, nous sommes aujourd'hui <b><span className="clickMe">30</span> Artisans</b>.
              <br /><br />
              Nous vous mettons d'ailleurs au défi de terminer les quêtes cachées du site... ça démarre dans la console <FontAwesomeIcon icon={faTerminal} />
            </p>
          </div>

          <div className="project-grid-item center">
            <SaintLeoSvg />
            <h1>
              Rural Shore
            </h1>
            <p className="center pad-text">
              Coddity est née en <b>2016 à Paris</b> et a ouvert en 2022 un centre de développement
              à <span className="red">S</span><b>aint-Léonard-de-Noblat</b> en Haute-Vienne.
              Nous avons déposé la maque <b>Rural Shore</b> pour promouvoir le numérique en région.
            </p>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default Maison;
