import { useEffect, useRef } from 'react';
import { useLocation } from 'react-router';

const AutoScrollToTop = (props) => {
  
  const { pathname } = useLocation();

  const previousPathname = useRef(null);

  useEffect(() => {
    if(previousPathname.current !== pathname && pathname !== '/') {
      window.scrollTo(0, 0);
    }
    previousPathname.current = pathname;
  }, [pathname]);

  return (props.children);
}

export default AutoScrollToTop;
