import React from "react";
import "./BrickBreak.css";
import Board from "./components/GameBoard/GameBoard";

let num = 0;
const word = [
    [66, 82, 73, 81, 85, 69],
    [66, 82, 73, 67, 75]
];
const available = [true, true];
export default class BrickBreak extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            show: false
        };
        //fonction appelé à chaque entrée
        document.addEventListener("keydown", event => this.onWordsDetection(event)); // on écoute les touches pressées
    }

    onWordsDetection = event => {
        if (!this.state.show) {
            let change = false;
            for (let i = 0; i < word.length; i++) {
                if (change === false) {
                    if (word[i][num] === event.keyCode && available[i] === true) {
                        change = true;
                        num++;
                        console.log("Vous etes sur la bonne voie " + num);
                        if (num === word[i].length) {
                            this.setState({ show: true });
                            console.log("Bravo");
                            num = 0;
                        }
                    } else if (word[i][num] !== event.keyCode) {
                        available[i] = false;
                    }
                } else {
                    if (word[i][num - 1] !== event.keyCode && available[i] === true) {
                        available[i] = false;
                    }
                    if (word[i][num - 1] === event.keyCode && available[i] === true) {
                        if (num === word[i].length) {
                            this.setState({ show: true });
                            console.log("Bravo");
                            num = 0;
                        }
                    }
                }
            }
            let count = 0;
            for (let i = 0; i < available.length; i++) {
                available[i] === false && count++;
            }
            if (count === available.length) {
                num = 0;
                for (let i = 0; i < available.length; i++) {
                    available[i] = true;
                }
            }
        }
    };

    dontShow = () => {
        this.setState({ show: false });
    };

    render() {
        if (this.state.show === true) {
            return (
                <div style={{ position: "fixed", top: 0, bottom: 0, left: 0, right: 0, zIndex: 2 }}>
                    <Board dontShow={this.dontShow}></Board>
                </div>
            );
        } else {
            return null;
        }
    }
}
