import React from 'react';

const CookieSvg = () => (
	<div className="logotech">
		
		<svg width="40%" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
			<path d="m16 4c-6.628906 0-12 5.371094-12 12s5.371094 12 12 12 12-5.371094
				12-12c0-.484375-.035156-.960937-.09375-1.433594-.398437.273438-.882812.433594-1.40625.433594-1.078125
				0-1.988281-.6875-2.339844-1.640625-.625.398438-1.363281.640625-2.160156.640625-2.210937
				0-4-1.789062-4-4 0-.734375.210938-1.414062.558594-2.007812-.019531.003906-.039063.007812
				-.058594.007812-1.382812 0-2.5-1.117187-2.5-2.5 0-.558594.1875-1.066406.496094-1.480469
				-.164063-.007812-.328125-.019531-.496094-.019531zm7.5 0c-.828125 0-1.5.671875-1.5
				1.5s.671875 1.5 1.5 1.5 1.5-.671875 1.5-1.5-.671875-1.5-1.5-1.5zm-9.449219 2.1875c.199219
				1.289063.949219 2.398438 1.996094 3.085938-.03125.238281-.046875.484375-.046875.726562
				0 3.308594 2.691406 6 6 6 .496094 0 .992188-.0625 1.46875-.1875.683594.625 1.546875
				1.039063 2.484375 1.15625-.488281 5.0625-4.765625 9.03125-9.953125 9.03125-5.515625
				0-10-4.484375-10-10 0-4.847656 3.46875-8.902344 8.050781-9.8125zm7.949219 2.8125c-.550781
				0-1 .449219-1 1s.449219 1 1 1 1-.449219 1-1-.449219-1-1-1zm-8 1c-.550781 0-1 .449219-1
				1s.449219 1 1 1 1-.449219 1-1-.449219-1-1-1zm13 0c-.550781 0-1 .449219-1 1s.449219 1 1 1
				1-.449219 1-1-.449219-1-1-1zm-16 3c-1.105469 0-2 .894531-2 2s.894531 2 2 2 2-.894531
				2-2-.894531-2-2-2zm5 2c-.550781 0-1 .449219-1 1s.449219 1 1 1 1-.449219 1-1-.449219-1-1-1zm-3.5
				4c-.828125 0-1.5.671875-1.5 1.5s.671875 1.5 1.5 1.5 1.5-.671875 1.5-1.5-.671875-1.5-1.5-1.5zm7
				1c-.828125 0-1.5.671875-1.5 1.5s.671875 1.5 1.5 1.5 1.5-.671875 1.5-1.5-.671875-1.5-1.5-1.5z"/>
		</svg>
		
	</div>
);

export default CookieSvg;
