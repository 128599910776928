import React from 'react';

import LogoItem from "./logoitem/LogoItem";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt, faFileExcel } from '@fortawesome/free-solid-svg-icons';

import LogoBourse from '../img/bourse.svg';
import LogoBlog from '../img/blog.svg';
import LogoMovaicode from '../img/movaicode-bold.svg';
import { PpsSvg, FreedayOfficielSvg } from '../assets/svg/';

import "../css/project-grid.css";

const Univers = ({striped}) => (  
  <div id="univers" className={`section ${striped}`}>
    <div className="content">
      <h1 className="sectiontitle">Univers</h1>
      <div className="block-content">

        <div className="project-grid project-grid-max-3">

          <div className="project-grid-item center">
            
            <LogoItem imgSvg={LogoBourse} />
            
            <p className="center pad-text">
              Coddity <b>parraine des étudiant(e)s</b> en informatique en prenant en charge
              une partie de leurs <b>frais de scolarité</b>.
              Les lauréat(e)s sont sélectionnés à l'issue d'un <b>concours de développement</b> annuel.
              La <b>7ème édition</b> est en préparation !
            </p>
            <p className="pad-text">
              <a className="blue" href="https://bourse.coddity.com/" target="_blank" rel="noopener noreferrer">
                bourse.coddity.com<FontAwesomeIcon icon={faExternalLinkAlt} className="iconMargin" />
              </a>
            </p>
          </div>

          <div className="project-grid-item center">

            <LogoItem imgSvg={LogoMovaicode} />

            <p className="center pad-text">
              Bien coder, optimiser, respecter des conventions... <b>Y EN A MARRE</b> !
              Bienvenue au concours du pire dev de France : montrez au monde <b>à quel point vous pouvez être nul(le)</b> !
              Tous les mois, une fonction à coder de la pire des manières : mais attention il faut
              qu'elle marche ! Plus facile à dire qu'à faire...
            </p>
            <p className="pad-text">
              <a className="blue" href="https://github.com/CoddityTeam/movaicode/" target="_blank" rel="noopener noreferrer">
                Tentez d'être movai<FontAwesomeIcon icon={faExternalLinkAlt} className="iconMargin" />
              </a>
            </p>
          </div>

          <div className="project-grid-item center">
            
            <LogoItem imgSvg={LogoBlog} />

            <p className="center pad-text">
              Sur notre blog nous publions des articles <b>d'actualité technique</b> (langages, frameworks, outils, data) ainsi que
              nos réflexions sur des sujets plus humains tels que le <b>recrutement</b>, la <b>gestion des compétences</b>, l'<b>accessibilité</b>, etc.
            </p>
            <p className="pad-text">
              <a className="blue" href="https://blog.coddity.com/" target="_blank" rel="noopener noreferrer">
                blog.coddity.com<FontAwesomeIcon icon={faExternalLinkAlt} className="iconMargin" />
              </a>
            </p>
          </div>

          <div className="project-grid-item center">
            <FreedayOfficielSvg />
            <h1>Freeday</h1>
            <p className="pad-text">
                Créé, finalisé en Atelier, commercialisé et ouvert à l'open source, 
                Freeday permet de <b>gérer facilement les absences de salariés ou bénévoles d'associations
                ou TPE</b>.
                Avec Freeday, adieu la feuille <FontAwesomeIcon icon={faFileExcel} /> partagée !
            </p>
            <p className="pad-text">
                <a className="blue" href="https://freeday-app.com/" target="_blank" rel="noopener noreferrer">
                    freeday-app.com<FontAwesomeIcon icon={faExternalLinkAlt} className="iconMargin" />
                </a>
            </p>
          </div>


          <div className="project-grid-item center">
            <PpsSvg />
            <h1>Punky Places Society</h1>
            <p className="pad-text">
                Notre label PPS récompense les <b>meilleurs entreprises</b> (en toute objectivité et modestie),
                qui se voient recevoir leur certification sous la forme d'un NFT.
                Propulsé par des <b>smart contracts</b> sur la blockchain <b>Ethereum</b>,
                la labelisation PPS a pour objectif de devenir une organisation autonome et décentralisée.
            </p>
            <p className="pad-text">
                <a className="blue" href="https://punky.place/" target="_blank" rel="noopener noreferrer">
                    punky.place<FontAwesomeIcon icon={faExternalLinkAlt} className="iconMargin" />
                </a>
            </p>
         </div>

        </div>
      </div>
    </div>
  </div>
);

export default Univers;
