import React from 'react';

const News2Svg = () => (
	<div className="logotech">
		<svg width="40%" enableBackground="new 0 0 64 64" viewBox="0 0 64 64" xmlns="http://www.w3.org/2000/svg">
			<path d="m57.247 24.222c.973-2.628 1.254-5.134.555-7.383-.322.029-.656.044-.998.044-10.242
			0-28.344-12.877-29.714-14.883-5.645 8.223-17.477 14.545-22.825 21.635-2.164 2.869-3.246
			5.004-1.093 8.286l22.549 28.973c1.017 1.377 3.01 1.489 4.429.219 0 0 18.492-23.729
			31.851-31.071-1.004-1.961-2.675-3.918-4.754-5.82m-31.919 30.532-.094.065-.085.074c-.352.316-.656.666
			-.912 1.041l-19.538-25.103c-1.407-2.177-1.008-3.323 1.063-6.067 2.349-3.113 6.103-6.135 10.078-9.334
			4.132-3.326 8.388-6.75 11.514-10.646 5.16 4.246 19.593 13.621 28.932 13.964 1.12 10.997-21.682
			29.536-30.958 36.006"/><path d="m19.993 14.158 25.205 19.75.898-.804-25.447-19.534z"/>
			<path d="m28.207 24.671-10.316-8.632s-3.59 2.292-7.938 7.319l9.152 9.462z"/>
			<path d="m5.606 28.06 19.468 23.861 3.595-3.217-20.756-23.731s-1.809.979-2.307 3.087"/>
			<path d="m27.42 29.428 11.309 10.27 3.593-3.217-11.775-9.852z"/>
			<path d="m21.166 35.027 10.377 11.104 3.592-3.216-10.842-10.687z"/>
			<path d="m27.857 10.756-1.525 4.282.956.696 4.855-4.347-.95-.598-3.345 2.995 1.479-4.174-.992
			-.626-4.627 4.142.829.603z"/><path d="m32.012 19.179.926-.831-2.825-2.008 1.281-1.146 2.509
			1.718.891-.799-2.537-1.694 1.058-.946 2.809 1.817.912-.815-3.884-2.452-4.918 4.403z"/>
			<path d="m35.021 21.371 3.839-2.325 1.257-.796-.834 1.078-2.47 3.351 1.193.869 7.388-3.799-1.402
			-.885-4.051 2.318-1.034.626.694-.913 2.401-3.287-1.348-.85-3.745 2.321-.995.629.685-.871
			2.268-3.207-1.264-.798-3.661 5.753z"/>
			<path d="m48.907 21.705c-.909-.571-1.758-.844-2.552-.828-.788.017-1.434.254-1.943.71-.559.5-.747
			1.019-.563 1.557.108.323.452.79 1.039 1.408l.607.638c.362.376.595.684.697.92.1.239.051.448-.146.624
			-.337.301-.776.358-1.315.174-.281-.098-.595-.27-.938-.515-.57-.406-.847-.797-.84-1.174.004-.206.117
			-.442.338-.708l-1.311-.914c-.591.529-.815 1.119-.667 1.774.149.661.656 1.313 1.536 1.955.877.643
			1.75.987 2.608 1.025.867.037 1.581-.191 2.131-.686.54-.482.732-1 .578-1.551-.098-.353-.373-.764-.817
			-1.232l-.995-1.05c-.379-.396-.605-.671-.682-.828-.12-.239-.077-.45.13-.634.225-.202.513-.284.865
			-.249.357.035.729.178 1.118.428.353.228.602.464.741.708.215.368.155.723-.175 1.06l1.477.973c.614
			-.592.806-1.219.577-1.878-.224-.653-.725-1.223-1.498-1.707"/>
		</svg>
		
		

	</div>
);

export default News2Svg;
