import * as React from 'react';
import { useEffect } from 'react';
import Widget from '@mcaptcha/core-glue';

import './MCaptcha.css';

const MCaptcha = ({ widgetUrl, onValidate, disabled }) => {
  const url = widgetUrl ? new URL(widgetUrl) : null;

  const widget = url ? new Widget({
    widgetLink: url
  }, (token) => {
    onValidate(token);
  }) : null;

  useEffect(() => {
    if (url) {
      widget.listen();
      return () => widget.destroy();
    }
  });

  return (
    <div className={`mcaptcha-container ${disabled ? 'disabled' : ''}`}>
      {widgetUrl ? (
        <iframe
          title='mCaptcha'
          src={widget.widgetLink.toString()}
          role='presentation'
          name='mcaptcha-widget__iframe'
          id='mcaptcha-widget__iframe'
          scrolling='no'
          sandbox='allow-same-origin allow-scripts allow-popups'
          width='100%'
          height='100%'
          frameBorder='0'
        />
      ) : null}
    </div>
  );
};

export default MCaptcha;
