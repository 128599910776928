/**
 * AudioWrapper
 * Classe permettant d'encapsuler le lecteur audio javascript
 * Permet de s'assurer que la valeur de l'attribut preload puisse être assigné avant que le navigateur tente de charger la ressource
 */

export default class AudioWrapper extends Audio{
    constructor(path, preload = "none") {
        // Le constructeur de la classe Audio assigne la valeur "auto" à l'attribut preload,
        // puis lance le chargement asynchrone de la ressource avant de retourner l'instance.
        // On instancie un nouvel objet sans ressource avant d'assigner une valeur à preload
        // pour empêcher le chargement.
        super()
        this.preload = preload
        this.src = path
    }
}
