import React from 'react';

export default function FaismoisigneSvg(){
    return(
        <div className="logotech">
            <svg id="Layer_1" enableBackground="new 0 0 496 496" height="40%" viewBox="0 0 496 496" width="40%" xmlns="http://www.w3.org/2000/svg">
				<metadata>
					Icon made by Vitaly Gorbachev, downloaded from www.flaticon.com, https://www.flaticon.com/authors/vitaly-gorbachev
				</metadata>
				<path d="m117.27 289.523-20.93 12.08c-8.89 5.14-14.42 14.72-14.42 25.01 0 21.992 24.006 36.082 43.27 24.96l107.47-62.05z"/>
				<path d="m104.26 207.603h-71.3c-18.302 0-32.96 14.802-32.96 32.96 0 18.17 14.79 32.96 32.96 32.96h185.49c-50.16-28.964-25.926-14.973-114.19-65.92z"/>
				<path d="m26.72 145.513c103.76 58.449 52.059 28.902 234.1 133.99.82.478.852.567 6.42 5.09.878-14.93-4.685-28.405-14.43-38.15-76.177-76.177-51.767-51.422-108.66-109.99l-84.42-48c-16.063-9.269-36.116-3.467-45.07 12.03-9.09 15.74-3.68 35.94 12.06 45.03z"/>
				<path d="m397.57 166.643h-54.29c-30.56 0-59.3-11.91-80.91-33.52-.11-.11-42.1-43.205-109.95-112.84-13.977-13.957-36.861-13.886-50.72.67-13.14 13.81-12.58 36.28 1.25 50.11.02.03.05.06.08.08 117.311 120.779 81.304 84.193 161.1 163.99 25.61 25.61 25.625 67.041.09 92.68-50.767 54.154-48.027 51.237-48.31 51.52-28.42 28.43-28.42 74.67 0 103.1 4.847 4.847 12.735 4.854 17.59.04l79.32-82.53c3.263-2.471 31.354-34.26 82.57-34.26 55.48 0 100.61-45.14 100.61-100.61 0-54.28-44.15-98.43-98.43-98.43z"/></svg>
        </div>
    );
}