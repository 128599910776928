import React from 'react';

export default function LightgridSvg(){
    return(
        <div className="logotech">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            width="40%" viewBox="0 0 300 300" enableBackground="new 0 0 300 300" xmlSpace="preserve">
                <rect x="60" y="94" fill="#444" width="83" height="21"/>
                <rect x="60" y="185" fill="#444" width="83" height="21"/>
                <rect x="60" y="255" fill="#444" width="83" height="20"/>
                <rect x="25" y="218" fill="#444" width="84" height="22"/>
                <rect x="60" y="94" fill="#000" width="22" height="112"/>
                <rect x="123" y="94" fill="#000" width="20" height="181"/>
                <rect x="25" y="60" fill="#000" width="20" height="180"/>
                <g>
                    <rect x="157" y="60" fill="#444" width="79" height="22"/>
                    <rect x="157" y="60" fill="#000" width="20" height="111"/>
                </g>
                <rect x="191" y="185" fill="#444" width="84" height="21"/>
                <rect x="191" y="94" fill="#444" width="84" height="21"/>
                <rect x="191" y="94" fill="#000" width="21" height="112"/>
                <rect x="255" y="25" fill="#000" width="20" height="181"/>
            </svg>
        </div>
    );
}