import React from 'react';

const ellipseStyle = {
    fill: "none",
    strokeWidth: 1.01561,
    strokeLinecap: "square"
}

const FreedayOfficielSvg = ({width = "40%"}) => (
    
    <div className="logotech">
        <svg
            width={width}
            viewBox="0 0 63 63"
        >
        <g
            id="Groupe_402"
            transform="translate(1.078783,1.0752716)">
            <ellipse
                style={ellipseStyle}
                id="path838"
                cx="30.627737"
                cy="30.660149"
                rx="31.70652"
                ry="31.73542" />
            <g
                id="Groupe_401"
                transform="translate(2.202,2.202)">
                <path
                    id="Tracé_1224"
                    d="m 2826.053,1273.457 a 28.225,28.225 0 0 1 -1.438,8.921 h -53.9 a 28.389,28.389 0 1 1 55.339,-8.921 z"
                    transform="translate(-2769.276,-1245.068)"
                    fill="none"
                />
                </g>
                <path
                    id="Tracé_1225"
                    d="m 2793.987,1239.188 a 30.591,30.591 0 0 0 -29.261,39.512 q 0.343,1.118 0.764,2.2 h 56.995 q 0.422,-1.084 0.764,-2.2 a 30.591,30.591 0 0 0 -29.261,-39.512 z m 26.95,39.512 h -53.9 a 28.389,28.389 0 1 1 53.9,0 z"
                    transform="translate(-2763.396,-1239.188)"
                />
                <path
                    id="Tracé_1226"
                    d="m 2826.784,1357.688 c -0.378,0.753 -0.79,1.487 -1.228,2.2 h -52.141 c -0.438,-0.715 -0.85,-1.449 -1.229,-2.2 z"
                    transform="translate(-2768.894,-1313.307)"
                />
                <path
                    id="Tracé_1227"
                    d="m 2828.839,1370.678 q -0.887,1.146 -1.873,2.2 h -44.718 c -0.659,-0.7 -1.281,-1.438 -1.873,-2.2 z"
                    transform="translate(-2774.017,-1321.432)"
                    fill="#A71951"
                />
                <path
                    id="Tracé_1228"
                    d="m 2831.983,1383.668 a 30.743,30.743 0 0 1 -2.992,2.2 H 2795.9 a 30.69,30.69 0 0 1 -2.993,-2.2 z"
                    transform="translate(-2781.854,-1329.557)"
                    fill="#F54367"
                />
                <path
                    id="Tracé_1229"
                    d="m 2837.439,1396.668 a 30.592,30.592 0 0 1 -22.793,0 z"
                    transform="translate(-2795.451,-1337.688)"
                    fill="#FFB97B"
                />
                <path
                    id="Tracé_1230"
                    d="m 2834.8,1281.781 c -2.1,-2 -4.591,-3.778 -7.351,-3.958 -1.8324,-0.049 -3.6422,0.4141 -5.226,1.337 0.492,-0.418 1,-0.827 1.531,-1.218 1.4523,-1.0669 3.0081,-1.9851 4.644,-2.741 0.1759,-0.08 0.1261,-0.3427 -0.067,-0.353 -2.109,-0.135 -4.0266,0.2205 -5.8042,1.3874 -1.3359,1.4003 -4.1006,4.884 -2.3461,4.9087 1.8939,0.026 -1.3445,-3.4972 -2.8049,-4.9527 -1.622,-1.188 -3.5608,-1.4784 -5.6698,-1.3434 -0.1931,0.01 -0.2429,0.2726 -0.067,0.353 1.636,0.7556 3.1918,1.6739 4.644,2.741 0.683,0.5 1.33,1.028 1.946,1.573 -1.7414,-1.1364 -3.7829,-1.7256 -5.862,-1.692 -2.76,0.179 -5.246,1.953 -7.351,3.958 -0.1957,0.1811 -0.016,0.5029 0.241,0.431 2.3923,-0.6476 4.8436,-1.0533 7.317,-1.211 1.2649,-0.082 2.5332,-0.1011 3.8,-0.056 -1.073,0.037 -2.1249,0.3077 -3.082,0.794 -2.056,1.094 -3.351,3.316 -4.272,5.584 -0.088,0.2067 0.1615,0.3918 0.334,0.248 2.9319,-2.4078 6.2764,-4.2636 9.871,-5.477 v 0.09 0.618 0.341 0.356 0.266 0.36 0.348 l -0.146,16.509 h 1.824 l -0.143,-16.509 v -0.352 -0.356 -0.266 -0.352 -0.341 -0.61 -0.061 c 3.5475,1.2166 6.8489,3.0579 9.748,5.437 0.1725,0.1438 0.4217,-0.041 0.334,-0.248 -0.921,-2.267 -2.216,-4.49 -4.272,-5.584 -0.9568,-0.4863 -2.0084,-0.7573 -3.081,-0.794 1.2651,-0.045 2.5317,-0.026 3.795,0.056 2.4731,0.1577 4.924,0.5634 7.316,1.211 0.2346,0.018 0.3656,-0.2649 0.2,-0.432 z"
                    transform="translate(-2789.376,-1261.469)"
                />
            </g>
        </svg>
    </div>
);

export default FreedayOfficielSvg;
