import React from 'react';

const LogoItem = (props) => {

    const { imgSvg } = props;

    return (
        <div className="logotech">
            <img src={imgSvg} width="40%" />
        </div>
    );
}

export default LogoItem;
