import React from 'react';

export default function ZappySvg(){
    return(
        <div className="logotech">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="40%" height="40%" viewBox="0 0 193.183 193.184" style={{enableBackground:'new 0 0 193.183 193.184'}} xmlSpace="preserve">
				<g>
                            <path d="M130.236,48.68c7.158-14.42,14.778-28.603,22.005-42.986c0.535-1.063,0.746-2.507,0-3.551
									c-0.74-1.039-1.394-1.479-2.507-1.66c-0.428-0.247-0.916-0.415-1.445-0.462c-3.654-0.324-7.235,3.073-9.985,5.13
									c-2.254,1.686-4.495,3.388-6.75,5.072c-0.116,0.036-0.234,0.043-0.349,0.092c-8.015,3.478-14.828,12.121-21.122,18.043
									c-10.481,9.862-20.667,20.025-30.659,30.38c-8.901,9.223-17.484,18.721-25.667,28.587c-4.726,5.698-13.875,13.953-13.27,21.755
									c0.082,1.063,1.073,2.1,2.227,1.695c0.075-0.025,0.14-0.076,0.215-0.104c0.439,0.617,1.133,1.093,2.145,1.188
									c11.762,1.124,23.697,1.013,35.53,0.993c-0.059,0.148-0.093,0.305-0.076,0.468c0.425,3.888-5.524,11.434-7.373,14.723
									c-4.325,7.697-7.879,14.427-10.609,22.855c-0.706,2.179-1.471,4.333-2.253,6.483c-0.638,0.245-1.121,0.995-0.766,1.826
									c0.017,0.037,0.036,0.071,0.054,0.108c-1.781,4.83-3.604,9.642-5.058,14.571c-0.885,3.001-1.893,5.968-2.893,8.932
									c-1.352,4.008-2.434,4.186-0.958,3.786c-0.231,0.419-0.5,0.818-0.687,1.261c-0.854,1.857-0.4,3.546,1.244,4.744
									c0.032,0.024,0.066,0.048,0.1,0.072c1.221,0.891,3.275,0.523,4.259-0.55c0.277-0.302,0.518-0.647,0.765-0.985
									c0.481,0.01,0.979-0.082,1.469-0.433c18.473-13.187,34.285-30.232,49.65-46.811c13.202-14.243,31.661-30.094,40.693-47.386
									c1.338-2.561-1.498-6.133-4.224-4.315c-0.352-0.774-0.966-1.429-1.895-1.685c-9.734-2.681-20.42-1.585-30.557-2.696
									c0.722-3.563,5.547-11.298,6.558-13.606C121.83,65.585,126.047,57.122,130.236,48.68z M134.812,15.261
									c1.938-1.14,3.862-2.3,5.779-3.478c-3.196,5.683-6.207,11.477-9.158,17.302c-1.419-1.664-2.926-3.283-4.47-4.821
									C129.986,21.444,132.761,18.482,134.812,15.261z M125.408,25.692c1.505,2.088,2.922,4.3,4.625,6.181
									c-1.046,2.089-2.116,4.165-3.144,6.262c-0.766-0.59-1.543-1.171-2.259-1.81c-1.87-1.669-3.568-3.563-5.127-5.546
									C121.468,29.118,123.471,27.428,125.408,25.692z M112.788,36.73c1.466-1.418,3.077-2.833,4.735-4.256
									c1.396,1.856,2.885,3.636,4.477,5.336c1.047,1.118,2.157,2.359,3.363,3.476c-0.787,1.621-1.579,3.241-2.357,4.864
									c-1.443-1.201-3.141-2.253-4.337-3.288c-2.148-1.855-4.253-3.759-6.347-5.676C112.477,37.036,112.631,36.881,112.788,36.73z
									 M49.395,106.001c2.734-2.955,5.055-6.651,7.364-9.919c1.55,1.816,3.179,3.561,4.94,5.208c1.213,1.134,2.737,2.737,4.363,4.076
									C60.497,105.44,54.93,105.606,49.395,106.001z M60.679,180.095c0.206-0.726,0.413-1.435,0.628-2.101
									c1.044-3.217,2.029-6.562,3.024-9.94c0.009,0.012,0.015,0.026,0.023,0.039c0.955,1.428,2.119,3.541,3.612,4.854
									C65.541,175.332,63.12,177.719,60.679,180.095z M70.871,170.1c-1.108-1.477-2.813-2.788-3.864-4.052
									c-0.538-0.647-0.981-1.374-1.464-2.068c0.974-3.249,2.002-6.488,3.118-9.664c1.322,2.292,2.833,4.467,4.549,6.513
									c0.934,1.113,2.12,2.489,3.474,3.483C74.749,166.243,72.817,168.178,70.871,170.1z M79.369,161.635
									c-0.24-0.146-0.472-0.291-0.715-0.446c-1.384-0.888-2.572-2.094-3.717-3.262c-1.964-2.003-3.635-4.291-5.146-6.663
									c0.854-2.241,1.789-4.416,2.789-6.532c2.083,2.481,4.239,4.9,6.497,7.228c1.668,1.721,3.254,3.342,5.211,4.634
									C82.643,158.268,81.024,159.971,79.369,161.635z M86.808,154.009c-1.462-1.714-3.275-3.211-5.042-4.74
									c-2.699-2.335-5.29-4.796-7.778-7.355c0.618-1.064,1.686-2.848,2.911-4.994c1.791,2.785,4.089,5.337,6.239,7.721
									c1.487,1.649,3.899,5.124,6.448,6.477C88.658,152.077,87.74,153.051,86.808,154.009z M92.311,148.278
									c-0.438-0.428-0.922-0.807-1.457-1.17c-1.737-1.176-3.295-2.686-4.833-4.104c-2.858-2.635-5.35-5.657-7.58-8.842
									c0.617-1.126,1.245-2.312,1.861-3.521c3.614,3.173,7.07,6.531,10.553,9.844c1.253,1.192,2.857,3.133,4.653,4.372
									C94.434,145.988,93.389,147.153,92.311,148.278z M98.182,141.997c-2.004-1.573-4.17-3.797-6.007-5.373
									c-3.445-2.957-6.839-6.019-10.577-8.606c1.457-3.076,2.688-6.163,3.307-8.801c3.699,3.186,7.396,6.378,11.031,9.634
									c2.157,1.932,4.987,5.664,8.059,6.928C102.072,137.869,100.125,139.93,98.182,141.997z M106.722,132.726
									c-2.033-2.728-6.221-4.678-8.74-6.53c-4.329-3.183-8.437-6.674-12.687-9.971c0.015-1.467-0.298-2.664-1.151-3.376
									c0.172,0,0.346-0.001,0.519,0c2.399,0.004,3.604-1.836,3.635-3.699c3.764,3.333,7.544,6.648,11.263,10.034
									c3.344,3.045,6.489,7.413,10.442,9.67C108.908,130.17,107.817,131.472,106.722,132.726z M112.764,125.479
									c-3.975-7.643-14.922-14.042-21.181-19.234c-7.776-6.451-15.036-15.119-23.38-20.656c-0.712-0.472-1.776,0.311-1.478,1.135
									c2.625,7.227,10.833,13.378,17.135,18.586c-4.059,0.005-8.133,0.002-12.211,0.018c-1.682-2.234-4.9-3.758-7.066-5.486
									c-2.225-1.777-4.302-3.746-6.286-5.798c0.312-0.407,0.622-0.857,0.939-1.236c4.476-5.348,9.137-10.549,13.876-15.677
									c9.004,18.181,27.614,31.765,42.257,45.073c0.006,0.006,0.013,0.006,0.02,0.012C114.512,123.31,113.637,124.401,112.764,125.479z
									 M118.589,118.226c-15.422-13.536-31.666-26.177-43.753-42.943c1.331-1.429,2.681-2.836,4.019-4.258
									c12.389,16.057,28.194,29.651,41.497,45.036C119.766,116.783,119.17,117.502,118.589,118.226z M127.08,96.426
									c3.64,0.366,7.178,0.812,10.715,0.642c-2.58,2.275-5.07,4.762-7.494,7.371c-1.308-1.104-2.604-2.223-3.857-3.413
									c-1.439-1.367-2.846-2.661-4.355-3.92c-0.794-0.663-2.024,0.165-1.474,1.138c1.037,1.828,2.217,3.412,3.552,5.06
									c1.065,1.317,2.223,2.762,3.476,4.096c-1.58,1.789-3.144,3.605-4.678,5.457c-10.496-16.89-28.945-29.17-42.552-43.478
									c1.562-1.654,3.114-3.321,4.685-4.964c0.921-0.963,1.872-1.895,2.798-2.853c0.006,0.01,0.005,0.019,0.011,0.029
									c2.911,4.902,6.179,9.558,9.725,14.02c2.325,2.925,5.701,7.76,9.545,9.251c0.04,1.102,0.184,2.138,0.529,3.035
									c-2.554,1.096-3.25,5.111,0.145,5.887C114.152,95.226,120.659,95.78,127.08,96.426z M111.423,69.406
									c-1.599-1.887-3.982-3.576-5.481-5.024c-2.981-2.88-5.937-5.79-8.997-8.59c-1.308-1.197-3.14,0.634-1.942,1.943
									c2.583,2.824,5.265,5.555,7.924,8.307c1.506,1.559,3.012,3.119,4.518,4.678c0.817,0.846,1.516,1.735,2.429,2.359
									c-0.978,2.49-1.786,4.961-2.259,7.258c-2.263-1.967-4.685-3.763-6.74-6.043c-3.946-4.378-7.425-9.172-10.971-13.873
									c-0.145-0.194-0.323-0.307-0.51-0.388c4.196-4.314,8.44-8.582,12.711-12.822c1.486,1.978,3.103,3.852,4.843,5.65
									c2.484,2.565,5.48,5.892,8.686,8.022C114.277,63.454,112.782,66.379,111.423,69.406z M110.726,52.627
									c-2.536-2.138-4.897-4.459-7.004-7.003c2.196-2.171,4.403-4.33,6.612-6.487c1.942,2.076,3.902,4.135,5.927,6.132
									c1.298,1.282,3.031,3.384,4.931,4.67c-0.634,1.329-1.275,2.658-1.906,3.985c-0.45,0.945-1.154,2.268-1.982,3.817
									C115.224,55.917,112.792,54.369,110.726,52.627z"/>     
                </g>
            </svg>
        </div>
    );
}