import React from 'react';

const ServerSvg = () => (
    <div className='logotech'>
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
            viewBox="0 0 48.071 48.071"
            enableBackground="new 0 0 48.071 48.071" 
            xmlSpace="preserve"
            width="40%" height="40%"
        >
            <path d="M42.73,20.546l-16.5-20c-0.403-0.489-1.068-0.671-1.664-0.458C24.49,0.115,5.732,11.245,5.732,11.245
                c-0.454,0.271-0.733,0.761-0.733,1.29v34.036c0,0.828,0.672,1.5,1.5,1.5h18.573h16.5c0.828,0,1.5-0.672,1.5-1.5V21.5
                C43.072,21.151,42.951,20.815,42.73,20.546z M40.072,45.071h-13.5V5.675l13.5,16.364V45.071z"/>
        </svg>
    </div>
);

export default ServerSvg;