// -------------------------------------------------------------------------
// Librairies
import React from "react";
import ReactDOM from "react-dom";
import BrickBreak from "./brick-break/BrickBreak"

let container = document.createElement("div"); 
 
export default function play(){
    document.body.appendChild(container); // on le place dans le body
    ReactDOM.render(<BrickBreak />, container); // on lui transmet un composant CoddingAnimation en tant qu'enfant
}
