import React from 'react';

import "../css/project-grid.css";

import LogoItem from "./logoitem/LogoItem";

import { WebdevSvg, CloudSvg, ServerSvg, HalSvg } from '../assets/svg/';

import FigmaSvg from '../img/figma.svg';
import DockerSvg from '../img/docker.svg';
import GoLangSvg from '../img/golang.svg';
import ChatGptSvg from '../img/chatgpt.svg';


const SavoirFaire = ({striped}) => (  
  <div id="savoirfaire" className={`section ${striped}`}>
    <div className="content">
      <h1 className="sectiontitle">Savoir<span className="red"> - </span>faire</h1>
      <div className="block-content">

        <div className="project-grid project-grid-max-4">

          <div className="project-grid-item center">
            
            <LogoItem imgSvg={FigmaSvg} />
            
            <h1>UI / UX</h1>
            <p className="pad-text">
              Nos UXD animent des <b>Ateliers UX</b> basés sur le <b>Design Thinking</b>.
              Ils suggèrent et génèrent des <b>univers et chartes graphiques</b>.
              Ils intégrent la charte retenue au sein des maquettes pour générer l'UI finale.
              <br /><br />
              Nos choix : <b>Figma</b>, <b>MaterialUI</b>
            </p>
          </div>

          <div className="project-grid-item center">
            
            <ServerSvg />
            
            <h1>Gestion de projets</h1>
            <p className="pad-text">
              Nos PO sont <b>issu(e)s de la technique</b> et ont basculé sur le produit :
              gestion des <b>risques</b>, <b><span className="red">r</span>oadmap</b> et <b>budget</b>.
              Ils(elles) maîtrisent Agile / Scrum ou Kanban.
              En complément, nous les formons à la gestion des <b>conflits</b>.
              <br /><br />
              Nos choix : <b>Agile</b>, <b>Scrum</b>, <b>Kanban</b> 
            </p>
          </div>

          <div className="project-grid-item center">
            
            <WebdevSvg />
            
            <h1>Dev front</h1>
            <p className="pad-text">
              <b>Javascript</b> et <b>Typescript</b> sont aujourd'hui incontournables,
              mais les paradigmes et techniques évoluent constamment : libs, patterns (SSR), outils...
              Et nous sommes membres actifs de la <b>Svelte Society</b> !
              <br /><br />
              Nos choix : <b>React</b>, <b>Vue</b>, <b>Angular</b>, <b>Svelte</b>
            </p>
          </div>

          <div className="project-grid-item center">
            
            <LogoItem imgSvg={GoLangSvg} />
            
            <h1>Dev backend</h1>
            <p className="pad-text">
              Nous concevons des API et microservices adaptés au besoin.
              Nous aimons les logiciels <b>bien conçus</b> et <b>évolutifs</b>.
              Nous réduisons au maximum le nombre de dépendances des projets pour en garder la maîtrise.
              <br /><br />
              Nos choix : <b>Java</b>, <b>Python</b>, <b>Golang</b>, <b>Nodejs</b>
            </p>
          </div>

          <div className="project-grid-item center">
          
            <LogoItem imgSvg={DockerSvg} />
            
            <h1>Devops</h1>
            <p className="pad-text">
              Notre équipe est formée à l'automatisation par nos spécialistes Devops.
              Ils couvrent l'ensemble du cycle de <b>CI/CD</b> et de la gestion
              de l'<b>infrastructure</b> : conventions, testing, qualité, déploiement, supervision et sécurité.
              <br /><br />
              Nos choix : <b>Docker</b>, <b>Ansible</b>, <b>SonarQube</b>
            </p>
          </div>

          <div className="project-grid-item center">
            
            <CloudSvg />
            
            <h1><span className="red">C</span>loud</h1>
            <p className="pad-text">
              Nous utilisons les services cloud des principaux fournisseurs.
              Notre infra de développement est déployée sur des <b>VPS</b> que nous sécurisons et supervisons
              tandis que notre production repose sur des services <b>PaaS</b> ou <b>SaaS</b>.
              <br /><br />
              Nos choix : <b>AWS</b>, <b>GCP</b>, <b>Clever-cloud</b>
            </p>
          </div>

          <div className="project-grid-item center">
            
            <HalSvg />
            
            <h1><span className="red">D</span>ata</h1>
            <p className="pad-text">
              Nous intervenons sur en tant que <b>ML engineers</b>, <b>data scientists</b> et <b>data engineers</b>.{' '}
              En parallèle, nous avons développé une expertise en NLP avec <b> Google Dialogflow</b>.
              <br /><br />
              Nos choix actuels : <b>DialogFlow</b>, <b>Airflow</b>, <b>pySpark</b>, <b>Kafka</b>, <b>OpenAI</b>
            </p>
          </div>

          <div className="project-grid-item center">
            
          <LogoItem imgSvg={ChatGptSvg} />
            
            <h1><span className="red">I</span>A génératives</h1>
            <p className="pad-text">
              Les LLM bouleversent les métiers intellectuels et permettent de <b>booster
              la productivité</b> des équipes. Notre Direction Technique les utilise 
              quotidiennement et les intègre chez nos clients.
              <br /><br />
              Nos choix actuels : évoluent tous les mois ...
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
);

export default SavoirFaire;
