// -------------------------------------------------------------------------
// Librairies
import manager from "./libs/WarriorManager";
import SHA1 from "sha1";
import Quest from "./libs/Quest";
import Clue from "./libs/Clue";

/**
 * Red Carpet - quête secondaire
 * Le but est que l'utilisateur clique sur tous les caractères rouges de la page
 */

export default class RedCarpet {
    constructor(hello) {
        //variable est à true si l'utilisateur à au moins toucher une lettre
        this.isTouchedOnce = false;
        this.doneEvent = false;
        if (hello) {
            this.hello = hello;
        }
        // instanciation de la variable this.quest de la classe Quest
        this.quest = new Quest(
            "La popularité n'attend plus que vous !",
            SHA1("Lunettes anti-paparazzi"),
            new Clue(3, "La popularité n'attend plus que vous", "COMPLETED !", 2)
        );

        if (localStorage.getItem("coddingMan1")) {
            const listQuest = JSON.parse(localStorage.getItem("quest"));
            if (listQuest) {
                listQuest.forEach(element => {
                    if (this.quest.name === element.name) {
                        this.doneEvent = true;
                    }
                });
            }
        }

        if (!this.doneEvent) {
            // récupère tous les éléments rouges de la page
            this.redElements = document.getElementsByClassName("red");
            // création du tableau conteneur des éléments compatible (à savoir ceux non présent dans un lien)
            this.compatibleElements = [];
            for (let idx = 0; idx < this.redElements.length; idx++) {
                let currentParent = this.redElements[idx];
                let isA = false;
                // tant qu'on atteint pas le parent dont l'id = "root" et qu'il existe un parent
                while (currentParent && !currentParent.id.includes("root")) {
                    // si ce parent est un lien <a>
                    if (currentParent.tagName === "A") {
                        isA = true;
                    }
                    // on passe au parent du parent
                    currentParent = currentParent.parentNode;
                }
                // si isA n'est pas true (donc s'il n'existe pas de parent qui soit un lien <a>)
                if (!isA) {
                    // on pousse l'élément rouge dans le tableau des éléments compatibles
                    this.compatibleElements.push(this.redElements[idx]);
                }
            }

            // on crée le tableau this.booleans qui prendra la valeur répondant à la question "l'élément d'index [i] rouge a-t-il été cliqué ?"
            this.booleans = new Array(this.compatibleElements.length);
            for (let idx = 0; idx < this.booleans.length; idx++) {
                // pour tous les éléments compatible, lors du clic, appel la méthode handleClick avec en paramètre leur index
                this.compatibleElements[idx].onclick = () => this.handleClick(idx);
                // on ajoute pointer à tous les éléments rouges compatibles
                this.compatibleElements[idx].className += " pointer";
                // on instancie tout le tableau de valeur à false
                this.booleans[idx] = false;
            }
        }
    }

    /**
     * Appelé lors du clic sur un élément
     * Lors du clic, passe la valeur à l'indice passé à true
     * Affiche le nombre d'élément rouge trouvé tant qu'on a pas tout trouvé
     * Si tous les éléments rouges ont été cliqué, lance la méthode de fin this.completed()
     * @param {Integer} key l'index dans le tableau de booléen
     */
    handleClick = key => {
        // si l'élément n'a pas encore été cliqué ie. className === "red pointer" alors on le traite
        if (this.compatibleElements[key].className !== "red") {
            this.booleans[key] = true;
            // on utilise le tableau wasClicked pour pouvoir connaître le nombre d'éléments rouges déjà cliqués
            let wasClicked = this.booleans.filter(element => element);
            if (!this.booleans.every(element => element)) {
                // on retire pointer à l'élément qui vient d'être cliqué
                this.compatibleElements[key].className = "red";
                // on affiche la progression
                console.log(`Vous êtes à ${wasClicked.length}/${this.booleans.length} du devant de la scène.`);
            }
            // tous les éléments sont à true
            else {
                this.completed();
            }
        }

        if (localStorage.getItem("coddingMan1")) {
            const listQuest = JSON.parse(localStorage.getItem("quest"));
            if (listQuest) {
                listQuest.forEach(element => {
                    if (this.quest.name === element.name) {
                        this.doneEvent = true;
                    }
                });
            }
        }

        if (!this.doneEvent) {
            // récupère tous les éléments rouges de la page
            this.redElements = document.getElementsByClassName("red");
            // création du tableau conteneur des éléments compatible (à savoir ceux non présent dans un lien)
            this.compatibleElements = [];
            for (let idx = 0; idx < this.redElements.length; idx++) {
                let currentParent = this.redElements[idx];
                let isA = false;
                // tant qu'on atteint pas le parent dont l'id = "root" et qu'il existe un parent
                while (currentParent && !currentParent.id.includes("root")) {
                    // si ce parent est un lien <a>
                    if (currentParent.tagName === "A") {
                        isA = true;
                    }
                    // on passe au parent du parent
                    currentParent = currentParent.parentNode;
                }
                // si isA n'est pas true (donc s'il n'existe pas de parent qui soit un lien <a>)
                if (!isA) {
                    // on pousse l'élément rouge dans le tableau des éléments compatibles
                    this.compatibleElements.push(this.redElements[idx]);
                }
            }

            // on crée le tableau this.booleans qui prendra la valeur répondant à la question "l'élément d'index [i] rouge a-t-il été cliqué ?"
            this.booleans = new Array(this.compatibleElements.length);
            for (let idx = 0; idx < this.booleans.length; idx++) {
                // pour tous les éléments compatible, lors du clic, appel la méthode handleClick avec en paramètre leur index
                this.compatibleElements[idx].onclick = () => this.handleClick(idx);
                // on ajoute pointer à tous les éléments rouges compatibles
                this.compatibleElements[idx].className += " pointer";
                // on instancie tout le tableau de valeur à false
                this.booleans[idx] = false;
            }
        }
    };

    /**
     * Appelé lors du clic sur un élément
     * Lors du clic, passe la valeur à l'indice passé à true
     * Affiche le nombre d'élément rouge trouvé tant qu'on a pas tout trouvé
     * Si tous les éléments rouges ont été cliqué, lance la méthode de fin this.completed()
     * @param {Integer} key l'index dans le tableau de booléen
     */
    handleClick = key => {
        // si l'élément n'a pas encore été cliqué ie. className === "red pointer" alors on le traite
        if (this.compatibleElements[key].className !== "red") {
            this.booleans[key] = true;
            // on utilise le tableau wasClicked pour pouvoir connaître le nombre d'éléments rouges déjà cliqués
            let wasClicked = this.booleans.filter(element => element);
            if (!this.booleans.every(element => element)) {
                // on retire pointer à l'élément qui vient d'être cliqué
                this.compatibleElements[key].className = "red";
                // on affiche la progression
                this.isTouchedOnce = true;
                console.log(`Vous êtes à ${wasClicked.length}/${this.booleans.length} du devant de la scène.`);
            }
            // tous les éléments sont à true
            else {
                this.completed();
            }
        }
    };

    //renvoie un booléen pour savoir si l'utilisateur a déjà touché une lettre mais pas terminée la quête
    hasTouchedLetter = () => {
        return this.isTouchedOnce;
    };

    /**
     * Commun à toutes les missions
     * Appel de la fonction completed du manager de quêtes
     */
    completed() {
        manager.completed(this.quest);
        //on prends l'ensemble des quêtes completés par l'utilisateur précédement
        let listQuest = JSON.parse(localStorage.getItem("quest"));
        this.isTouchedOnce = false;
        //on push la quête complétés dans le tableau des quêtes
        if (listQuest) {
            listQuest.push(this.quest);
            //on stocke ces changements dans le local storage
            localStorage.setItem("quest", JSON.stringify(listQuest));
        }

        //on signale que l'easter egg a été effectué une fois

        if (this.doneEvent === false) {
            this.doneEvent = true;
            if (this.hello) {
                this.hello();
            }
        }
    }
}
