import React from 'react';

const TownSvg = () => (
    <svg viewBox="0 0 11 11" width="40%" xmlns="http://www.w3.org/2000/svg">
        <path d="m3.695 1.1a.256.256 0 0 0 -.4 0l-2.24 2.831a.254.254 0 0 0 -.055.157v5.662a.25.25 0 0 0 .25.25h1.5a.25.25 0 0 0 .25-.25v-1.75h1v1.75a.25.25
            0 0 0 .25.25h.75v-4.5a.615.615 0 0 1 .147-.4l.853-1.1zm-.695 5.9h-1v-1h1zm0-2h-1v-1h1zm5.194-1.258a.248.248 0 0 0 -.387 0l-1.753 2.19a.249.249 0 0 0
            -.054.155v3.665a.248.248 0 0 0 .248.248h3.5a.248.248 0 0 0 .252-.244v-3.669a.249.249 0 0 0 -.054-.155zm-1.194 2.258h1v1h-1zm2 3h-1v-1h1z" />
    </svg>
);

export default TownSvg;