import React from 'react';

const Mentions = () => (
    <div id="mentionslegales" className="section">
        <div className="content">
            <h1 className="sectiontitle">
                <span className="red">M</span>entions Légales
            </h1>
            <div className="block-content">
                <div>
                    <h1><span className="red">É</span>diteur</h1>
                    <ul className="pad-text">
                        <li><span className="red">C</span>oddity</li>
                        <li>Société À Responsabilité Limitée</li>
                        <li>Au capital de 250 000 €</li>
                        <li>Immatriculée au RCS de Paris sous le n° 820 018 315</li>
                        <li>Dont le siège social est situé 37bis rue de Montreuil, 75011 Paris - France</li>
                        <li>Numéro de TVA intracommunautaire : FR 30 820018315</li>
                        <li>Téléphone : 01 88 33 48 54</li>
                        <li>Email : <a className="pointer" href="mailto:contact@coddity.com">contact@coddity.com</a></li>
                        <li>Directeur de la publication : Matthieu Moreau</li>
                    </ul>
                </div>
                <div>
                    <h1><span className="red">H</span>ébergeur</h1>
                    <ul className="pad-text">
                        <li><a href="https://www.ovh.com/">OVH</a></li>
                        <li>Société par Actions Simplifiée</li>
                        <li>Dont le siège social est situé 2 rue Kellermann, 59100 Roubaix - France</li>
                        <li>Téléphone : 10 07</li>
                    </ul>
                </div>
            </div>

            { /* -------------- RGPD */ }
            
            <div id="donnees" style={{marginTop: "10%"}}>
                <h1 className="sectiontitle">
                    <span className="red">T</span>raitement des données personnelles
                </h1>
                <div className="block-content">
                    <h1><span className="red">D</span>onnées collectées</h1>
                        <p>
                            Les données personnelles que vous nous communiquez lors de la soumission de votre demande dans le formulaire de contact
                            sont uniquement envoyées et traitées par l'équipe de direction de <span className="red">C</span>oddity.
                            Elles sont conservées pendant la durée des échanges et ne seront jamais communiquées à des tiers.
                            Les informations collectées incluent vos nom, fonction, nom de société et email.
                        </p>
                </div>
                <div className="block-content">
                    <h1><span className="red">U</span>tilisation des données</h1>
                        <p>
                            Nous n'utilisons ces données que pour répondre à votre demande de contact.
                        </p>
                </div>
                <div className="block-content">
                    <h1><span className="red">V</span>os droits</h1>
                        <p>
                            Vous pouvez demander l'accès à vos données personnelles si vous souhaitez modifier ou supprimer des données.
                            Pour émettre de telles demandes, ou si vous avez d'autres questions concernant nos pratiques de confidentialité,
                            veuillez contacter notre Délégué à la protection des données à l'adresse suivante : <a className="pointer" href="mailto:christopher@coddity.com">christopher@coddity.com</a>
                        </p>
                </div>
            </div>
        </div>
    </div>
);

export default Mentions;
