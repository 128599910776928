// -------------------------------------------------------------------------
// Librairies
import React from "react";
import ReactDOM from "react-dom";

// -------------------------------------------------------------------------
// Composants externes
import CoddingAnimation from "./CoddingAnimation";
import Start from "./Start";
import manager from "./libs/WarriorManager";
import play from "./Play";

// -------------------------------------------------------------------------
// Variables globales
let countdown = 3; // Le nombre de "tick" avant de lancer l'easter
let timer = null; // Conteneur du timer utilisé pour setInterval
let finishedCountdown = false; // Est-ce que le countdown est terminé ?
const css = "font-family: courier; font-size: 16px;"; // CSS en string utilisé pour la console
let isOnSectionPage = false; // On regarde si l'utilisateur est dans la section principale
// On append un conteneur <div> au body et on lui ajoute un composant CoddingAnimation
let container = document.createElement("div"); // Création d'un élément <div>

/**
 * Remet le timer à son état initial
 */
const stopTimer = () => {
    clearInterval(timer);
    countdown = 3;
};

/**
 * Lance le timer en affichant une phrase dans la console
 */
const startTimer = () => {
    if (!finishedCountdown) {
        console.log("%cstarting ...", css);
        timer = setInterval(timingTick, 500);
    }
};

/**
 * Fonction appelée à chaque tick de l'interval timer
 * Log le décompte puis une phrase dans l'attente d'une action de l'utilisateur
 * finishedCountdown passé à true lorsque le décompte est fini
 */
const timingTick = () => {
    if (countdown < 1) {
        finishedCountdown = true;
        stopTimer();
        displayStart();
        console.log("%cclick the button 'start' located at the very center of the page to play", css + "color: #cd4442;");
    } else {
        console.log(`%c${countdown}`, css);
        countdown -= 1;
    }
};

/**
 * Appelé lors du clic sur l'élément passé en paramètre de la fonction exportée
 * Si le countdown est fini, lance l'évènement "startEasters" sur le document
 */
const handleClick = () => {
    // création d'un évènement "starteasters"
    const startEvent = new Event("starteasters");
    // lancement de l'évènement précédemment créé
    document.dispatchEvent(startEvent);
};

const displayStart = () => {
    if (finishedCountdown) {
        let container = document.createElement("div"); // création d'un élément <div>
        document.body.appendChild(container); // on le place dans le body
        ReactDOM.render(<Start onClick={handleClick} />, container); // on lui transmet un composant Start en tant qu'enfant
    }
};

/**
 *  - Fonction exportée -
 * Sur l'élément passé en paramètre : écoute les events 'mouseenter' et 'mouseleave' pour savoir quand la souris passe au dessus
 * Lance de décompte de lancement de l'easter lorsque la souris passe au-dessus du composant
 * Arrête le décompte et le remet à 0 lorsque la souris n'est plus au-dessus du composant
 * Lors d'un clic sur l'élément, appelle la fonction handleClick définie précédemment
 */
export default function starter(domElement) {
    // listeners
    domElement.addEventListener("mouseenter", startTimer);
    domElement.addEventListener("mouseleave", stopTimer);
}
/**
 *
 * Fonction exportée
 * On affiche le composant CoddingAnimation dans cette fonction,
 * afin que CoddingAnimation puissse accéder à l'ensemble des éléments du DOM
 *
 */
export function load() {
    document.body.appendChild(container); // on le place dans le body
    ReactDOM.render(<CoddingAnimation isOnSectionPage={isOnSectionPage} />, container); // on lui transmet un composant CoddingAnimation en tant qu'enfant
    // On regarde si le premier dialogue de CoddingMan est apparu
    if (localStorage.getItem("coddingMan1")) {
        play();
        // On regarde si le local storage n'est pas nul
        if (localStorage.getItem("quest") !== null) {
            // On rajoute les quêtes qui sont dans le local storage
            const listQuest = JSON.parse(localStorage.getItem("quest"));
            if (listQuest) {
                listQuest.forEach(element => {
                    manager.completed(element, false);
                });
                console.log("Vous reprenez vos quêtes où vous les avez laissées.");
                console.log("Entrez 'inventaire()' pour lister vos objets.");
                console.log("Entrez 'journal()' pour parcourir le journal de quêtes.");
                console.log("Vous pouvez recommencer toutes les quêtes en entrant 'reset()'.");
            }
        }
    }
}

export function enterSectionPage() {
    isOnSectionPage = true;
    ReactDOM.render(<CoddingAnimation isOnSectionPage={isOnSectionPage} />, container); // on lui transmet un composant CoddingAnimation en tant qu'enfant
}

export function quitSectionPage() {
    isOnSectionPage = false;
    ReactDOM.render(<CoddingAnimation isOnSectionPage={isOnSectionPage} />, container); // on lui transmet un composant CoddingAnimation en tant qu'enfant
}
