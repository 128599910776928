import React, { useState } from "react";

const ConsultingSvg = () => {
    
    const [active, setActive] = useState(false);
    
    return(
            <div className="logotech">
                <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                        width="40%" viewBox="50 0 260 260" enableBackground="new 0 0 300 300" xmlSpace="preserve"
                        onMouseEnter={() => setActive(true)}
                        onMouseLeave={() => setActive(false)}
                        >
                    <path d="M226,248v-18.501C226,194.78,198.458,167,163.737,167h-25.886C103.129,167,73,194.78,73,229.499V248H226z"/>
                    <path d="M165.023,82.781l-13.987,19.529l14.236-6.836l-39.295,43.996l14.319-31.72l-1.621,0.849l-2.737,1.387l-7.706,3.906
                    l15.377-33.156c-18.724,3.326-32.945,19.673-32.945,39.353c0,22.082,17.9,39.983,39.983,39.983
                    c22.082,0,39.982-17.901,39.982-39.983C190.629,103.077,180,88.556,165.023,82.781z"/>
                    <path className={ active ? "infomove info":"info" } d="M249.775,25.724c-25.419,0-46.025,20.606-46.025,46.026c0,11.58,4.286,22.152,11.346,30.241L203.75,122.5l25.534-9.539
                    c6.172,3.075,13.127,4.814,20.491,4.814c25.42,0,46.026-20.606,46.026-46.026S275.195,25.724,249.775,25.724z M249.961,42.16
                    c3.92,0,7.098,3.178,7.098,7.098c0,3.921-3.178,7.097-7.098,7.097c-3.921,0-7.099-3.176-7.099-7.097
                    C242.862,45.338,246.04,42.16,249.961,42.16z M241.047,95.638c-8.573-4.47-2.287-20.004,1.27-26.584
                    c1.868-3.456-5.277-6.298-5.277-6.298s18.37-1.614,19.026,2.182c1.022,5.876-3.795,10.564-6.491,20.995
                    c-2.882,11.129,13.391,8.534,13.391,8.534S251.521,101.103,241.047,95.638z"/>
                </svg>
            </div>
    );
}

export default ConsultingSvg;