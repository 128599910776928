// * * * * * * * * * *
// Librairie React
import React from "react";
import _get from "lodash/get";
// * * * * * * * * * *
// Style
import styled, { keyframes } from "styled-components";
// * * * * * * * * * *
// Composants externes
import { CoddingManSvg } from "../assets/svg/";

/**
 * Codding Man appparaît et révèle les informations sur la quête
 */
export default class CoddingDialog extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            // Tableau des dialogues de codding man
            dialogs: [
                [
                    "Bonjour à toi, aventurier(e) du monde virtuel. Je suis Gonzague et je te souhaite la bienvenue.",
                    "Je veille sur ce domaine, caché dans l'ombre, depuis quelques années maintenant...",
                    "Mais ton enthousiasme a éveillé ma curiosité. Je dois avouer que c'est plutôt rare.",
                    "Mais tout cela n'aurait pas de sens si je n'avais rien à te proposer n'est-ce pas ?",
                    "Alors voilà...",
                    "J'ai placé sur ce site plusieurs autres artefacts. Certains virtuels, d'autres non...",
                    "Pars à la chasse pour tous les capturer ! Et qui sait... Peut-être y gagneras-tu quelque chose à force de détermination ?",
                    "Ha ha ha ha ha ! D'ici là, bon courage, et que l'aventure commence !"
                ],
                [
                    "Bravo aventurier(e) du monde virtuel, tu as fini toutes les quêtes que je t'ai proposées.",
                    "Tu es prêt(e) à rejoindre le panthéon des aventuriers.",
                    "Ton esprit de réflexion est fort impressionnant.",
                    "Au revoir, nos chemins se croiseront peut-être à nouveau."
                ]
            ],
            // Chaine de caractère actuellement affichée dans la boite de dialogue
            current: "",
            //
            printTimer: null,
            printing: false,
            index: 0,
            // booléen indiquant si la boîte de dialogue doit s'afficher
            clickedOnce: false,
            clickFinal: false
        };
    }

    /**
     * Appelé lors d'un clique sur sur le conteneur.
     * Avance d'une phrase le dialogue de Codding man si la précédente phrase a fini d'être affiché,
     * Sinon affiche la phrase en entière d'un coup
     */
    handleNext = () => {
        const { dialogs } = this.state;
        const { num } = this.props;
        if (!this.state.clickedOnce) {
            this.setState({ clickedOnce: true });
        }
        if (this.state.index < _get(dialogs, `[${num}].length`, 0) - 1) {
            if (this.state.printing) {
                this.printMessage();
            } else {
                this.printingMessageWithDelay();
            }
        } else if (this.state.index === _get(dialogs, `[${num}].length`, 0) - 1) {
            if (this.state.printing) {
                this.printMessage();
            } else if (!this.state.printing) {
                this.printingMessageWithDelay();
            }
        } else {
            this.props.onDisappear();
        }
    };

    /**
     * Ajoute un caractère à current si la phrase n'est pas finie, sinon arrête le timer puis incrémente l'index
     */
    oneMoreChar = () => {
        const { dialogs } = this.state;
        const { num } = this.props;
        if (this.state.current !== dialogs[num][this.state.index]) {
            this.setState(prevState => {
                return { current: this.state.dialogs[this.props.num][prevState.index].slice(0, prevState.current.length + 1) };
            });
        } else {
            clearInterval(this.state.printTimer);
            if (this.state.index < this.state.dialogs.length - 1) {
                this.setState(prevState => ({ index: prevState.index + 1, printing: false }));
            }
        }
    };

    /**
     * Affiche le message voulu d'un coup en arrêtant
     */
    printMessage = () => {
        clearInterval(this.state.printTimer);
        this.setState(prevState => {
            return {
                current: this.state.dialogs[this.props.num][prevState.index],
                printing: false,
                index: prevState.index + 1
            };
        });
    };

    /**
     * Ajoute le message de l'index actuel caractère par caractère à l'aide d'une horloge
     */
    printingMessageWithDelay = () => {
        this.setState({
            printTimer: setInterval(this.oneMoreChar, 20),
            current: "",
            printing: true
        });
    };

    /**
     * Lors de la création de l'objet, attend 4 secondes avant d'afficher la boîte de dialogue
     */
    componentDidMount = () => {
        setTimeout(this.handleNext, 4000);
    };

    render() {
        return (
            <CoddingContainer onClick={this.handleNext}>
                <CoddingManSvg />
                <DialogOuterwrap clickedOnce={this.state.clickedOnce}>
                    <DialogInnerwrap>
                        <Dialog>{this.state.current}</Dialog>
                    </DialogInnerwrap>
                </DialogOuterwrap>
            </CoddingContainer>
        );
    }
}

/**
 * Bordure extérieur de la boite de dialogue
 */
const DialogOuterwrap = styled.div`
    position: fixed;
    display: ${props => (props.clickedOnce ? "block" : "none")};
    top: 80%;
    left: 50%;
    transform: translate(-50%, 0);
    width: 350px;
    min-height: 100px;
    z-index: 4;
    border-radius: 8px;
    border 3px solid black;
    background-color: white;
`;

/**
 * Bordure intérieur de la boite de dialogue
 */
const DialogInnerwrap = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: calc(100% - 4px);
    height: calc(100% - 4px);
    border-radius: 4px;
    border: 3px solid black;
    padding: 10px;
    box-sizing: border-box;
`;

/**
 * conteneur du dialogue
 */
const Dialog = styled.p`
    font-family: sh-pinscher;
    font-size: 20px;
    line-height: 20px;
    text-align: left;
    margin: 0;
    cursor: default;
    user-select: none;
`;

/**
 * animation d'apparition et disparition
 */
const appearAnim = keyframes`
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
`;

/**
 * Conteneur centré de codding man
 */
const CoddingContainer = styled.div`
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    animation: ${appearAnim} 1s ease;
    z-index: 2;
`;
