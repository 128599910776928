import React from 'react';

const CoddingManSvg = () => (
    
            <svg width="330" height="328" xmlns="http://www.w3.org/2000/svg">
                <g>
                    <ellipse ry="160" rx="160" id="svg_11" cy="164" cx="164" strokeWidth="0" stroke="#000" fill="#fff"/>
                    <g className="lightning spaceshipmove">
                        <polygon id="svg_2" points="249.52,58.315 169.503,58.315 114.713,175.004 141.375,161.657 141.375,161.657 150.841,156.918 156.45,154.016 106.913,262.415 242.857,112.062 193.605,135.428" fill="#CD4442"/>
                        <polygon id="svg_3" points="155.818,58.315 98.223,183.263 109.051,177.84 165.49,58.315" fill="#1787B9"/>
                        <polygon id="svg_4" points="150.841,156.918 141.375,161.657 85.843,285.72 99.266,270.874" fill="#1787B9"/>
                        <polygon id="svg_5" points="165.49,58.315 109.051,177.84 114.713,175.004 169.503,58.315" fill="#FFFFFF"/>
                        <polygon id="svg_6" points="106.913,262.415 156.45,154.016 150.841,156.918 99.266,270.874" fill="#FFFFFF"/>
                    </g>
                    <g id="svg_7">
                        <path id="svg_8" d="m165,327.489c-90.719,0 -164.523,-72.903 -164.523,-162.513c0,-89.608 73.804,-162.51 164.523,-162.51s164.523,72.902 164.523,162.511c0,89.609 -73.804,162.512 -164.523,162.512zm0,-319.579c-87.68,0 -159.012,70.459 -159.012,157.067c0,86.607 71.333,157.069 159.012,157.069c87.679,0 159.012,-70.462 159.012,-157.069c0,-86.608 -71.333,-157.067 -159.012,-157.067z" fill="#1787B9"/>
                        <path id="svg_9" d="m165,320.355c-86.736,0 -157.302,-69.703 -157.302,-155.379c0,-85.675 70.566,-155.377 157.302,-155.377c86.735,0 157.3,69.702 157.3,155.377c0,85.676 -70.565,155.379 -157.3,155.379zm0,-305.312c-83.698,0 -151.791,67.26 -151.791,149.934c0,82.675 68.093,149.936 151.791,149.936c83.697,0 151.789,-67.261 151.789,-149.936c0,-82.674 -68.092,-149.934 -151.789,-149.934z" fill="#CD4442"/>
                    </g>
                </g>
            </svg>
);

export default CoddingManSvg;