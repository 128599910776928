import React, { useState } from 'react';

const PhilosophieSvg = ( { id } ) => {
  
  const [active, setActive] = useState(false);
  
  return(
    <div className="logo-tech">
      <svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="40%" viewBox="0 0 300 300" enableBackground="new 0 0 300 300" xmlSpace="preserve"
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
        id={id}
        >
        <g>
          <path id="startButton" d="M247.73,166.912c-4.863-9.863-7.689-16.81-10.396-23.002c-0.729-1.672-1.003-3.861-1.307-5.266
          c-0.472-2.349,0.911-7.919,0.911-7.919v0.008c3.299-5.472,10.791-14.712,10.791-32.335c-0.061-48.78-48.574-83.547-98.753-83.547
          c-50.14,0-98.753,46.173-98.753,91.283c0,27.7,11.459,52.998,23.672,75.582c5.259,9.742,30.428,38.012,32.578,50.734
          c3.344,19.773,1.033,41.021,7.052,43.939l72.771,1.338c6.201,0-2.371-21.006,1.186-41.555c2.401-13.938,36.933,7.965,43.65-11.703
          c2.098-6.154-0.806-11.611,0.213-20.26c0.79-6.869,0.973-10.531,1.854-21.08c0.243-2.674,0.547-4.832,0.912-6.717
          c2.797,0.652,6.109,0.729,11.08,1.215C247.549,177.84,252.655,176.959,247.73,166.912z"/>
        </g>
        <path className={ active ? "smwheel smwheelmove":"smwheel" } fill="#DDDDDD" d="M124.386,115.366c-0.106,0.639-0.805,1.049-1.565,0.912l-4.909-0.866c-1.14,2.827-2.781,5.38-4.818,7.508
        l3.131,4.469c0.426,0.623,0.35,1.429-0.183,1.793l-5.927,4.134c-0.517,0.38-1.307,0.182-1.748-0.456l-3.116-4.453
        c-2.705,1.17-5.654,1.839-8.724,1.93l-1.019,5.775c-0.137,0.76-0.76,1.277-1.383,1.155l-7.113-1.261
        c-0.638-0.122-1.064-0.821-0.927-1.565l1.018-5.776c-2.979-1.186-5.624-2.948-7.828-5.122l-4.027,2.812
        c-0.623,0.44-1.429,0.364-1.793-0.167l-4.149-5.927c-0.365-0.532-0.167-1.322,0.471-1.748l4.165-2.918
        c-1.064-2.599-1.672-5.411-1.748-8.299l-4.925-0.881c-0.745-0.122-1.261-0.76-1.14-1.398l1.246-7.113
        c0.122-0.639,0.821-1.034,1.565-0.912l4.909,0.882c1.14-2.857,2.797-5.381,4.818-7.523l-2.782-3.967
        c-0.425-0.639-0.334-1.444,0.183-1.809l5.927-4.149c0.517-0.365,1.307-0.152,1.748,0.486l2.766,3.967
        c2.72-1.17,5.669-1.854,8.724-1.93l0.775-4.347c0.122-0.729,0.76-1.261,1.398-1.155l7.113,1.277c0.639,0.106,1.034,0.806,0.912,1.55
        l-0.79,4.347c2.705,1.064,5.137,2.614,7.204,4.499l4.194-2.918c0.608-0.426,1.414-0.365,1.794,0.183l4.134,5.927
        c0.365,0.517,0.152,1.307-0.456,1.733l-4.043,2.827c1.292,2.827,2.037,5.913,2.113,9.12l4.924,0.866
        c0.745,0.137,1.262,0.76,1.14,1.398L124.386,115.366z"/>
        <path className={ active ? "lwheel lwheelmove":"lwheel" } fill="#DDDDDD" d="M214.218,91.238c-0.183,1.034-1.307,1.703-2.522,1.49l-0.122-0.015c-0.03,0-0.061,0.015-0.091,0.015
        l-7.827-1.398c-1.839,4.62-4.515,8.754-7.827,12.234l5.076,7.28c0.699,1.003,0.577,2.325-0.289,2.918l-9.651,6.749
        c-0.44,0.319-1.003,0.365-1.535,0.213c-0.577-0.091-1.14-0.395-1.535-0.958l-5.046-7.204c-4.377,1.869-9.119,2.948-14.028,3.085
        l-1.672,9.423c-0.212,1.216-1.23,2.067-2.265,1.885l-0.121-0.031c-0.03,0-0.061,0.031-0.092,0.031l-11.611-2.067
        c-1.033-0.183-1.702-1.322-1.489-2.554l1.672-9.423c-4.833-1.915-9.104-4.772-12.676-8.269l-6.475,4.514
        c-0.517,0.38-1.125,0.487-1.672,0.411c-0.578-0.016-1.125-0.213-1.459-0.699l-6.749-9.636c-0.608-0.882-0.273-2.158,0.729-2.857
        l6.839-4.772c-1.732-4.226-2.751-8.8-2.872-13.527l-8.025-1.429c-1.2-0.213-2.052-1.247-1.869-2.28l2.067-11.597
        c0.182-1.033,1.307-1.702,2.523-1.489l0.106,0.015c0.046,0,0.076-0.03,0.106-0.015l7.827,1.383c1.854-4.62,4.544-8.724,7.827-12.22
        l-4.529-6.475c-0.699-1.033-0.577-2.341,0.289-2.948l9.667-6.749c0.441-0.304,0.988-0.365,1.535-0.198
        c0.578,0.106,1.125,0.396,1.521,0.958l4.499,6.414c4.377-1.854,9.119-2.949,14.027-3.07l1.262-7.083
        c0.213-1.216,1.231-2.067,2.28-1.869l0.137,0.015c0.015,0.015,0.046-0.031,0.076-0.015l11.597,2.052
        c1.033,0.183,1.702,1.337,1.489,2.553l-1.262,7.067c4.347,1.733,8.237,4.225,11.627,7.265l6.702-4.682
        c0.532-0.38,1.141-0.486,1.688-0.41c0.578,0.03,1.125,0.228,1.459,0.699l6.733,9.667c0.607,0.851,0.288,2.127-0.729,2.827
        l-6.566,4.59c2.082,4.621,3.283,9.667,3.436,14.895l8.01,1.429c1.23,0.212,2.051,1.216,1.869,2.265L214.218,91.238z"/>
        <g>
          <path id="startButton" d="M148.035,74.702c1.923-10.829,12.273-18.056,23.095-16.125c10.837,1.922,18.056,12.265,16.141,23.094
          s-12.265,18.056-23.102,16.126C153.348,95.874,146.128,85.531,148.035,74.702z"/>
          <path id="startButton" d="M83.859,105.214c1.185-6.642,7.523-11.064,14.172-9.887c6.642,1.178,11.057,7.516,9.887,14.165
          c-1.186,6.642-7.516,11.064-14.165,9.887C87.111,118.201,82.681,111.863,83.859,105.214z"/>
        </g>
      </svg>
    </div>
  );
}

export default PhilosophieSvg;