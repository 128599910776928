import SHA1 from 'sha1';

/**
 * Personnage incarné par l'utilisateur. 
 * Dispose d'un inventaire d'items gagnés au cours des missions
 */
export default class Warrior{
    constructor(){
        this.itemList = [];
        this.questList = [];
    }

    reset(){
        localStorage.clear();
        this.itemList = [];
        this.questList = [];
    }
    /**
     * Affiche l'inventaire du guerrier par objet avec leur nom et description
     */
    inventory(){
        if(this.itemList.length === 0){
            console.log("Vous ne disposez d'aucun item. Etonnant étant donné que ce n'est pas possible. Auriez-vous... %cTRICHE?!","font-size: 18px;")
        }
        else {
            console.group('Inventaire');
            for(var i = 0 ; i < this.itemList.length ; i++){
                console.log(`%c${this.itemList[i].name}\n%c${this.itemList[i].desc}`,"font-size: 16px; font-weight: bold;","");
            }
            console.groupEnd();
        }
    }

    /**
     * Affiche le journal de quête du guerrier avec les indices et informations sur les prochaines quêtes débloqués
     */
    journal(){
        if(this.questList.length === 0){
            console.log("Aucune information relative aux quêtes disponible");
        }
        else {
            console.group("Journal");
            for(let idx = 0; idx < this.questList.length; idx++){
                if(this.questList[idx].id === 1){
                    console.log(`%c${this.questList[idx].title} %cquête principale\n%c${this.questList[idx].sentence}`,"font-size: 16px;","font-size: 16px; font-weight: bold;","font-style: italic");
                } else if(this.questList[idx].sentence === "COMPLETED !"){
                    console.log(`%c${this.questList[idx].title}\n%c${this.questList[idx].sentence}`,"font-size: 16px;","font-size : 16px; color: #1787B9; font-weight: bold;");
                } else {
                    console.log(`%c${this.questList[idx].title}\n%c${this.questList[idx].sentence}`,"font-size: 16px;","font-style: italic");
                }
            }
        }
        console.groupEnd();
    }

    /**
     * Ajoute l'item passé en crypté en paramètre s'il existe dans le tableau ITEMS et s'il n'existe pas déjà dans l'inventaire 
     * @param {SHA1 string} crypt 
     */
    addItem(crypt){
        for(var i = 0; i < Warrior.ITEMS.length; i++){
            if(crypt === SHA1(Warrior.ITEMS[i].name)){
                for(var j = 0 ; j < this.itemList ; j++){
                    if(Warrior.ITEMS[i].id === this.itemList[j])
                    {
                        return "Already exists"
                    }
                }
                this.itemList.push(Warrior.ITEMS[i])
                return "Item added";
            }
        }
        return "Doesn't exists"
    }

    /**
     * Ajoute ou met à jour les informations sur la quête dont l'indice passé en paramètre 
     * @param {Indice contenant {id, sentence, level}} clue 
     */
    addClue(clue){
        for(let idx = 0; idx < this.questList.length ; idx++){
            if(this.questList[idx].id === clue.id){
                if(this.questList[idx].level < clue.level){
                    this.questList[idx] = clue;
                    return "updated";
                }
                return null;
            }
        }
        this.questList.push(clue);
        return "added";
    }
}

/**
 * Items disponibles et possibles de récupérer dans son inventaire
 */
Warrior.ITEMS = [
    {
        id: 1,
        name: "Armure du débutant",
        desc: "Cette armure est non seulement très inconfortable, mais aussi très inutile. Ses plaques en bois déjà fissurées n'absorbent aucun dégât et ont même tendance à vous broyer les os plus qu'autre chose, d'autant plus qu'elle n'est pas à votre taille. Mais elle prouve votre détermination naissante, alors vous la gardez par fierté.",
        img: ""
    },
    {
        id: 2,
        name: "Bague lamentable",
        desc: "Tout comme la blague, mais à défaut d'avoir un l, la bague lamentable vous fait devenir la risée de tous en public et vous gênez vos amis les plus proches. Ceci dit, vous ne vous en lassez jamais et la gardez toujours sur vous pour la sortir au bon moment, sait-on jamais. Il paraît que chez Coddity, un Artisan les collectione... Pourquoi ? Personne ne le sait, et personne ne veut vraiment le savoir...",
        img: ""
    },
    {
        id: 3,
        name: "Chausson de danse en acier trempé",
        desc: "",
        img: ""
    },
    {
        id: 4,
        name: "Lunette anti-paparazzi",
        desc: "Ces lunettes que l'on pourrait tout simplement renommer en grosses lunettes de soleil rondes vous offrent le goût du luxe et de la popularité qui vous manquait dans votre vie. Cette popularité qui vous met sur le devant de la scène, devant ces fans parfois ingrats, qui vous lâcheront probablement pour la première nouvelle star du moment, avec ces paparazzis qui vous suivent sans relâche pour pointer du doigt chacun des faux-pas que vous aurez faits... Finalement, mieux vaut peut-être ne pas les porter.",
        img: ""
    },
    {
        id: 5,
        name: "Souris",
        desc: "Une souris de parfaite ergonomie qui favorise le positionnement de la main et des doigts pour cliquer tel un champion.",
        img: ""
    },
    {
        id: 6,
        name: "Flash",
        desc: "Barry Allen et Reverse Flash n'ont qu'à bien se tenir face à votre rapidité.",
        img: ""
    },
    {
        id:7,
        name: "Console de jeu",
        desc: "Pour l'activer, il faut taper au clavier la réponse à cette énigme : je commence par un b et je sers dans la construction de maisons ou de murs"
    }
]