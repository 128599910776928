import React from 'react';

export default function TextoSvg(){
    return(
        <div className="logotech">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="40%" height="40%" viewBox="0 0 23.671 23.671" style={{enableBackground:'new 0 0 23.671 23.671'}} xmlSpace="preserve">
                <g>
                    
                    <path d="M18.545,3.033c0.002-0.004,0.007-0.007,0.007-0.011c-1.82-1.073-4.018-1.712-6.388-1.768
                        c-0.373,0-0.741,0.005-1.157,0.024c-0.016,0-0.033,0-0.046,0c-0.018,0.001-0.035,0.001-0.053,0.001
                        c-0.07,0.101-0.325,0.016-0.469,0.045C10.101,1.386,9.751,1.436,9.376,1.46C9.33,1.469,9.282,1.481,9.237,1.49
                        C9.156,1.525,9.063,1.544,8.96,1.551C8.866,1.572,8.771,1.59,8.683,1.614C8.647,1.622,8.604,1.631,8.566,1.638
                        c-2.735,0.667-5.052,2.15-6.588,4.119l6.921,6.919L18.545,3.033z"/>
                    <path d="M3.32,18.256l4.785-4.783L1.334,6.704c-0.008,0-0.016,0-0.021,0C0.479,8.079,0,9.636,0,11.29
                        c0,1.231,0.274,2.404,0.754,3.497c0.418,0.886,0.942,1.671,1.522,2.406C2.594,17.567,2.944,17.922,3.32,18.256z"/>
                    <path d="M12.013,11.158l-8.196,8.195c-0.524,1.069-1.429,1.758-2.296,2.486c0.585,0.427,1.409,0.525,2.175,0.568
                        c1.055,0.06,2.078-0.16,2.925-0.473c0.572-0.212,1.321-0.744,1.843-0.805c0.527-0.059,1.145,0.181,1.747,0.239
                        c3.789,0.362,7.039-0.751,9.161-2.314c0.159-0.118,0.411-0.274,0.487-0.495l-7.607-7.605
                        C12.177,11.016,12.094,11.088,12.013,11.158z"/>
                    <path d="M19.513,3.655l-6.481,6.484l7.725,7.724c1.805-1.762,2.914-4.053,2.914-6.574C23.67,8.231,22.054,5.498,19.513,3.655z"/>
                    
                </g>
            </svg>
        </div>
    );
}

