import React from 'react';
import LogoItem from './logoitem/LogoItem';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaperclip } from '@fortawesome/free-solid-svg-icons';

import { ConsultingSvg, QualiteSvg } from '../assets/svg/';

import LogoLightBulb from '../img/lightbulb-solid.svg';

import "../css/project-grid.css";

const Services = ({striped}) => (
    <div id="services" className={`section ${striped}`}>
        <div className="content">
            <h1 className="sectiontitle">
                Services
            </h1>
            <div className="block-content">

                <div className="project-grid project-grid-max-3">

                    <div className="project-grid-item center">
                        
                        <ConsultingSvg />
                        
                        <h1><span className='red'>A</span>ssistance Technique</h1>
                        <p className="pad-text">
                            Notre équipe de <b>consultants</b> intervient en missions longues au sein de vos équipes pour apporter
                            la <span className='red'>c</span>ompétence <b>technique</b> ou <b>fonctionnelle</b> qui vous manque.
                            <br /><br />
                            Clients actuels : Renault, Société Générale, Chanel, SFR
                        </p>
                    </div>

                    <div className="project-grid-item center">
                        
                        <QualiteSvg />
                        
                        <h1>Direction Technique</h1>
                        <p className="pad-text">
                            Nous développons des applications métier <b>sur-mesure</b> sur la base
                            de votre <b>cahier des charges</b> :
                            conception, design UX, développement Agile, recette, formation et maintenance.
                            <br /><br />
                            Projets actuels : Gendarmerie Nationale, CCI de Belfort, Lagardère Travel Retail
                        </p>
                    </div>

                    <div className="project-grid-item center">
                        
                        <LogoItem imgSvg={LogoLightBulb} />
                        
                        <h1>Conseil</h1>
                        <p className="pad-text">
                            Nous apportons notre expertise lors d'interventions ponctuelles : <b>IA génératives</b>, design
                            thinking, <b>low-code</b>, organisation de projets.
                            <br /><br />
                            Dernières interventions : MEDEF Limousin, SFR, Team For The Planet
                        </p>
                    </div>
                </div>
            </div>

        </div>
    </div>
);

export default Services;
