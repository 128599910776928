import React, { useState } from 'react';

const CloudSvg = () => {
    
    const [active, setActive] = useState(false);

    return(
        <div className="logotech">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                width="40%" viewBox="0 -25 300 300" enableBackground="new 0 -25 300 300" xmlSpace="preserve"
                                onMouseEnter={() => setActive(true)}
                                onMouseLeave={() => setActive(false)}
                                >
                <g>
                    <path className={active ?"cloudarrowmove":"cloudarrow"} d="M208.144,173.561c-1.38-1.312-3.438-2.064-5.647-2.064c-2.386,0-5.562,0.851-6.954,2.314L157,212.324v-48.443v-61.135
                    c0-4.134-3.364-7.496-7.5-7.496s-7.5,3.362-7.5,7.496v61.049v48.344l-34.483-38.31c-1.429-1.504-2.763-2.332-4.666-2.332
                    c-1.773,0-3.271,0.732-4.672,2.063c-2.921,2.776-2.801,7.531,0.086,10.57l46.643,53.025c0.391,0.419,2.518,2.511,5.477,2.511
                    c3.044,0,5.535-2.462,5.547-2.474l52.473-53.035C211.25,181.162,211.138,176.408,208.144,173.561z"/>
                    <path className={active ? "cloudmove":"cloud"} d="M285.418,113.86C295.479,104.688,296,85,283.264,73.763c-13.336-11.766-43.209-6.196-47.017-5.277
                    c1.624-0.492,8.12-3.56,5.137-20.74c-4.34-24.954-41.485-47.489-65.479-26.748c0,0-2.32-20.844-32.501-20.741
                    C85.712,0.453,83.425,55.389,83.425,55.389S69.882,24.436,45.633,38.193C10.656,58.038,33.509,94.941,33.509,94.941
                    c-27.825-12.316-56.884,64.203-3.098,68.217L136,163.761v-61.015c0-7.442,6.057-13.496,13.5-13.496c7.444,0,13.5,6.054,13.5,13.496
                    v61.169l68.247,0.39c49.753,0,68.753,0,68.636-22.865C299.762,117.879,285.418,113.86,285.418,113.86z"/>
                </g>
            </svg>
        </div>
    );
}

export default CloudSvg;