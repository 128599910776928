import React from 'react';

const SparklingHeartSvg = () => (
	<div className="logotech">
		
		<svg viewBox="0 0 64 64" width="40%" xmlns="http://www.w3.org/2000/svg"><path d="m42.975 48.182-5.747-2.871 5.747-2.873 2.877-5.75 2.873 5.75 1.299.648c7.824-6.913 14.486-15.829 11.049-24.926-2.063-5.452-5.617-8.348-9.55-9.501l2.308 4.617 5.275 2.637-5.275 2.636-2.637 5.277-2.639-5.277-5.273-2.636 5.273-2.637 2.389-4.776c-8.15-2.02-17.676 3.189-18.946 8.782-2.643-9.005-22.892-15.721-29.071.89-1.468 3.947-1.091 7.807.426 11.465l5.77-2.885 3.566-7.127 3.563 7.127 7.127 3.563-7.127 3.562-3.563 7.129-3.566-7.129-4.944-2.473c6.594 12.651 26.013 22.549 27.819 24.596.853-.77 5.644-3.475 11.157-7.459zm-.694-23.867 3 3-3 3-3-3zm-20.403-.662-3-3 3-3 3 3zm4 20.406-2.5-2.5 2.5-2.5 2.5 2.5z"/></svg>
		
	</div>
);

export default SparklingHeartSvg;
