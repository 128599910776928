import React from 'react';

export default function LuigiSvg(){
    return(
        <div className="logotech">
            <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
            width="40%" height="40%" viewBox="0 0 723.000000 723.000000"
            preserveAspectRatio="xMidYMid meet">
                <metadata>
                Created by potrace 1.15, written by Peter Selinger 2001-2017
                </metadata>
                <g transform="translate(0.000000,723.000000) scale(0.100000,-0.100000)" fill="#000000" stroke="none">
                    <path d="M2020 6950 l0 -220 -220 0 -220 0 0 -445 0 -445 -225 0 -225 0 0
                    -445 0 -445 225 0 225 0 0 -225 0 -225 220 0 220 0 0 225 0 225 445 0 445 0 0
                    220 0 220 -220 0 -220 0 0 225 0 225 220 0 220 0 0 220 0 220 445 0 445 0 0
                    -445 0 -445 225 0 225 0 0 -220 0 -220 -225 0 -225 0 0 -225 0 -225 895 0 895
                    0 0 225 0 225 -445 0 -445 0 0 220 0 220 -225 0 -225 0 0 445 0 445 670 0 670
                    0 0 225 0 225 -670 0 -670 0 0 220 0 220 -1115 0 -1115 0 0 -220z m0 -1555 l0
                    -445 -220 0 -220 0 0 445 0 445 220 0 220 0 0 -445z"/>
                    
                    <path d="M1580 3840 l0 -220 -225 0 -225 0 0 -225 0 -225 -40 0 -40 0 0 -220
                    0 -220 265 0 265 0 0 -225 0 -225 220 0 220 0 0 -220 0 -220 -220 0 -220 0 0
                    -445 0 -445 -225 0 -225 0 0 -225 0 -225 -40 0 -40 0 0 -220 0 -220 710 0 710
                    0 0 445 0 445 220 0 220 0 0 220 0 220 445 0 445 0 0 -220 0 -220 225 0 225 0
                    0 -445 0 -445 890 0 890 0 0 220 0 220 -220 0 -220 0 0 225 0 225 -225 0 -225
                    0 0 445 0 445 -220 0 -220 0 0 220 0 220 220 0 220 0 0 225 0 225 445 0 445 0
                    0 220 0 220 -220 0 -220 0 0 225 0 225 -225 0 -225 0 0 220 0 220 -1780 0
                    -1780 0 0 -220z m1330 -1335 l0 -225 -220 0 -220 0 0 225 0 225 220 0 220 0 0
                    -225z m1340 0 l0 -225 -225 0 -225 0 0 225 0 225 225 0 225 0 0 -225z"/>
                </g>
            </svg>
        </div>
    );
}