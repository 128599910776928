import React from 'react';

export default function RefrigirateurSvg(){
    return(
        <div className="logotech">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                width="40%" height="40%" viewBox="0 0 60 60" style={{enableBackground:'new 0 0 60 60'}} xmlSpace="preserve">
                <g>
                    <polygon points="12,24 12,27 13,27 18,27 19,27 24,27 25,27 25,24 	"/>				
                    <polygon points="24,33 19,33 19,29 18,29 18,33 13,33 13,29 12,29 12,34 25,34 25,29 24,29 	"/>
					<path d="M29,0H10.561C8.046,0,6,2.046,6,4.561v52.51C6,58.686,7.314,60,8.93,60H29V0z M27,29v5v5H10v-5v-5v-2v-5h17v5V29z"/>
					<rect x="12" y="36" width="13" height="1"/>
					<path d="M48,24H35v13h13V24z M46,28h-9c-0.553,0-1-0.447-1-1s0.447-1,1-1h9c0.553,0,1,0.447,1,1S46.553,28,46,28z"/>
					<path d="M49.403,0H31v60h20.07c1.615,0,2.93-1.314,2.93-2.93V4.54C53.949,2.037,51.887,0,49.403,0z M41,7h2c0.553,0,1,0.447,1,1
						s-0.447,1-1,1h-2c-0.553,0-1-0.447-1-1S40.447,7,41,7z M50,39H33V22h17V39z"/>
                </g>
            </svg>
        </div>
    );
}

