import React from "react";
import "./GameBoard.css";
import Rectangle from "./Rectangle.js";
import Ball from "./Ball";
import Brick from "./Brick";
import Param from "../Parametre/Parametre";
import Bonus from "../Bonus/Bonus";
import upLife from "../../asset/sound/mario-1up.mp3";
import grownUp from "../../asset/sound/mushroom.mp3";
import Menu from "../Menu/Menu";
import Boss from "../Boss/Boss";
import BoardingScore from "../Parametre/BoardingScore";
import sky from "../../asset/image/sky.jpg";
import prairie from "../../asset/image/backgrounnd.jpg";
import code from "../../asset/image/code.jpg";
//musique principale du jeu (à définir)
//import music from "../../asset/sound/strike-earth.mp3"
//muisque de victoire (à définir)
//import victory from "../../asset/sound/fanfare.mp3"
//taille de l'écran
const MAX_HEIGHT = 720;
const MAX_WIDTH = 1000;
//boléen qui permet de savoir si l'utilisateur veut quitter le jeu
//diamètre de la balle
const BALL_DIAMETER = 21;
//taille de la raquette
const PAD_WIDTH = 106;
const PAD_HEIGHT = 16;
//taille du base du viseur
const HEIGHT_BASE_VISEUR = 250;
//coordonnées du viseur de base 
const BASE_VISEURX = 503;
const BASE_VISEURY = 383;
//coordonnées de départ pour la raquette
const BASE_PAD_COORDX = 450;
const PAD_COORDY = 644;
//coordonnées de départ pour la balle
const BASE_BALL_COORDX = 493;
const BASE_BALL_COORDY= 622;


export default class GameBoard extends React.Component {
  
    //constructeur
    constructor(props) {
        super(props);
        //état local
        this.state = {
            //coordonnées de notre raquette
            coordJX: BASE_PAD_COORDX,
            //coordonnées de notre balle
            //on a un tableau ce qui permet d'avoir plusieurs balles en même temps
            coordBallX: [],
            coordBallY: [],
            //tableau de vecteur de déplacement des balles
            vectBallX: [6],
            vectBallY: [6],
            //boléen qui s'active lorsque le joueur perd une vie
            lose: false,
            //boléen qui s'active lorsqu'on joue
            play: false,
            //tableau qui stocke les briques à détruire
            brick: [],
            //nombre de vies du joueur
            life: 3,
            //niveau actuel
            //plus le niveau est élévé, plus la balle est rapide
            lvl: 1,
            //boléen qui est vrai si on commence le jeu
            first: true,
            //boléen qui est vrai si on gagne
            win: false,
            //boléen qui s'active lorsqu'on perd
            defeat: false,
            //tableau qui stocke les bonus
            bonus: [],
            //nombre de balle dans le jeu
            nbBall: 1,
            //lorsqu'on utilise le viseur avec une balle, prends la valeur du numéro de la balle
            throw: null,
            //taille supplémentaire de la raquette (utilisé lorsqu'on veut agrandir la raquette)
            plusHeight: 0,
            //boléen qui est vrai quand on évite les collisions des brique
            notCollide: false,
            //temps restant au bonus brickDestroyer
            timer: null,
            //temps total du bonus brickDestroyer
            initialTime: null,
            //dégat de la balle
            degat: 1,
            //score de joueur
            score: 0,
            //nombre de fois que le joueur peut viser
            viseur: 0,
            //boléen qui s'active lorsqu'on ne veut plus bouger la raquette (pour une animation)
            animation: false,
            //boléen qui est vrai si la raquette se prends une attaque de boss
            damage: false,
            //boléen qui est vrai lorsqu'on veut afficher le score du joueur
            showScore: false,
            //boléen qui est vrai lorsqu'on combat un boss
            bossFigth: false,
            //nombre de rencontre de boss
            bossEncounter: 0,
            //boléen qui est vrai quand on bat un boss
            bossBeat: false,
            //fond d'écran du jue
            background: 0,
            //sprite utilisé pour le boss
            bossSprite: 0,
        };
        //intervalle qui permet de déplacer les bonus
        this.falling = null;
        //intervalle qui permet de déplacer les balles
        this.move = null;
        //intervalle qui s'active quand le bonus brickDestroyer est activé
        this.destroy = null;
        //intervalle qu'on utilise quand la raquette subit des dommages
        this.takeDamage = null;
        //son lorsqu'on gagne une vie
        this.newLifeSound = new Audio(upLife);
        //son qui s'active quand on agrandit notre raquette
        this.biggerSound = new Audio(grownUp);
        //reglage du son des effets sonore
        this.biggerSound.volume = 0.5;
        this.newLifeSound.volume = 0.5;
        //musique principale
        this.mainMusic = null;
        //musique de victoire
        this.fanfare = null;
        this.quit=false
    }

    componentWillUnmount() {
        if (this.move) {
            clearInterval(this.move);
        }
        if (this.falling) {
            clearInterval(this.falling);
        }
        /*   if(this.mainMusic)
        {
        this.mainMusic.pause();
        delete(this.mainMusic);
        } */
        /* if(this.fanfare){
        this.fanfare.pause();
        delete(this.fanfare);
        } */
    }

    //fonction qui gère les déplacements de la raquette
    onMouseMove = event => {
        //tant qu'on a pas envoyé la balle on ne touche pas la raquette
        if (this.state.throw === null && this.state.play && this.state.animation === false) {
            //sinon on calcule la position de la souris par rapport au cadre du jeu
            //on déplace la raquette en fonction de la position de la souris
            const element = document.getElementsByClassName("board");
            if (element) {
                const bounds = element[0].getBoundingClientRect();
                let newX = event.clientX - bounds.left - 50 - this.state.plusHeight / 2;
                if (newX < 0) {
                    newX = 0;
                }
                if (newX + this.state.plusHeight > MAX_WIDTH - PAD_WIDTH) {
                    newX =  MAX_WIDTH - PAD_WIDTH - this.state.plusHeight;
                }
                this.setState({ coordJX: newX });
            }
        }
    };
    
    collisionBrick = nb => {
        //tableau qui va stocker les index des briques à supprimer
        let ending = [];
        const { coordBallX, coordBallY, vectBallX, vectBallY } = this.state;
        //utile si on autorise le joueur a détruire 2 balle
        const oldX = coordBallX[nb];
        const oldY = coordBallY[nb];
        const oldVx = vectBallX[nb];
        const oldVy = vectBallY[nb];
        const brick_height = 26;
        const brick_width = 66;
        if (vectBallX[nb] !== 0 || vectBallY[nb] !== 0) {
            //on regarde chaque brique
            this.state.brick.forEach(el => {
                //si on a pas encore touché une brique alors
                //on regarde si la balle va toucher une brique lors de la prochaine étape
    
                //Remarque: si on veut autoriser le joueur a touché 2 brique en même temps
                //il suffit de mettre ending.length<1 dans la condition suivant
                if (
                    oldX + oldVx + BALL_DIAMETER >= el.coordX &&
                    oldX + oldVx <= el.coordX + brick_width &&
                    oldY + oldVy + BALL_DIAMETER >= el.coordY &&
                    oldY + oldVy <= el.coordY + brick_height &&
                    el.hit === 1 &&
                    ending.length < 1
                ) {
                    //si c'est le cas alors on stocke l'index de la brique qui va être touché
                    ending.push(el);
                    if (nb !== this.state.notCollide) {
                        //On change les vecteurs de la balle en fonction du vecteur actuelle de la balle
                        if (vectBallX[nb] >= 0 && vectBallY[nb] >= 0) {
                            //variable qui permet de savoir si la balle va toucher le bord de la brique
                            const intervalle = coordBallX[nb] + vectBallX[nb] - el.coordX + BALL_DIAMETER - 
                            (coordBallY[nb] + vectBallY[nb] - el.coordY + BALL_DIAMETER);
                            if (Math.abs(intervalle) <= 7) {
                                coordBallX[nb] = el.coordX - BALL_DIAMETER;
                                coordBallY[nb] = el.coordY - BALL_DIAMETER;
                                vectBallX[nb] = -vectBallX[nb];
                                vectBallY[nb] = -vectBallY[nb];
                                this.setState({ 
                                    coordBallX, 
                                    coordBallY, 
                                    vectBallX, 
                                    vectBallY 
                                });
                            } 
                            else {
                                const min = Math.min(
                                    Math.abs(coordBallX[nb] + vectBallX[nb] - el.coordX + BALL_DIAMETER),
                                    Math.abs(coordBallY[nb] + vectBallY[nb] - el.coordY + BALL_DIAMETER)
                                );
                                if (min === Math.abs(coordBallX[nb] + vectBallX[nb] - el.coordX + BALL_DIAMETER)) {
                                    coordBallX[nb] = el.coordX - BALL_DIAMETER;
                                    coordBallY[nb] = coordBallY[nb] + vectBallY[nb];
                                    vectBallX[nb] = -vectBallX[nb];
                                    this.setState({
                                        coordBallX,
                                        coordBallY,
                                        vectBallX
                                    });
                                } 
                                else {
                                    coordBallX[nb] = coordBallX[nb] + vectBallX[nb];
                                    coordBallY[nb] = el.coordY - BALL_DIAMETER;
                                    vectBallY[nb] = -vectBallY[nb];
                                    this.setState({
                                        coordBallX,
                                        coordBallY,
                                        vectBallX
                                    });
                                }
                            }
                        } 
                        else if (vectBallX[nb] <= 0 && vectBallY[nb] >= 0) {
                            const intervalle=coordBallX[nb] + vectBallX[nb] - (el.coordX + brick_width) - 
                            (coordBallY[nb] + vectBallY[nb] - el.coordY + BALL_DIAMETER);
                            if (Math.abs(intervalle) <= 7) {
                                coordBallX[nb] = el.coordX + brick_width;
                                coordBallY[nb] = el.coordY - BALL_DIAMETER;
                                vectBallX[nb] = -vectBallX[nb];
                                vectBallY[nb] = -vectBallY[nb];
                                this.setState({ 
                                    coordBallX, 
                                    coordBallY, 
                                    vectBallX, 
                                    vectBallY 
                                });
                            } 
                            else {
                                const min = Math.min(
                                    Math.abs(coordBallX[nb] + vectBallX[nb] - (el.coordX + brick_width)),
                                    Math.abs(coordBallY[nb] + vectBallY[nb] - el.coordY + BALL_DIAMETER)
                                );
                                if (min === Math.abs(coordBallX[nb] + vectBallX[nb] - (el.coordX + brick_width))) {
                                    coordBallX[nb] = el.coordX + brick_width;
                                    coordBallY[nb] = coordBallY[nb] + vectBallY[nb];
                                    vectBallX[nb] = -vectBallX[nb];
                                    this.setState({ 
                                        coordBallX, 
                                        coordBallY, 
                                        vectBallX 
                                    });
                                } 
                                else {
                                    coordBallX[nb] = coordBallX[nb] + vectBallX[nb];
                                    coordBallY[nb] = el.coordY - BALL_DIAMETER;
                                    vectBallY[nb] = -vectBallY[nb];
                                    this.setState({ 
                                        coordBallX, 
                                        coordBallY, 
                                        vectBallY 
                                    });
                                }
                            }
                        } 
                        else if (vectBallX[nb] <= 0 && vectBallY[nb] <= 0) {
                            const intervalle=coordBallX[nb] + vectBallX[nb] - (el.coordX + brick_width) - 
                            (coordBallY[nb] + vectBallY[nb] - (el.coordY + brick_height));
                            if (Math.abs(intervalle) <= 7) {
                                coordBallX[nb] = el.coordX + brick_width;
                                coordBallY[nb] = el.coordY + brick_height;
                                vectBallX[nb] = -vectBallX[nb];
                                vectBallY[nb] = -vectBallY[nb];
                                this.setState({ 
                                    coordBallX,
                                    coordBallY, 
                                    vectBallX, 
                                    vectBallY 
                                });
                            } 
                            else {
                                const min = Math.min(
                                    Math.abs(coordBallX[nb] + vectBallX[nb] - (el.coordX + brick_width)),
                                    Math.abs(coordBallY[nb] + vectBallY[nb] - (el.coordY + brick_height))
                                );
                                if (min === Math.abs(coordBallX[nb] + vectBallX[nb] - (el.coordX + brick_width))) {
                                    coordBallX[nb] = el.coordX + brick_width;
                                    coordBallY[nb] = coordBallY[nb] + vectBallY[nb];
                                    vectBallX[nb] = -vectBallX[nb];
                                    this.setState({
                                        coordBallX,
                                        coordBallY,
                                        vectBallX
                                    });
                                } 
                                else {
                                    coordBallY[nb] = el.coordY + brick_height;
                                    coordBallX[nb] = coordBallX[nb] + vectBallX[nb];
                                    vectBallY[nb] = -vectBallY[nb];
                                    this.setState({
                                        coordBallX,
                                        coordBallY,
                                        vectBallY
                                    });
                                }
                            }
                        } 
                        else if (vectBallX[nb] >= 0 && vectBallY[nb] <= 0) {
                            const intrvalle=coordBallX[nb] + vectBallX[nb] - el.coordX + BALL_DIAMETER - 
                            (coordBallY[nb] + vectBallY[nb] - (el.coordY + brick_height));
                            if (Math.abs(intrvalle) <= 7) {
                                coordBallX[nb] = el.coordX - BALL_DIAMETER;
                                coordBallY[nb] = el.coordY + brick_height;
                                vectBallX[nb] = -vectBallX[nb];
                                vectBallY[nb] = -vectBallY[nb];
                                this.setState({
                                    coordBallX,
                                    coordBallY,
                                    vectBallX,
                                    vectBallY
                                });
                            } 
                            else {
                                const min = Math.min(
                                    Math.abs(coordBallX[nb] + vectBallX[nb] - el.coordX + BALL_DIAMETER),
                                    Math.abs(coordBallY[nb] + vectBallY[nb] - (el.coordY + brick_height))
                                );
                                if (min === Math.abs(coordBallX[nb] + vectBallX[nb] - el.coordX + BALL_DIAMETER)) {
                                    coordBallX[nb] = el.coordX - BALL_DIAMETER;
                                    coordBallY[nb] = coordBallY[nb] + vectBallY[nb];
                                    vectBallX[nb] = -vectBallX[nb];
                                    this.setState({
                                        coordBallX,
                                        coordBallY,
                                        vectBallX
                                    });
                                } 
                                else {
                                    coordBallY[nb] = el.coordY + brick_height;
                                    coordBallX[nb] = coordBallX[nb] + vectBallX[nb];
                                    vectBallY[nb] = -vectBallY[nb];
                                    this.setState({ 
                                        coordBallX, 
                                        coordBallY, 
                                        vectBallY 
                                    });
                                }
                            }
                        }
                    }
                }
            });
            //si on a touché des brique
            if (ending.length > 0) {
                let indexToDelete;
                let tab = this.state.bonus;
                let { brick, score } = this.state;
                const nextX = 60;
                const nextY = 40;
                //alors on regarde l'ensemble des briques
                ending.forEach(el => {
                    //on augmente le socre du joueur
                    score += 100;
                    indexToDelete = brick.indexOf(el);
                    brick[indexToDelete].HP = brick[indexToDelete].HP - this.state.degat;
                    //si la brique n'a plus de Hp alors on la détruit
                    if (brick[indexToDelete].HP <= 0) {
                        const item = brick[indexToDelete];
                        //les briques adjacente à la brique détruite sont maintenant destructible
                        brick.forEach(el => {
                            if (
                                (el.coordX === item.coordX + nextX && el.coordY === item.coordY) ||
                                (el.coordX === item.coordX - nextX && el.coordY === item.coordY) ||
                                (el.coordY === item.coordY - nextY && el.coordX === item.coordX) ||
                                (el.coordY === item.coordY + nextY && el.coordX === item.coordX)
                            ) {
                                el.hit = 1;
                            }
                        });
                        //on fait tomber le bonus de la brique (si elle est en a)
                        if (item.bonus) {
                            tab.push({ coordX: item.coordX + 22, coordY: item.coordY + 5, type: item.bonus });
                        }
                        if (tab.length === 1 && this.falling === null) {
                            const timeInterval = 10 + 10 / this.state.lvl
                            this.falling = setInterval(this.fall, timeInterval);
                        }
                        brick.splice(indexToDelete, 1);
                    }
                });
                this.setState({
                    bonus: tab,
                    brick: brick,
                    score
                });
            }
            //On retourne l'index de la brique qui a été supprimé
        }
    
        return ending;
    };

    //ajoute une balle dans le jeu
    addBall = () => {
        const { coordBallX, coordBallY, vectBallX, vectBallY, nbBall } = this.state;
        const newX = coordBallX[nbBall - 1];
        const newY = coordBallY[nbBall - 1];
        //si la dernière balle ajouté n'est pas en train d'être lancé grâce au bonus viseur
        //alors on crée une balle
        if (this.state.throw !== nbBall - 1) {
            const vectX = -vectBallX[nbBall - 1];
            const vectY = -vectBallY[nbBall - 1];
            this.setState(prevState => ({
                coordBallX: [...prevState.coordBallX, newX],
                coordBallY: [...prevState.coordBallY, newY],
                vectBallX: [...prevState.vectBallX, vectX],
                vectBallY: [...prevState.vectBallY, vectY],
                nbBall: prevState.nbBall + 1
            }));
        }
        //sinon on crée une balle qui part de la raquette avec un angle aléatoire
        else {
            const dist = 10 + this.state.lvl * 2;
            const angle = this.degToRad(Math.random() * (140) - 70);
            this.setState(prevState => ({
                coordBallX: [...prevState.coordBallX, newX + Math.sin(angle) * dist],
                coordBallY: [...prevState.coordBallY, newY - Math.cos(angle) * dist],
                vectBallX: [...prevState.vectBallX, Math.sin(angle) * dist],
                vectBallY: [...prevState.vectBallY, -Math.cos(angle) * dist],
                nbBall: prevState.nbBall + 1
            }));
        }
    };

    //agrandit la raquette
    largePad = () => {
        //si la raquette n'a pas déjà été agrandi
        if (this.state.plusHeight === 0) {
            let i = 0;
            //alors on active l'animation d'aggrandissement de la raquette
            //puis on agrandit la raquette
            clearInterval(this.move);
            this.setState({ animation: true });
            let modifFalling = false;
            if (this.falling) {
                clearInterval(this.falling);
                this.falling = null;
                modifFalling = true;
            }
            //voici l'intervalle d'animation de l'agrandissement de la raquette
            const miniInterval = setInterval(() => {
                if (i === 1 || i === 3 || i === 6) {
                    this.setState({ plusHeight: 0 });
                } 
                else if (i === 0 || i === 2 || i === 4 || i === 7) {
                    this.setState({ plusHeight: 25 });
                }
                if (i === 5) {
                    this.setState({ plusHeight: 50 });
                }

                if (i === 8) {
                    const timeInterval = 10 + 10 / this.state.lvl;
                    this.setState({ plusHeight: 50, animation: false });
                    clearInterval(miniInterval);
                    this.move = setInterval(this.goAllBall, timeInterval);
                    if (modifFalling) {
                        this.falling = setInterval(this.fall, timeInterval);
                    }
                }
                i++;
            }, 100);
            //on joue les bruitages
            this.biggerSound.play();
        }
    };

    //une balle traverse les briques
    //note il faut définir la durée de ce bonus (afin que le jeu soit optimal)
    destroyBrick = () => {
        //si le timer est null
        if (this.state.timer === null) {
            //alors on active le bonus pendant un certain temps
            this.setState(prevState => ({
                notCollide: prevState.nbBall - 1,
                degat: 100,
                timer: "5",
                initialTime: 5
            }));
            let i = 0;
            this.destroy = setInterval(() => {
                //on regarde combien de temps il reste au bonus pour s'activer
                //si il ne reste plus de temps on arrête le bonus
                const time = this.state.initialTime - 1 - i;
                if (time <= 0) {
                    this.setState({
                        notCollide: false,
                        degat: 1,
                        timer: null,
                        initialTime: null
                    });
                    clearInterval(this.destroy);
                } 
                else {
                    this.setState({
                        timer: time
                    });
                }
                i++;
            }, 1000);
        }
        //si le bonus est déjà activé, on rajoute 5 seconde en plus
        else {
            this.setState(prevState => ({
                initialTime: prevState.initialTime + 5,
                timer: prevState.timer + 5
            }));
        }
    };

    //bonus qui permet de choisir la direction de la balle 3 fois
    viseur = () => {
        this.setState({
            viseur: 3
        });
    };

    //ajoute une vie
    addLife = () => {
        this.setState(prevState => ({
            life: prevState.life + 1
        }));
        //bruitage de vie
        this.newLifeSound.play();
    };

    //ajoute des dégats
    moreDmg = () => {
        this.setState({ degat: 2 });
    };

    //remet les dégat à 1
    lessDmg = () => {
        this.setState({ degat: 1 });
    };

    //ajoute un bonus en fonction des paramètre
    //type: string qui definit le type de bonus à appliquer sur le jeu
    addBonus = type => {
        switch (type) {
            case "multi":
                this.addBall();
                break;
            case "large":
                this.largePad();
                break;
            case "brickDestroyer":
                this.destroyBrick();
                break;
            case "viseur":
                this.viseur();
                break;
            case "addLife":
                this.addLife();
                break;
            case "moreDammage":
                this.moreDmg();
                break;
            case "lessDammage":
                this.lessDmg();
                break;
            default:
        }
    };

    //fonction qui fait tomber des bonus et gére la collision entre les bonus et la raquette
    fall = () => {
        const { bonus } = this.state;
        const toDestroy = [];
        //variable indiquant la  taille de la hitbox des différents bonus
        const bonus_height = 22;
        const bonus_width = 10;
        //coordonné y où les bonus peuvent être récupérer par la raqueete
        const dist = 3;
        if (bonus.length !== 0) {
            //on regarde chaque bonus
            bonus.forEach((el, index) => {
                //si il y a une collision entre la raquette et le bonus
                //on applique le bonus
                if (
                    el.coordY + bonus_height >= PAD_COORDY &&
                    el.coordY <= PAD_COORDY + PAD_HEIGHT &&
                    el.coordX + bonus_width >= this.state.coordJX &&
                    el.coordX + bonus_width <= this.state.coordJX + PAD_WIDTH + this.state.plusHeight
                ) {
                    //on stocke l'index du bonus dans un tableau
                    toDestroy.push(index);
                    this.addBonus(el.type);
                }
                //si le bonus n'est pas sorti du cadre de jeu
                //on continue de le faire tomber
                else if (el.coordY + dist < MAX_HEIGHT - BALL_DIAMETER) {
                    el.coordY += dist;
                }
                //sinon on stocke l'index du bonus pour le supprimer
                else {
                    toDestroy.push(index);
                }
            });
            //On supprime tout les bonus qui doivent sont stocké dans la variable toDestroy
            toDestroy.forEach(el => {
                bonus.splice(el, 1);
            });
            //si il n'y a plus de bonus
            //on arrête l'intervalle
            if (bonus.length === 0) {
                clearInterval(this.falling);
                this.falling = null;
            }
            this.setState({ bonus });
        }
    };

    //fonction qui fait bouger l'ensemble des balles sur le terrain
    goAllBall = () => {
        for (let i = 0; i < this.state.nbBall; i++) {
            this.go(i);
        }
    };

    //fonction qui gére le déplacement de la balle
    //nb : numéro de la balle à controller
    go = nb => {
        //on regarde si la balle touche
        const check = this.collisionBrick(nb);
        const { coordBallX, coordBallY, vectBallX, vectBallY } = this.state;
        //si elle touche pas de brique alors
        if (check.length === 0) {
            //on gére la collision dans le coin 0 0 du cadre de jeu
            if (coordBallX[nb] + vectBallX[nb] <= 0 && coordBallY[nb] + vectBallY[nb] <= 0) {
                coordBallX[nb] = 0;
                coordBallY[nb] = 0;
                vectBallX[nb] = -vectBallX[nb];
                vectBallY[nb] = -vectBallY[nb];
                this.setState(() => ({ coordBallY, coordBallX, vectBallX, vectBallY }));
            } 
            else if (coordBallX[nb] + BALL_DIAMETER + vectBallX[nb] >= MAX_WIDTH && coordBallY[nb] + vectBallY[nb] <= 0) {
                coordBallY[nb] = 0;
                coordBallX[nb] = MAX_WIDTH - BALL_DIAMETER;
                vectBallX[nb] = -vectBallX[nb];
                vectBallY[nb] = -vectBallY[nb];
                this.setState(() => ({ coordBallY, coordBallX, vectBallX, vectBallY }));
            }
            //on gére le déplacement de la balle lorsque le balle est au niveau de la raquette
            else if (coordBallY[nb] + BALL_DIAMETER + vectBallX[nb] > PAD_COORDY && vectBallY[nb] > 0) {
                //cette fonction gére la collision entre la raquette et la balle
                this.checkCollision(nb);
            }
            //on gére la collision entre la balle et le haut du cadre du jeu
            else if (coordBallY[nb] + vectBallY[nb] <= 0 && vectBallY[nb] < 0) {
                coordBallX[nb] = coordBallX[nb] + vectBallX[nb];
                coordBallY[nb] = 0;
                vectBallY[nb] = -vectBallY[nb];
                this.setState({
                    coordBallX,
                    coordBallY,
                    vectBallY
                });
            }
            //on gére la collision entre la balle et la droite de cadre du jeu
            else if (coordBallX[nb] + BALL_DIAMETER + vectBallX[nb] > MAX_WIDTH) {
                coordBallX[nb] = MAX_WIDTH - BALL_DIAMETER;
                vectBallX[nb] = -vectBallX[nb];
                coordBallY[nb] = coordBallY[nb] + vectBallY[nb];
                this.setState({
                    coordBallY,
                    coordBallX,
                    vectBallX
                });
            }
            //on gére la collision entre la balle et la gauche de cadre du jeu
            else if (coordBallX[nb] + vectBallX[nb] <= 0) {
                coordBallX[nb] = 0;
                coordBallY[nb] = coordBallY[nb] + vectBallY[nb];
                vectBallX[nb] = -vectBallX[nb];
                this.setState({
                    coordBallY,
                    coordBallX,
                    vectBallX
                });
            }
            //trajectoire normal de la balle
            else {
                coordBallX[nb] = coordBallX[nb] + vectBallX[nb];
                coordBallY[nb] = coordBallY[nb] + vectBallY[nb];
                this.setState({
                    coordBallX,
                    coordBallY
                });
            }
        }
        //si on a touché une brique alors la trajectoire de la balle a déjà été défini
        //donc il suffit de supprimer la brique qui a été touché
        else {
            //ON regarde si il reste des briques
            let { brick } = this.state;
            if (brick.length === 0) {
                /*  this.mainMusic.pause();
                delete (this.mainMusic);
                this.mainMusic=null; */
                /* this.fanfare=new Audio(victory);
                this.fanfare.loop=true;
                this.fanfare.play(); */
                //si il n'y a plus de briques alors on fait un nouveau niveaus
                this.setState({
                    animation: true,
                    brick: [],
                    showScore: true
                });
                clearInterval(this.move);
                if (this.falling) {
                    clearInterval(this.falling);
                    this.falling = null;
                }
            } 
            else {
                this.setState({ brick: brick });
            }
        }
    };

    //permet de gérer la collision entre la balle et la raquette
    //nb : numéro de la balle à controller
    checkCollision = nb => {
        const { coordJX, coordBallX, coordBallY, vectBallX, vectBallY } = this.state;
        //si la balle n'arrive pas à la moitié de la raquette alors
        if (coordBallY[nb] < PAD_COORDY - PAD_HEIGHT + BALL_DIAMETER) {
            //alors on regarde si il y a collision
            if (coordBallX[nb] + BALL_DIAMETER >= coordJX && coordBallX[nb] <= coordJX + PAD_WIDTH + this.state.plusHeight) {
                if (this.state.viseur === 0 || this.state.throw !== null) {
                    const dist = 12;
                    //collision sur le bord gauche de la raquette
                    if (coordBallX[nb] + BALL_DIAMETER <= coordJX + 25) {
                        //on envoie la balle à un angle aléatoire situé entre -40 et -70 deg
                        let angle = -(Math.random() * (30) + 40);
                        angle = this.degToRad(angle);
                        coordBallX[nb] = coordBallX[nb] + Math.sin(angle) * dist;
                        coordBallY[nb] = coordBallY[nb] - Math.cos(angle) * dist;
                        vectBallY[nb] = -Math.cos(angle) * dist;
                        vectBallX[nb] = Math.sin(angle) * dist;
                        this.setState({
                            coordBallX,
                            coordBallY,
                            vectBallY,
                            vectBallX,
                            deg: angle
                        });
                    }
                    //collision sur le bord droit de la raquette
                    else if (coordBallX[nb] >= coordJX + 85 + this.state.plusHeight) {
                        //on envoie la balle à un angle aléatoire situé entre 40 et 70 deg
                        let angle = Math.random() * (30) + 40;
                        angle = this.degToRad(angle);
                        coordBallX[nb] = coordBallX[nb] + Math.sin(angle) * dist;
                        coordBallY[nb] = coordBallY[nb] - Math.cos(angle) * dist;
                        vectBallY[nb] = -Math.cos(angle) * dist;
                        vectBallX[nb] = Math.sin(angle) * dist;
                        this.setState({
                            coordBallX,
                            coordBallY,
                            vectBallY,
                            vectBallX,
                            deg: angle
                        });
                    }
                    //sinon on envoie la balle va être envoyé selon l'angle d'incidence
                    else {
                        coordBallX[nb] = coordBallX[nb] + vectBallX[nb];
                        const offset=1
                        coordBallY[nb] = PAD_COORDY - BALL_DIAMETER - offset;
                        vectBallY[nb] = -vectBallY[nb];
                        this.setState({
                            coordBallX,
                            coordBallY,
                            vectBallY
                        });
                    }
                }
                //si on peut viser à la prochaine collision
                else {
                    const { plusHeight } = this.state;
                    //on stoppe le mouvement de la balle
                    if (coordBallX[nb] > coordJX + 90 + plusHeight) {
                        //clearInterval(this.move);
                        coordBallY[nb] = PAD_COORDY - BALL_DIAMETER;
                        coordBallX[nb] = coordJX + 90;
                        vectBallX[nb] = 0;
                        vectBallY[nb] = 0;
                        this.setState({
                            throw: nb,
                            coordBallY,
                            coordBallX,
                            vectBallX,
                            vectBallY,
                            deg: 0,
                            heightViseur: HEIGHT_BASE_VISEUR,
                            viseurY: coordBallY[nb] + 10 - HEIGHT_BASE_VISEUR,
                            viseurX: coordBallX[nb] + 10
                        });
                    } 
                    else if (coordBallX[nb] < coordJX - 10) {
                        //clearInterval(this.move);
                        coordBallY[nb] = PAD_COORDY - BALL_DIAMETER;
                        coordBallX[nb] = coordJX - 10;
                        vectBallX[nb] = 0;
                        vectBallY[nb] = 0;
                        this.setState({
                            throw: nb,
                            coordBallY,
                            coordBallX,
                            vectBallX,
                            vectBallY,
                            deg: 0,
                            heightViseur: HEIGHT_BASE_VISEUR,
                            viseurY: coordBallY[nb] + 10 - HEIGHT_BASE_VISEUR,
                            viseurX: coordBallX[nb] + 10
                        });
                    } 
                    else {
                        //clearInterval(this.move);
                        coordBallY[nb] = PAD_COORDY - BALL_DIAMETER;
                        vectBallX[nb] = 0;
                        vectBallY[nb] = 0;
                        this.setState({
                            throw: nb,
                            coordBallY,
                            vectBallX,
                            vectBallY,
                            deg: 0,
                            heightViseur: HEIGHT_BASE_VISEUR,
                            viseurY: coordBallY[nb] + 10 - HEIGHT_BASE_VISEUR,
                            viseurX: coordBallX[nb] + 10
                        });
                    }
                }
            }
            //sinon on continue la trajectoire
            else {
                if (coordBallX[nb] + vectBallX[nb] + BALL_DIAMETER > MAX_WIDTH) {
                    coordBallX[nb] = MAX_WIDTH - BALL_DIAMETER;
                    coordBallY[nb] = coordBallY[nb] + vectBallY[nb];
                    vectBallX[nb] = -vectBallX[nb];
                    this.setState({
                        coordBallX,
                        coordBallY,
                        vectBallX
                    });
                } 
                else if (coordBallX[nb] + vectBallX[nb] < 0) {
                    coordBallX[nb] = 0;
                    coordBallY[nb] = coordBallY[nb] + vectBallY[nb];
                    vectBallX[nb] = -vectBallX[nb];
                    this.setState({
                        coordBallX,
                        coordBallY,
                        vectBallX
                    });
                } 
                else {
                    coordBallX[nb] = coordBallX[nb] + vectBallX[nb];
                    coordBallY[nb] = coordBallY[nb] + vectBallY[nb];
                    this.setState({
                        coordBallX,
                        coordBallY
                    });
                }
            }
        }
        //si la balle est trop basse mais qu'elle peut toucher la raquette
        //alors on detecte la collision (cependant la balle continue sa trajectoire vers le bas)
        else if (coordBallY[nb] >= PAD_COORDY - PAD_HEIGHT + BALL_DIAMETER 
                && coordBallY[nb] + vectBallY[nb] < PAD_COORDY + PAD_HEIGHT) {
            //si il y a une collision entre le joueur et la balle
            //on detecte la collision et on fait tomber la balle
            if (coordBallX[nb] + BALL_DIAMETER >= coordJX && 
                coordBallX[nb] <= coordJX + PAD_WIDTH + this.state.plusHeight && 
                this.state.lose === false) {
                coordBallX[nb] = coordBallX[nb] + vectBallX[nb];
                coordBallY[nb] = coordBallY[nb] + vectBallY[nb];
                vectBallX[nb] = -vectBallX[nb];
                this.setState({
                    coordBallX,
                    coordBallY,
                    vectBallX,
                    lose: true
                });
            }
            //sinon la balle continue sa trajectoire
            else {
                //si la balle est en bordure on arrange sa trajectoire
                if (coordBallX[nb] + vectBallX[nb] + BALL_DIAMETER > MAX_WIDTH) {
                    coordBallX[nb] = MAX_WIDTH - BALL_DIAMETER;
                    coordBallY[nb] = coordBallY[nb] + vectBallY[nb];
                    vectBallX[nb] = -vectBallX[nb];
                    this.setState({
                        coordBallX,
                        coordBallY,
                        vectBallX
                    });
                } 
                else if (coordBallX[nb] + vectBallX[nb] < 0) {
                    coordBallX[nb] = 0;
                    coordBallY[nb] = coordBallY[nb] + vectBallY[nb];
                    vectBallX[nb] = -vectBallX[nb];
                    this.setState({
                        coordBallX,
                        coordBallY,
                        vectBallX
                    });
                }
                //sinon on fait rien
                else {
                    coordBallX[nb] = coordBallX[nb] + vectBallX[nb];
                    coordBallY[nb] = coordBallY[nb] + vectBallY[nb];
                    this.setState({
                        coordBallX,
                        coordBallY
                    });
                }
            }
        }
        //si la balle est trop basse
        //on attends qu'elle touche le bord du terrain
        //si elle dépasse le bord, on la ramène au dernier pixel du terrain
        else if (coordBallY[nb] + vectBallY[nb] > PAD_COORDY + PAD_HEIGHT 
            && coordBallY[nb] + vectBallY[nb] < MAX_HEIGHT - BALL_DIAMETER) {
            if (coordBallX[nb] + vectBallX[nb] + BALL_DIAMETER > MAX_WIDTH) {
                coordBallX[nb] = MAX_WIDTH - BALL_DIAMETER;
                coordBallY[nb] = coordBallY[nb] + vectBallY[nb];
                vectBallX[nb] = -vectBallX[nb];
                this.setState({
                    coordBallX,
                    coordBallY,
                    vectBallX
                });
            } 
            else if (coordBallX[nb] + vectBallX[nb] < 0) {
                coordBallX[nb] = 0;
                coordBallY[nb] = coordBallY[nb] + vectBallY[nb];
                vectBallX[nb] = -vectBallX[nb];
                this.setState({
                    coordBallX,
                    coordBallY,
                    vectBallX
                });
            } 
            else {
                coordBallX[nb] = coordBallX[nb] + vectBallX[nb];
                coordBallY[nb] = coordBallY[nb] + vectBallY[nb];
                this.setState({
                    coordBallX,
                    coordBallY
                });
            }
        }
        //on détruit la balle et on regarde si le joueur perd une vie
        else if (coordBallY[nb] === MAX_HEIGHT - BALL_DIAMETER) {
            //si il reste qu'une seule balle le joueur perd une vie
            if (this.state.nbBall === 1) {
                //on stoppe les différent fonction qui sont appelés grâce à setInterval
                const { life } = this.state;
                //si il a plus de vie alors game over
                if (life <= 1) {
                    clearInterval(this.destroy);
                    clearInterval(this.move);
                    clearInterval(this.falling);
                    clearInterval(this.takeDamage);
                    this.falling = null;
                    this.takeDamage = null;
                    this.move = null;
                    this.destroy = null;
                    this.setState(prevState => ({
                        life: prevState.life - 1,
                        defeat: true,
                        lose: true,
                        bonus: [],
                        plusHeight: 0,
                        notCollide: false,
                        viseur: 0,
                        degat: 1,
                        timer: null,
                        initialTime: null,
                        lvl: 1,
                        vectBallX: [0],
                        vectBallY: [0],
                        animation: true,
                    }));
                    /* if(this.mainMusic)
                    {
                        this.mainMusic.pause();
                        delete (this.mainMusic);
                        this.mainMusic=null;
                    } */
                }
                //sinon on lui retire une vie
                else {
                    clearInterval(this.move);
                    clearInterval(this.falling);
                    clearInterval(this.takeDamage);
                    this.falling = null;
                    this.takeDamage = null;
                    this.setState(prevState => ({
                        life: prevState.life - 1,
                        coordJX: BASE_PAD_COORDX,
                        coordBallY: [BASE_BALL_COORDY],
                        coordBallX: [BASE_BALL_COORDX],
                        throw: 0,
                        rotation: "rotate(0deg)",
                        viseurX: BASE_VISEURX,
                        deg: 0,
                        viseurY: BASE_VISEURY,
                        bonus: [],
                        heightViseur: HEIGHT_BASE_VISEUR,
                        plusHeight: 0,
                        viseur: 0,
                        degat: 1,
                        notCollide: false,
                        timer: null,
                        initialTime: null,
                        vectBallX: [0],
                        vectBallY: [0],
                        animation: false,
                        damage: false
                    }));
                }
            }
            //si il reste d'autre balle alors le jeu continue
            else {
                if (this.state.throw !== null) {
                    const coorBallThrowX = coordBallX[this.state.throw];
                    const coorBallThrowY = coordBallY[this.state.throw];
                    coordBallX.splice(nb, 1);
                    coordBallY.splice(nb, 1);
                    vectBallX.splice(nb, 1);
                    vectBallY.splice(nb, 1);
                    let newThrow;
                    for (let i = 0; i < this.state.nbBall - 1; i++) {
                        if (coordBallX[i] === coorBallThrowX && coordBallY[i] === coorBallThrowY) {
                            newThrow = i;
                        }
                    }
                    this.setState(prevState => ({
                        coordBallX,
                        coordBallY,
                        vectBallX,
                        vectBallY,
                        nbBall: prevState.nbBall - 1,
                        throw: newThrow
                    }));
                } 
                else {
                    coordBallX.splice(nb, 1);
                    coordBallY.splice(nb, 1);
                    vectBallX.splice(nb, 1);
                    vectBallY.splice(nb, 1);
                    this.setState(prevState => ({
                        coordBallX,
                        coordBallY,
                        vectBallX,
                        vectBallY,
                        nbBall: prevState.nbBall - 1
                    }));
                }
            }
        }
        //si la balle touche le bord alors on retire une vie au joueur
        else if (coordBallY[nb] + vectBallY[nb] > MAX_HEIGHT - BALL_DIAMETER) {
            if (coordBallX[nb] + vectBallX[nb] + BALL_DIAMETER > MAX_WIDTH) {
                coordBallX[nb] = MAX_WIDTH - BALL_DIAMETER;
                coordBallY[nb] = MAX_HEIGHT - BALL_DIAMETER;
                this.setState({
                    coordBallX,
                    coordBallY
                });
            } 
            else if (coordBallX[nb] + vectBallX[nb] < 0) {
                coordBallX[nb] = 0;
                coordBallY[nb] = MAX_HEIGHT - BALL_DIAMETER;
                this.setState({
                    coordBallX,
                    coordBallY
                });
            } 
            else {
                coordBallX[nb] = coordBallX[nb] + vectBallX[nb];
                coordBallY[nb] = MAX_HEIGHT - BALL_DIAMETER;
                this.setState({
                    coordBallX,
                    coordBallY
                });
            }
        }
    };
    
    //génere les briques
    generate = () => {
        let { brick } = this.state;
        const { lvl } = this.state;
        brick = [];
        //nombre de bonus disponbile lorsqu'on génére le niveau
        let numberOfCollide = 3 - Math.floor(lvl/2);
        let numberOfMulti = 3 - Math.floor(lvl/2);
        let numberOfViseur = 2 - Math.floor(lvl/2);
        let numberOfLife = 2 - Math.floor(lvl/2);
        let numberOfLarge = 3 - Math.floor(lvl/2);
        const brickBeginX = 200;
        const brickIntervalX = 60;
        const brickBeginY = 70;
        const brickIntervalY = 40;
        for (let i = 0; i < 5; i++) {
            for (let j = 0; j < 10; j++) {
                //les premières brique ne donnent pas de bonus
                if (i === 0 || i === 4 || j === 0 || j === 9) {
                    //si hit vaut 1 alors on peut toucher la brique, sinon c'est pas possible
                    brick.push({ 
                        coordX: brickBeginX + j * brickIntervalX, 
                        coordY: brickBeginY + i * brickIntervalY, 
                        HP: 1, 
                        hit: 1 
                    });
                } 
                else {
                    //on génére un nombre aléatoire 
                    const num = Math.floor(Math.random() * (10 - 1 + 1)) + 1;
                    //lorsqu'on génére un 2, on donne le bonus viseur à la brique
                    if (num === 2 && numberOfViseur > 0) {
                        brick.push({ 
                            coordX: brickBeginX + j * brickIntervalX, 
                            coordY: brickBeginY + i * brickIntervalY, 
                            HP: 2, 
                            bonus: "viseur", 
                            hit: 2 
                        });
                        numberOfViseur--;
                    } 
                    //lorsqu'on génére un 5, on donne le bonus vie supplémentaire à la brique
                    else if (num === 5 && numberOfLife > 0) {
                        brick.push({ 
                            coordX: brickBeginX + j * brickIntervalX, 
                            coordY: brickBeginY + i * brickIntervalY, 
                            HP: 2, 
                            bonus: "addLife", 
                            hit: 2 
                        });
                        numberOfLife--;
                    }
                    //lorsqu'on génére un 7, on donne le bonus multi ball à la brique
                    else if (num === 7 && numberOfMulti > 0) {
                        brick.push({ 
                            coordX: brickBeginX + j * brickIntervalX, 
                            coordY: brickBeginY + i * brickIntervalY, 
                            HP: 3, 
                            bonus: "multi", 
                            hit: 2 
                        });
                        numberOfMulti--;
                    } 
                    //lorsqu'on génére un 10, on donne le bonus balle noire à la brique 
                    //(la balle détruit et il n'y a plus de collision entre les brique)
                    else if (num === 10 && numberOfCollide > 0) {
                        brick.push({ 
                            coordX: brickBeginX + j * brickIntervalX, 
                            coordY: brickBeginY + i * brickIntervalY, 
                            HP: 3, 
                            bonus: "brickDestroyer",
                            hit: 2 
                        });
                        numberOfCollide--;
                    } 
                    //lorsqu'on génére un 9, on donne le bonus qui agrandit la raquette à la brique
                    else if (num === 9 && numberOfLarge > 0) {
                        brick.push({ 
                            coordX: brickBeginX + j * brickIntervalX, 
                            coordY: brickBeginY + i * brickIntervalY, 
                            HP: 2, 
                            bonus: "large", 
                            hit: 2 
                        });
                        numberOfLarge--;
                    } 
                    //sinon la brique n'a pas de bonus
                    else {
                        brick.push({ 
                            coordX: brickBeginX + j * brickIntervalX, 
                            coordY: brickBeginY + i * brickIntervalY, 
                            HP: 1, 
                            hit: 2 
                        });
                    }
                }
            }
        }
        this.setState({ brick });
    };

    //fonction qui démarre le combat de boss
    startBoss = () => {
        this.setState({ bossFigth: true });
    };

    //démarrre le programme
    start = (background, bossSprite) => {
        //si ça premier partie, on active les event listener
        /* this.mainMusic=new Audio(music);
        this.mainMusic.loop=true;
        this.mainMusic.play(); */
        if (this.state.first) {
            document.addEventListener("mousemove", event => this.onMouseMove(event));
            document.addEventListener("mousemove", event => this.throw(event));
        }
        this.setState({
            play: true,
            background: background,
            bossSprite: bossSprite,
            coordBallY: [BASE_BALL_COORDY],
            coordBallX: [BASE_BALL_COORDX],
            vectBallX: [0],
            vectBallY: [0],
            coordJX: BASE_PAD_COORDX,
            life: 3,
            score: 0,
            defeat: false,
            lose: false,
            win: false,
            throw: 0,
            viseurX: BASE_VISEURX,
            viseurY: BASE_VISEURY,
            deg: 0,
            heightViseur: HEIGHT_BASE_VISEUR,
            nbBall: 1,
            lvl:1
        });
        this.generate();
    };

    //transforme radian en degré
    radToDeg = rad => {
        return (rad * 180) / Math.PI;
    };

    //transforme un degré en radian
    degToRad = deg => {
        return (deg * Math.PI) / 180;
    };


    //affiche le viseur en fonction de la position de la souris  
    throw = event => {
        //si on doit lancer la balle alors
        if (this.state.throw !== null) {
            //on recupere la position de la souris
            const element = document.getElementsByClassName("board");
            if (element) {
                const bounds = element[0].getBoundingClientRect();
                const coordBallX = this.state.coordBallX[this.state.throw];
                const coordBallY = this.state.coordBallY[this.state.throw];
                const flecheX = event.clientX - bounds.left;
                const flecheY = event.clientY - bounds.top;
                //on fait des math pour savoir la taille du viseur,l'angle du viseur et sa position dans le cadre de jeu
                let dist = Math.sqrt(Math.pow(coordBallX + 10 - flecheX, 2) + Math.pow(coordBallY + 10 - flecheY, 2));
                let alpha = this.radToDeg(Math.asin(Math.abs(coordBallX + 10 - flecheX) / dist));
                if (flecheX < coordBallX + 10) {
                    alpha = -alpha;
                }
                if (Math.abs(alpha) > 80 || flecheY > BASE_BALL_COORDY) {
                    alpha > 0 ? (alpha = 80) : (alpha = -80);
                }
                this.setState({
                    flecheX,
                    flecheY,
                    deg: alpha
                });
                if (dist < 100) {
                    if (flecheX <= 0) {
                        dist = -(coordBallX + 10) / Math.sin(this.degToRad(alpha));
                        this.setState({
                            viseurX: coordBallX + 10 + (Math.sin(this.degToRad(alpha)) * dist) / 2,
                            viseurY: coordBallY + 10 - dist / 2 - (Math.cos(this.degToRad(alpha)) * dist) / 2,
                            heightViseur: dist
                        });
                    } 
                    else if (flecheX >= MAX_WIDTH) {
                        dist = (MAX_WIDTH - (coordBallX + 10)) / Math.sin(this.degToRad(alpha));
                        this.setState({
                            viseurX: coordBallX + 10 + (Math.sin(this.degToRad(alpha)) * dist) / 2,
                            viseurY: coordBallY + 10 - dist / 2 - (Math.cos(this.degToRad(alpha)) * dist) / 2,
                            heightViseur: dist
                        });
                    } 
                    else {
                        this.setState({
                            viseurX: coordBallX + 10 + (Math.sin(this.degToRad(alpha)) * dist) / 2,
                            viseurY: coordBallY + 10 - dist / 2 - (Math.cos(this.degToRad(alpha)) * dist) / 2,
                            heightViseur: dist
                        });
                    }
                } 
                else if (dist >= 100 && dist < 140) {
                    if (flecheX <= 0) {
                        dist = -(coordBallX + 10) / Math.sin(this.degToRad(alpha));
                        this.setState({
                            viseurX: coordBallX + 10 + (Math.sin(this.degToRad(alpha)) * dist) / 2,
                            viseurY: coordBallY + 10 - dist / 2 - (Math.cos(this.degToRad(alpha)) * dist) / 2,
                            heightViseur: dist
                        });
                    } 
                    else if (flecheX >= MAX_WIDTH) {
                        dist = (MAX_WIDTH - (coordBallX + 10)) / Math.sin(this.degToRad(alpha));
                        this.setState({
                            viseurX: coordBallX + 10 + (Math.sin(this.degToRad(alpha)) * dist) / 2,
                            viseurY: coordBallY + 10 - dist / 2 - (Math.cos(this.degToRad(alpha)) * dist) / 2,
                            heightViseur: dist
                        });
                    } 
                    else {
                        this.setState({
                            viseurX: coordBallX + 10 + Math.sin(this.degToRad(alpha)) * 50,
                            viseurY: coordBallY + 10 - 50 - Math.cos(this.degToRad(alpha)) * 50,
                            heightViseur: 100
                        });
                    }
                } 
                else if (dist < 340 && dist >= 140) {
                    //distance de séparation entre la souris et le viseur
                    const distOffset = 40;
                    const viseurCoordX = coordBallX + 10 + (Math.sin(this.degToRad(alpha)) * (dist - distOffset)) / 2 + 
                    (Math.sin(this.degToRad(alpha)) * (dist - distOffset)) / 2; 
                    if (viseurCoordX<= 0) {
                        dist = distOffset - (coordBallX + 10) / Math.sin(this.degToRad(alpha));
                        this.setState({
                            viseurX: coordBallX + 10 + (Math.sin(this.degToRad(alpha)) * (dist - distOffset)) / 2,
                            viseurY: coordBallY + 10 - (dist - distOffset) / 2 - (Math.cos(this.degToRad(alpha)) * (dist - distOffset)) / 2,
                            heightViseur: dist - distOffset
                        });
                    } 
                    else if (viseurCoordX >= MAX_WIDTH) {
                        dist = distOffset - (-MAX_WIDTH + (coordBallX + 10)) / Math.sin(this.degToRad(alpha));
                        this.setState({
                            viseurX: coordBallX + 10 + (Math.sin(this.degToRad(alpha)) * (dist - distOffset)) / 2,
                            viseurY: coordBallY + 10 - (dist - distOffset) / 2 - (Math.cos(this.degToRad(alpha)) * (dist - distOffset)) / 2,
                            heightViseur: dist - distOffset
                        });
                    } 
                    else {
                        this.setState({
                            viseurX: coordBallX + 10 + (Math.sin(this.degToRad(alpha)) * (dist - distOffset)) / 2,
                            viseurY: coordBallY + 10 - (dist - distOffset) / 2 - (Math.cos(this.degToRad(alpha)) * (dist - distOffset)) / 2,
                            heightViseur: dist - distOffset
                        });
                    }
                } 
                else {
                    dist = 300;
                    const distOffset = 40;
                    const viseurCoordX = coordBallX + 10 + (Math.sin(this.degToRad(alpha)) * 300) / 2 + 
                    (Math.sin(this.degToRad(alpha)) * 300) / 2
                    if (viseurCoordX <= 0) {
                        dist = distOffset - (coordBallX + 10) / Math.sin(this.degToRad(alpha));
                        this.setState({
                            viseurX: coordBallX + 10 + (Math.sin(this.degToRad(alpha)) * (dist - distOffset)) / 2,
                            viseurY: coordBallY + 10 - (dist - distOffset) / 2 - (Math.cos(this.degToRad(alpha)) * (dist - distOffset)) / 2,
                            heightViseur: dist - distOffset
                        });
                    } 
                    else if (viseurCoordX >= MAX_WIDTH) {
                        dist = distOffset - (-MAX_WIDTH + (coordBallX + 10)) / Math.sin(this.degToRad(alpha));
                        this.setState({
                            viseurX: coordBallX + 10 + (Math.sin(this.degToRad(alpha)) * (dist - distOffset)) / 2,
                            viseurY: coordBallY + 10 - (dist - distOffset) / 2 - (Math.cos(this.degToRad(alpha)) * (dist - distOffset)) / 2,
                            heightViseur: dist - distOffset
                        });
                    } 
                    else {
                        this.setState({
                            viseurX: coordBallX + 10 + Math.sin(this.degToRad(alpha)) * (dist/2),
                            viseurY: coordBallY + 10 - (dist/2) - Math.cos(this.degToRad(alpha)) * (dist/2),
                            heightViseur: 300
                        });
                    }
                }
            }
        }
    };

    //fonction qui permet de lancer la balle en fonction de l'angle du viseur
    launch = () => {
        if (this.state.throw !== null && this.state.deg !== null && this.quit === false) {
            const { vectBallX, vectBallY } = this.state;
            const alpha = this.degToRad(this.state.deg);
            const dist = 12;
            if (this.state.viseur > 0) {
                vectBallX[this.state.throw] = Math.sin(alpha) * dist;
                vectBallY[this.state.throw] = -Math.cos(alpha) * dist;
                this.setState(prevState => ({
                    vectBallX,
                    vectBallY,
                    throw: null,
                    viseur: prevState.viseur - 1
                }));
            } 
            else {
                this.setState({
                    vectBallX: [Math.sin(alpha) * dist],
                    vectBallY: [-Math.cos(alpha) * dist],
                    throw: null
                });
                const timeInterval = 10 + 10 / this.state.lvl
                this.move = setInterval(this.goAllBall, timeInterval);
            }
            if (this.state.first === true) {
                this.setState({ first: false });
            }
        }
        if (this.quit === true) {
            this.quit = false;
        }
    };

    //fonction appelé lorsque la balle touche le boss
    changeDirection = nb => {
        const { vectBallX, vectBallY } = this.state;
        vectBallX[nb] = -vectBallX[nb];
        vectBallY[nb] = -vectBallY[nb];
        this.setState({ vectBallX, vectBallY});
    };

    //collision entre la raquette et les boules de feu
    collisionBossAttack = coordX => {
        const { coordJX } = this.state;
        //taille des boule d efue envoyer par le boss
        const fireWidth = 25;
        if (coordJX <= coordX + fireWidth && coordJX + PAD_WIDTH >= coordX && this.state.throw === null) {
            this.setState({ animation: true });
            setTimeout(() => {
                this.setState({ animation: false, damage: false });
                clearInterval(this.takeDamage);
                this.takeDamage = null;
            }, 750);
            let i = 0;
            if (this.takeDamage === null) {
                this.takeDamage = setInterval(() => {
                    if (i % 2 === 0) {
                        this.setState({ damage: true });
                    }
                    else {
                        this.setState({ damage: false });
                    }
                    i++;
                }, 150);
            }
        }
    };

    //fonction qui fait tomber les bonus pendant le combat de boss
    dropBonus = (posX, posY) => {
        const { bonus } = this.state;
        const num = Math.floor(Math.random() * 3);

        switch (num) {
            case 0:
                bonus.push({ coordX: posX + 100, coordY: posY, type: "addLife" });
                break;
            case 1:
                bonus.push({ coordX: posX + 100, coordY: posY, type: "viseur" });
                break;
            case 2:
                bonus.push({ coordX: posX + 100, coordY: posY, type: "multi" });
                break;
            default:
        }
        if (bonus.length === 1 && this.falling === null) {
            const timeInterval = 10 + 10 / this.state.lvl;
            this.falling = setInterval(this.fall, timeInterval);
        }
        this.setState({ bonus });
    };

    //fonction qui stoppe la balle après la fin d'un niveau
    stop = () => {
        //on arrête les différents intervalles
        const { vectBallX, vectBallY } = this.state;
        for (let i = 0; i < vectBallX.length; i++) {
            vectBallX[i] = 0;
            vectBallY[i] = 0;
        }
        clearInterval(this.move);
        if (this.falling) {
            clearInterval(this.falling);
        }
        this.falling = null;

        this.setState({ animation: true, vectBallX, vectBallY, bonus: [] });
    };

    //fonction qui fait disparaitre le boss
    bossDispear = () => {
        this.setState(prevState => ({
            showScore: true,
            bossFigth: false,
            bossBeat: true,
            score: prevState.score + 2500
        }));
        /* this.fanfare=new Audio(victory);
        this.fanfare.loop=true;
        this.fanfare.play(); */
        //setTimeout(()=>{this.setState({})},)
    };

    //permet de retourner au menu après avoir fini un niveau
    returnMenu = () => {
        clearInterval(this.destroy);
        clearInterval(this.move);
        clearInterval(this.falling);
        clearInterval(this.takeDamage);
        this.falling = null;
        this.takeDamage = null;
        this.move = null;
        this.destroy = null;
        this.quit = true;
        this.setState({
            play: false,
            score: 0,
            bonus: [],
            plusHeight: 0,
            notCollide: false,
            viseur: 0,
            degat: 1,
            timer: null,
            initialTime: null,
            lvl: 1,
            vectBallX: [0],
            vectBallY: [0],
            animation: false,
            damage: false,
            bossBeat: false,
            showScore: false,
            bossFigth: false,
            throw: null
        });
        /* if(this.mainMusic)
        {
            this.mainMusic.pause();
            delete (this.mainMusic);
            this.mainMusic=null;
        } */
    };

    //permet de passer au niveau suivant
    createNewLevel = () => {
        /* this.fanfare.pause();
        delete(this.fanfare); */
        //on combat le boss tout les 5 niveaux
        if (this.state.lvl % 5 === 1 && this.state.bossBeat === false) {
            this.setState({
                bonus: [],
                coordBallY: [BASE_BALL_COORDY],
                coordBallX: [BASE_BALL_COORDX],
                throw: 0,
                timer: null,
                initialTime: null,
                notCollide: false,
                viseurX: BASE_VISEURX,
                viseurY: BASE_VISEURY,
                deg: 0,
                viseur: 0,
                nbBall: 1,
                heightViseur: HEIGHT_BASE_VISEUR,
                bossBeat: false,
                showScore: false,
                animation: false,
                bossFigth: true,
                coordJX: BASE_PAD_COORDX
            });
        }
        //sinon on fait un niveau "classique"
        else if (this.state.bossBeat === true) {
            this.setState(prevState => ({
                lvl: prevState.lvl + 1,
                bonus: [],
                coordBallY: [BASE_BALL_COORDY],
                coordBallX: [BASE_BALL_COORDX],
                throw: 0,
                timer: null,
                initialTime: null,
                notCollide: false,
                viseurX: BASE_VISEURX,
                viseurY: BASE_VISEURY,
                deg: 0,
                viseur: 0,
                nbBall: 1,
                heightViseur: HEIGHT_BASE_VISEUR,
                bossBeat: false,
                showScore: false,
                bossEncounter: prevState.bossEncounter + 1,
                animation: false,
                coordJX: BASE_PAD_COORDX
            }));
            this.generate();
            /* this.mainMusic=new Audio(music);
            this.mainMusic.loop=true;
            this.mainMusic.play(); */
        } 
        else {
            this.setState(prevState => ({
                lvl: prevState.lvl + 1,
                bonus: [],
                coordBallY: [BASE_BALL_COORDY],
                coordBallX: [BASE_BALL_COORDX],
                throw: 0,
                timer: null,
                initialTime: null,
                notCollide: false,
                viseurX: BASE_VISEURX,
                viseurY: BASE_VISEURY,
                deg: 0,
                viseur: 0,
                nbBall: 1,
                heightViseur: HEIGHT_BASE_VISEUR,
                bossBeat: false,
                showScore: false,
                animation: false,
                coordJX: BASE_PAD_COORDX
            }));
            this.generate();
            /* this.mainMusic=new Audio(music);
            this.mainMusic.loop=true;
            this.mainMusic.play(); */
        }
        clearInterval(this.move);
        if (this.falling) {
            clearInterval(this.falling);
            this.falling = null;
        }
    };
    
    //fonction qui permet de changer le fond d'écran du jeu
    selectBackground = () => {
        const { background } = this.state;
        switch (background) {
            case 1:
                return sky;
            case 2:
                return prairie;
            case 3:
                return code;
            default:
        }
    };
    
    //méthodes du render
    render() {
        const background = this.selectBackground();
        const { viseurX, viseurY } = this.state;
        const tab = [];
        for (let i = 0; i < this.state.nbBall; i++) {
            tab.push(i);
        }
        if (this.state.play === true) {
            return (
                <div className='allPage'>
                    <div className='board' onClick={this.launch}>
                        <div
                            style={{
                                backgroundImage: "url(" + background + ")",
                                backgroundSize: "100%",
                                width: "100%",
                                height: "100%"
                            }}
                        >
                            {this.state.throw !== null && (
                                <div
                                    className='viseur'
                                    style={{
                                        position: "absolute",
                                        top: viseurY,
                                        left: viseurX,
                                        transform: "rotate(" + this.state.deg + "deg)",
                                        height: this.state.heightViseur
                                    }}
                                />
                            )}
                            {this.state.bossFigth === true && (
                                <Boss
                                    nbBall={this.state.nbBall}
                                    coordBallX={this.state.coordBallX}
                                    coordBallY={this.state.coordBallY}
                                    vectBallX={this.state.vectBallX}
                                    vectBallY={this.state.vectBallY}
                                    changeDirection={this.changeDirection}
                                    hitPad={this.collisionBossAttack}
                                    dropBonus={this.dropBonus}
                                    stopBall={this.stop}
                                    showScore={this.bossDispear}
                                    bossSprite={this.state.bossSprite}
                                    lvl={this.state.lvl}
                                    life={this.state.life}
                                ></Boss>
                            )}

                            <Param
                                life={this.state.life}
                                level={this.state.bossFigth ? "Boss" : this.state.lvl}
                                timer={this.state.timer}
                                viseur={this.state.viseur}
                                score={this.state.score}
                                returnMenu={this.returnMenu}
                            ></Param>

                            {!this.state.damage && 
                                <Rectangle coord={this.state.coordJX} height={this.state.plusHeight}></Rectangle>}
                            
                            {this.state.defeat &&
                                <BoardingScore
                                    onNextLevel={null}
                                    onQuit={this.returnMenu}
                                    score={this.state.score}
                                    lvl="Game Over"
                                />}
                                
                            {tab.map((el, index) => {
                                //On affiche l'ensemble des balle
                                return (
                                    <Ball
                                        key={index}
                                        coordX={this.state.coordBallX[el]}
                                        coordY={this.state.coordBallY[el]}
                                        bigger={this.state.notCollide === index}
                                    ></Ball>
                                );
                            })}
                            {this.state.showScore && (
                                <BoardingScore
                                    onNextLevel={this.createNewLevel}
                                    onQuit={this.returnMenu}
                                    score={this.state.score}
                                    lvl={this.state.bossBeat !== false ? "BOSS" : this.state.lvl}
                                />
                            )}
                            {this.state.brick.map((el, index) => {
                                return <Brick key={index} coordX={el.coordX} coordY={el.coordY} HP={el.HP}></Brick>;
                            })}

                            {this.state.bonus.map((el, index) => {
                                return <Bonus key={index} coordX={el.coordX} coordY={el.coordY} type={el.type}></Bonus>;
                            })}
                        </div>
                    </div>
                </div>
            );
        } 
        else {
            return (
                <div className='allPage'>
                    <div className='board'>
                        <Menu start={this.start} dontShow={this.props.dontShow}></Menu>
                    </div>
                </div>
            );
        }
    };
}
