import React from 'react';
import { NavHashLink as Link } from 'react-router-hash-link';
import LogoItem from "./logoitem/LogoItem";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRight, faEnvelope, faExternalLinkAlt, faLink } from '@fortawesome/free-solid-svg-icons';

import { AtelierSvg, ParkourSvg } from '../assets/svg';
import LogoTraining from '../img/model-training.svg';

import "../css/project-grid.css";

const Rituels = ({striped}) => (  
  <div id="rituels" className={`section ${striped}`}>
    <div className="content">
      <h1 className="sectiontitle"><span className="red">R</span>ituels</h1>
      <div className="block-content">

        <div className="project-grid project-grid-max-3">

          <div className="project-grid-item center">
            <AtelierSvg />
            <h1>
              L'Atelier
            </h1>
            <p className="center pad-text">
              Nous organisons régulièrement des <b><span className="red">A</span>teliers</b>.
              Il s'agit d'une journée dédiée à la <b>R&D</b>, afin de progresser au
              travers d'un projet personnel et/ou <b>open source</b>.
            </p>
            <Link to='/atelier'>
              <button className="btn btn-center sendbtn">
                Voir les projets de l'Atelier <FontAwesomeIcon icon={faArrowRight} className="iconMargin" />
              </button>
              
            </Link>
          </div>

          <div className="project-grid-item center">
            <LogoItem imgSvg={LogoTraining} />

            <h1>La Manufacture</h1>
            <p className="pad-text">
              La <span className="red">M</span>anuf est une <b>formation interne</b> mensuelle, ciblant des 
              compétences <b>techniques</b> : GPT4, Llama2, AWS ; ou compétences <b>transverses</b> : gestion des conflits, prise de parole en public,
              formalisation d'un cahier des charges, etc.
            </p>
          </div>

          <div className="project-grid-item center">
            <ParkourSvg />
            <h1>Le <span className="red">P</span>arkour</h1>
            <p className="pad-text">
              Nous pensons qu'un Artisan doit être <b>polyvalent</b>.
              Le Parkour est une mise à niveau de <b>2 semaines</b> pour nos nouveaux arrivants : frontend, backend,
              IA génératives et administration système.
            </p>
          </div>

        </div>
      </div>
    </div>
  </div>
);

export default Rituels;
