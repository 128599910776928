import React from "react";
import "./Boss.css";
import ClassicSprite from "../../asset/image/boss.png";
//import HitSound from "../../asset/sound/SF-coupoing2.mp3"
import fire from "../../asset/image/feu.png";
import explosion from "../../asset/image/explosion.png";
import matthieu from "../../asset/image/boss2.png";
import "../../asset/css/global.css";

//musique du boss (à définir)
//import musique from "../../asset/sound/undertale-megalovania.mp3"
//les commentaire avec la variable audio sont à décommenter lorsqu'on aura trouvé une musique libre de droit

export default class Boss extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            //position et déplacement du boss
            posY: 153,
            posX: 400,
            vectY: 0.8,
            vectX: 0.3,
            //vie du boss
            HP: 25 + this.props.lvl,
            //attaque du boss
            fire: [],
            //point d'arrivé du boss durant sa trajectoire
            num: null,
            //boléen lorsque le boss se prend des dégats
            damage: false,
            //tableau qui contient les explosion quand le boss est vaincu
            tabExp: []
        };
        //différent intervalle
        this.move = null;
        this.fireMove = null;
        this.explosion = null;
        //tableau des points d'arrivée du boss
        this.listPoint = [];
        //this.audio=new Audio(musique);
    }

    componentWillUnmount() {
        if (this.move) {
            clearInterval(this.move);
        }
        if (this.fireMove) {
            clearInterval(this.fireMove);
        }
        if (this.explosion) {
            clearInterval(this.explosion);
        }
        this.move = null;
        this.fireMove = null;
        this.explosion = null;
        /* this.audio.pause();
        this.audio=null; */
    }

    componentDidMount() {
        /* this.audio=new Audio(musique);
        this.audio.play();
        this.audio.loop=true; */
        //on crée le tableau de coordonnées pour le déplacement du boss
        const baseOffset = 100;
        const interval = 150;
        for (let i = 0; i < 2; i++) {
            for (let j = 0; j < 5; j++) {
                this.listPoint.push({ x: baseOffset + j * interval, y: baseOffset + i * interval });
            }
        }
        let i = 0;
        this.calculVect(null);
        //on crée un intervalle pour lancer les boules de feu
        this.fireMove = setInterval(() => {
            const { fire } = this.state;
            const fireDiameter = 20;
            const padHeight = 16;
            const startCollisionPad = 610;
            const endCollisionPad = 644;
            const endDisplayFire = 695;
            const speedFire = 4;
            fire.forEach((el, index) => {
                if (el.coordY + speedFire <= endDisplayFire) {
                    el.coordY += speedFire;
                    this.setState({ fire });
                    if (el.coordY >= startCollisionPad && el.coordY <= endCollisionPad + padHeight + fireDiameter) {
                        this.props.hitPad(el.coordX);
                    }
                } else {
                    fire.splice(index, 1);
                }
            });
        }, 10 + 10 / this.props.lvl);
        //on crée un intervalle pour déplacer
        this.move = setInterval(() => {
            this.collision();
            if (this.state.damage === false) {
                const { vectX, vectY, posX, posY, num } = this.state;
                if (vectX > 0 && vectY >= 0) {
                    if (this.listPoint[num].x - posX <= 0 && this.listPoint[num].y - posY <= 0) {
                        let newnum = Math.floor(Math.random() * 10);
                        while (newnum === num) {
                            newnum = Math.floor(Math.random() * 10);
                        }
                        this.setState(prevState => ({
                            posY: prevState.posY + 0,
                            posX: prevState.posX + 0,
                            num: newnum
                        }));
                        this.calculVect(newnum);
                    } else {
                        this.setState(prevState => ({
                            posY: prevState.posY + vectY,
                            posX: prevState.posX + vectX
                        }));
                    }
                } else if (vectX <= 0 && vectY >= 0) {
                    if (this.listPoint[num].x - posX >= 0 && this.listPoint[num].y - posY <= 0) {
                        let newnum = Math.floor(Math.random() * 10);
                        while (newnum === num) {
                            newnum = Math.floor(Math.random() * 10);
                        }
                        this.setState(prevState => ({
                            posY: prevState.posY + 0,
                            posX: prevState.posX + 0,
                            num: newnum
                        }));
                        this.calculVect(newnum);
                    } else {
                        this.setState(prevState => ({
                            posY: prevState.posY + vectY,
                            posX: prevState.posX + vectX
                        }));
                    }
                } else if (vectX <= 0 && vectY <= 0) {
                    if (this.listPoint[num].x - posX >= 0 && this.listPoint[num].y - posY >= 0) {
                        let newnum = Math.floor(Math.random() * 10);
                        while (newnum === num) {
                            newnum = Math.floor(Math.random() * 10);
                        }
                        this.setState(prevState => ({
                            posY: prevState.posY,
                            posX: prevState.posX,
                            num: newnum
                        }));
                        this.calculVect(newnum);
                    } else {
                        this.setState(prevState => ({
                            posY: prevState.posY + vectY,
                            posX: prevState.posX + vectX
                        }));
                    }
                } else if (vectX >= 0 && vectY <= 0) {
                    if (this.listPoint[num].x - posX <= 0 && this.listPoint[num].y - posY >= 0) {
                        let newnum = Math.floor(Math.random() * 10);
                        while (newnum === num) {
                            newnum = Math.floor(Math.random() * 10);
                        }
                        this.setState(prevState => ({
                            posY: prevState.posY,
                            posX: prevState.posX,
                            num: newnum
                        }));
                        this.calculVect(newnum);
                    } else {
                        this.setState(prevState => ({
                            posY: prevState.posY + vectY,
                            posX: prevState.posX + vectX
                        }));
                    }
                }
            }
            if (i % 80 === 0) {
                this.throwFireBall();
            }
            i++;
        }, 10 + 10 / this.props.lvl);
    }

    //calcul le vecteur de déplacement du boss
    calculVect = num => {
        const { posX, posY } = this.state;
        if (num === null) {
            num = Math.floor(Math.random() * 10);
        }

        //on calcule la distance du boss et du point d'arrivée
        const dist = Math.sqrt(Math.pow(posX - this.listPoint[num].x, 2) + Math.pow(posY - this.listPoint[num].y, 2));
        let deg = Math.acos(Math.abs(posX - this.listPoint[num].x) / dist);
        //on change l'angle
        if (this.listPoint[num].x - posX <= 0 && this.listPoint[num].y - posY >= 0) {
            deg = Math.PI - deg;
        } else if (this.listPoint[num].x - posX <= 0 && this.listPoint[num].y - posY <= 0) {
            deg += Math.PI;
        } else if (this.listPoint[num].x - posX >= 0 && this.listPoint[num].y - posY <= 0) {
            deg = 2 * Math.PI - deg;
        }
        //On crée le vecteur
        const vectY = 3 * Math.sin(deg);
        const vectX = 3 * Math.cos(deg);
        if (Math.abs(posY - this.listPoint[num].y) <= 2) {
            this.setState({ vectX: vectX, vectY: 0, num, posY: this.listPoint[num].y });
        } else {
            this.setState({ vectX, vectY, num });
        }
    };

    //les fonction de collision fonctionne
    collision = () => {
        for (let i = 0; i < this.props.coordBallX.length; i++) {
            this.collisionOneBall(i);
        }
    };

    //detecte la collision entre la balle et le boss
    //i: numéro de la balle
    collisionOneBall = i => {
        //
        const { coordBallX, coordBallY, vectBallX, vectBallY } = this.props;
        const { posX, posY, HP, damage } = this.state;
        const dist =
            Math.pow(coordBallX[i] + vectBallX[i] + 11.5 - (posX + 100), 2) + Math.pow(coordBallY[i] + vectBallY[i] + 11.5 - (posY + 100), 2);
        if (dist <= 111.5 * 111.5) {
            if (damage === false) {
                this.setState(prevState => ({
                    HP: prevState.HP - 1
                }));

                if (HP - 1 <= 0) {
                    this.destruction();
                } else {
                    if ((25 - HP + 1) % 7 === 3) {
                        this.props.dropBonus(posX, posY);
                    }
                    this.setState(prevState => ({
                        vectX: 0,
                        vectY: 0,
                        damage: true
                    }));

                    setTimeout(() => {
                        this.calculVect(this.state.num);
                        this.setState({ damage: false });
                    }, 100);
                    this.props.changeDirection(i);
                }
            }
        }
    };

    //ajoute une boule de feu
    throwFireBall = () => {
        const { fire, posX, posY } = this.state;
        //fire.push({coordX:posX,coordY:posY+200});
        fire.push({ coordX: posX + 100, coordY: posY + 200 });
    };

    //animation de défaite du boss
    destruction = () => {
        this.stop();
        this.setState({ destruction: true, fire: [] });
        let i = 0;
        this.props.stopBall();
        this.explosion = setInterval(() => {
            const { tabExp } = this.state;
            if (i < 20) {
                const random1 = Math.floor(Math.random() * 160);
                const random2 = Math.floor(Math.random() * 150);
                tabExp.push({ x: random1, y: random2 });
                if (i >= 4) {
                    tabExp.splice(0, 1);
                }
                this.setState({ tabExp });
            }
            if (i >= 20) {
                tabExp.splice(0, 1);
                if (tabExp.length === 0) {
                    clearInterval(this.explosion);
                    this.explosion = null;
                    this.props.showScore();
                } else {
                    this.setState(tabExp);
                }
            }
            i++;
        }, 100);
    };

    bossImage = () => {
        switch (this.props.bossSprite) {
            case 0:
                return ClassicSprite;
            case 1:
                return matthieu;
            default:
                return ClassicSprite;
        }
    };
    stop = () => {
        clearInterval(this.move);
        clearInterval(this.fireMove);
    };
    render() {
        const { posX, posY } = this.state;
        if (this.props.life === 0) {
            this.stop();
        }
        return (
            <div>
                <div className='infoBoss'>
                    <h1 className='bossName'>
                        <span className='red' style={{ fontSize: 30 }}>
                            G
                        </span>
                        onza
                        <span className='blue' style={{ fontSize: 30 }}>
                            G
                        </span>
                        ue
                    </h1>
                    <progress className='HpBarre' value={this.state.HP} max={25 + this.props.lvl}></progress>
                </div>
                <div className='bordure' style={{ position: "absolute", top: posY, left: posX }}>
                    <img src={this.bossImage()} className='image' alt='bossImage' />
                </div>
                {this.state.fire.map((el, index) => (
                    <img src={fire} key={index} alt='fire' width={30} height={30} style={{ position: "absolute", top: el.coordY, left: el.coordX }} />
                ))}
                {this.state.tabExp.map((el, index) => {
                    return (
                        <img
                            key={index}
                            src={explosion}
                            width={40}
                            alt='explosion'
                            style={{ position: "absolute", top: el.y + posY, left: el.x + posX }}
                        />
                    );
                })}
            </div>
        );
    }
}
