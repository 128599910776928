// -------------------------------------------------------------------------
// Librairies
import manager from "./libs/WarriorManager";
import SHA1 from "sha1";
import Quest from "./libs/Quest";
import Clue from "./libs/Clue";

/**
 * Many Clics
 * Le but est que l'utilisateur écrive un script
 * pouvant cliquer nbClick fois sur le mot 'vingtaine' (className = "clickMe")
 */

const nbClick = 30;

export default class ManyClicks {
    constructor(hello) {
        // compteur des clics
        this.count = 0;
        // temps 1er clic
        this.start = 0;
        // temps dernier clic
        this.end = 0;
        // récupère l'élément à cliquer
        this.clickElement = null;

        if (hello) {
            this.hello = hello;
        }

        // instanciation de la variable this.quest de la classe Quest si l'utilisateur a cliqué à la main
        this.questClick = new Quest(
            "En un clic",
            SHA1("Souris"),
            new Clue(4, "En un clic", `Sacré clic ! Mais essayez de cliquer ${nbClick} fois en moins d'une seconde...`, 2)
        );
        // instanciation de la variable this.quest de la classe Quest si l'utilisateur écrit une fonction
        this.quest = new Quest("En un clic", SHA1("Flash"), new Clue(4, "En un clic", "COMPLETED !", 3));
        this.doneEvent = false; //bolean qui permet de savoir si le easter egg a déjà été activé
        //si l'easter a déjà été fait alors on l'utilisateur ne pourra pas le refaire
        if (localStorage.getItem("coddingMan1")) {
            const listQuest = JSON.parse(localStorage.getItem("quest"));
            if (listQuest) {
                listQuest.forEach(element => {
                    if (this.quest.name === element.name) {
                        this.doneEvent = true;
                    }
                });
            }
        }
        if (!this.doneEvent) {
            this.clickElement = document.querySelectorAll(".clickMe")[0];

            if (this.clickElement) {
                // on ajoute pointer à l'élément à cliquer
                this.clickElement.className += " pointer";
                // lors du clic sur l'élément à cliquer, appelle la fonction handleClick
                this.clickElement.onclick = () => this.handleClick();
            }
        }
    }

    /**
     * Appelée à chaque clic sur l'élément à cliquer
     * Récupère l'heure du 1er clic et l'heure du nbClick_ème clics
     * Si la difference entre les 2 temps est supérieur à 1sec alors
     * on sait que les clics ont été fait manuellement : la quete est à moitié réussie
     * si l'utilisateur a écrit une fonction alors quête completed
     */
    handleClick = () => {
        if (!this.doneEvent) {
            if (this.count === 0) {
                this.start = new Date().getTime();
            }
            this.count += 1;
            // si la quête n'a pas été half completed ou completed alors on affiche le compteur
            if (this.clickElement.className === "clickMe pointer") {
                console.log("Vous avez trouvé", this.count, this.count === 1 ? "artisan" : "artisans");
                if (this.count >= nbClick) {
                    this.end = new Date().getTime();
                    let diff = (this.end - this.start) / 1000;
                    // si clic manuel
                    if (diff > 1) {
                        this.halfCompleted();
                    }
                    // sinon machine
                    else {
                        this.completed();
                    }
                }
            }
            // cas où la quête a été half completed ce qui entraîne la suppression du css pointer sur l'élément
            else if (this.count >= nbClick) {
                this.completed();
            }
        }
    };

    reset = () => {
        this.clickElement.className = "clickMe";
        this.count = 0;
        this.start = 0;
        this.end = 0;
    };

    halfCompleted = () => {
        this.reset();
        manager.completed(this.questClick);
    };

    completed = () => {
        this.reset();
        manager.completed(this.quest);
        //on prends l'ensemble des quêtes completés par l'utilisateur précédement
        let listQuest = JSON.parse(localStorage.getItem("quest"));
        //on push la quête complétés dans le tableau des quêtes
        if (listQuest) {
            listQuest.push(this.quest);
            //on stocke ces changements dans le local storage
            localStorage.setItem("quest", JSON.stringify(listQuest));
            //on signale que l'easter egg a été effectué une fois
        }
        if (this.doneEvent === false) {
            this.doneEvent = true;
            if (this.hello) {
                this.hello();
            }
        }
    };
}
