import React from 'react';

export default function HelpSvg(){
    return(
		<div className="logotech">
			<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				viewBox="0 0 442.668 442.668" enableBackground="new 0 0 442.668 442.668" xmlSpace="preserve"
				width="40%" height="40%">
			<g>
				<g id="Layer_1_50_">
					<g>
						<path d="M237.57,293.74c0.023-0.002,0.046-0.004,0.069-0.006C237.63,293.734,237.602,293.736,237.57,293.74z"/>
						<g>
							<path d="M237.507,293.745c0.021-0.001,0.042-0.003,0.063-0.005C237.548,293.742,237.526,293.744,237.507,293.745z"/>
							<path d="M248.28,0c0,0-13.031,0.974-20.114,30.652c-3.827,16.035-5.044,38.234-6.707,61.65
								c-0.647,9.119-1.317,18.549-2.123,28.078c-8.905,4.643-17.811,9.285-26.716,13.928c-8.217,4.283-16.433,8.566-24.649,12.85
								c2.337-2.494,4.454-5.236,6.271-8.232c5.795-9.557,8.03-21.039,6.498-32.082c10.145-7.83,5.3-16.346,1.498-23.836
								c-13.303-26.211-47.626-24.465-47.626-24.465c-46.056,2.125-53.398,25.662-51.693,44.408l0.169,2.574
								c0.038,0.57,0.992,14.135,7.502,27.861c7.359,15.521,18.808,25.033,33.44,27.949c-8.38,6.336-14.133,15.902-15.607,26.389
								c-0.831,5.906,0.058,11.551,1.061,17.328c1.177,6.77,2.353,13.539,3.528,20.311c2.937,16.9,5.872,33.801,8.808,50.699
								c0.604,3.479,1.208,6.955,1.813,10.432c1.039,5.98,3.494,12.063,7.039,17.008c1.027,1.434,2.056,2.869,3.084,4.303
								c9.526,13.289,19.053,26.576,28.579,39.865c1.274,1.777,2.549,3.555,3.823,5.332c0.484,0.676,0.501,1.145,0.632,1.994
								c1.032,6.707,2.064,13.412,3.097,20.117c1.326,8.617,2.652,17.236,3.979,25.854c0.873,5.672,1.197,11.91,4.31,16.885
								c7.035,11.252,23.215,13.082,32.506,3.557c4.142-4.246,6.292-10.154,5.854-16.068c-0.102-1.369-0.202-2.738-0.304-4.107
								c-1.204-16.281-2.408-32.561-3.612-48.842c-0.384-5.186-0.163-11.219-2.049-16.137c-1.066-2.781-2.902-5.281-4.508-7.783
								c-2.282-3.559-14.239-24.096-14.239-24.096s32.481-7.961,45.72-10.631c-0.095,0.007-0.078,0.005-0.035,0.001
								c-0.29,0.021-0.581,0.048-0.871,0.071c0.396-0.037,0.791-0.08,1.187-0.119c-0.37,0.035-0.722,0.006-1.088-0.018
								c3.164-0.264,6.782,1.131,9.755,2.072c1.205,0.383,2.402,0.791,3.595,1.213l-11.805,62.164l8,58.205v25.334H359.97V0H248.28z
								M108.342,72.066c0.072-0.287,0.341-0.586,0.732-0.787c4.776-2.461,9.781-4.041,15.083-4.902
								c0.831-0.135,1.789,0.141,2.189,0.598c1.903,2.166,2.76,3.291,4.271,5.592c0.176,0.266,0.176,0.545,0.009,0.77
								c-0.128,0.172-0.345,0.305-0.616,0.383c-0.085,0.025-0.179,0.041-0.271,0.059c-4.348,0.76-8.367,2.039-12.294,4.055
								c-0.322,0.164-0.736,0.268-1.139,0.25c-0.401-0.02-0.761-0.146-0.994-0.355c-2.395-2.15-3.729-3.154-6.642-4.941
								C108.391,72.613,108.269,72.354,108.342,72.066z M92.083,92.746c0.991-5.582,3.932-10.139,7.473-14.191
								c0.258-0.293,0.677-0.533,1.099-0.656c0.423-0.125,0.83-0.119,1.124,0.008c2.841,1.221,5.55,2.742,8.063,4.543
								c0.43,0.309,0.401,0.85,0.042,1.277c-2.515,3.002-4.701,6.326-5.374,10.43c-0.014,0.086-0.038,0.17-0.073,0.252
								c-0.095,0.225-0.266,0.424-0.486,0.561c-0.3,0.186-0.66,0.242-0.977,0.145c-3.271-0.99-6.688-1.453-10.104-1.4
								C92.321,93.719,91.981,93.322,92.083,92.746z M99.822,129.305c-1.132-2.361-2.073-4.73-2.859-7.02l10.618-1.119
								c3.154,8.354,8.475,20.025,16.323,29.766C113.49,148.18,105.409,140.949,99.822,129.305z M165.612,133.693
								c-5.95,9.809-16.126,16.586-26.813,18.162c-9.969-8.297-16.807-21.871-20.786-31.787c0,0,32.725-2.977,40.155-4.152
								c11.583-1.832,12.936-3.773,12.936-3.773C171.393,119.664,169.527,127.234,165.612,133.693z M232.432,93.082
								c2.151-40.498,8.516-66.414,8.516-66.414L255.615,46l-10.318,60.969c-3.138,1.213-6.116,3.068-9.088,4.617
								c-1.782,0.928-3.563,1.857-5.345,2.787C231.431,107.158,232.062,100.041,232.432,93.082z M226.348,157.713
								c2.81-1.68,5.62-3.359,8.43-5.039c3.744-2.238,7.488-4.477,11.233-6.715l5.187,13.615c-9.014,4.035-18.028,8.072-27.042,12.111
								C224.963,167.074,225.69,162.41,226.348,157.713z M214.129,165.018c-0.479,2.963-0.99,5.9-1.535,8.811
								c-0.045-0.006-0.09-0.012-0.135-0.016c-3.663-0.41-7.326-0.818-10.989-1.227C205.689,170.063,209.909,167.541,214.129,165.018z
								M192.134,213.418c-0.188-1.088-0.378-2.176-0.566-3.264c3.726,0.203,7.45,0.408,11.175,0.611
								c-2.334,6.256-4.99,12.137-8.021,17.547C193.859,223.348,192.996,218.383,192.134,213.418z M261.041,254.68
								c-3.734-1.52-7.545-2.857-11.473-3.912c-5.088-1.365-10.125-2.041-15.38-1.926c-5.011,0.111-10.108,1.207-15.038,1.969
								c-6.659,1.025-13.296,2.223-19.93,3.402c-0.571-3.287-1.142-6.574-1.713-9.861c6.942-9.451,12.379-20.646,16.694-32.957
								c1.748,0.094,3.497,0.189,5.245,0.287c3.886,0.217,7.288-0.605,10.705-2.373c8.871-4.588,17.742-9.178,26.614-13.768
								L261.041,254.68z"/>
						</g>
					</g>
				</g>
			</g>

			</svg>
		</div>
	);
}