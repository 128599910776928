import React from 'react';

import LogoItem from "./logoitem/LogoItem";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons';

import LogoMajor from '../img/major.svg';
import { DanilSvg } from '../assets/svg/';
import LogoShaker from '../img/ginto.svg';
import LogoSara from '../img/sara.svg';

import "../css/project-grid.css";

const Produits = ({striped}) => (
    <div id="produits" className={`section ${striped}`}>
        <div className="content">
            <h1 className="sectiontitle">
                Produits
            </h1>
            <div className="block-content">
                <div className="project-grid">

                    <div className="project-grid-item center">
                        <LogoItem imgSvg={LogoMajor} />
                        <h1>Major IA</h1>
                        <p className="pad-text">
                            Nous développons des solutions d'<b>IA générative</b> du POC à l'industrialisation. Nous formons 
                            des équipes au travers de <b>3 ateliers</b> : découverte de l'IA, pratique du prompting, 
                            perfectionnement.
                        </p>
                        <p className="pad-text">
                            <a href="mailto:contact@coddity.com" target="_blank" rel="noopener noreferrer">
                                <button className="btn btn-center sendbtn">
                                    En savoir plus <FontAwesomeIcon icon={faEnvelope} className="iconMargin" />
                                </button>
                            </a>
                        </p>
                    </div>

                    <div className="project-grid-item center">
                        <DanilSvg />
                        <h1>Danil</h1>
                        <p className="pad-text">
                            Nous optimisons la fonction <b>Service Client</b> grâce à notre suite logicielle complète : assistant
                            virtuel, moteur de NLU et datavisualisation.
                            Nous traitons aujourd'hui <b>1 million de conversations mensuelles</b>.
                        </p>
                        <p className="pad-text">
                            <a href="https://danil.io/" target="_blank" rel="noopener noreferrer">
                                <button className="btn btn-center blackbtn">
                                    danil.io <FontAwesomeIcon icon={faExternalLinkAlt} className="iconMargin" />
                                </button>
                            </a>
                        </p>
                    </div>

                    <div className="project-grid-item center">
                        <LogoItem imgSvg={LogoShaker} />
                        <h1>Ginto</h1>
                        <p className="pad-text">
                            Plateforme de co-construite
                            avec l'agence <a href="http://pikel.fr/" target="_blank" rel="noopener noreferrer">Pikel</a>,
                            Ginto favorise l'apprentissage grâce à un gameplay novateur reposant sur le <b>micro-learning</b>.
                            Utilisée par <b>30 000 collaborateurs</b> du secteur banque assurance.
                        </p>
                        <p className="pad-text">
                            <a href="https://ginto.ink/" target="_blank" rel="noopener noreferrer">
                                <button className="btn btn-center greenbtn">
                                    ginto.ink <FontAwesomeIcon icon={faExternalLinkAlt} className="iconMargin" />
                                </button>
                            </a>
                        </p>
                    </div>

                    <div className="project-grid-item center">
                        <LogoItem imgSvg={LogoSara} />
                        <h1>S.A.R.A.</h1>
                        <p className="pad-text">
                            <b>Artificial Powers for Human Resources</b>. Partenaire de Coddity, S.A.R.A.
                            est un module d'IA dédié au <b>recrutement</b>, interopérable avec tout ATS. Elle identifie
                            les <b>meilleurs profils</b> candidats pour une <b>fiche de poste</b>.
                        </p>
                        <p className="pad-text">
                            <a href="https://sara-corp.com/" target="_blank" rel="noopener noreferrer">
                                <button className="btn btn-center orangebtn">
                                    sara-corp.com <FontAwesomeIcon icon={faExternalLinkAlt} className="iconMargin" />
                                </button>
                            </a>
                        </p>
                    </div>

                </div>
            </div>
        </div>
    </div>
);

export default Produits;
