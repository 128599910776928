import React from 'react';

export default function LunetariumSVG(){
	return(
		<div className="logotech">
			<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 32.5 32.5"
			xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px" 
			height="40%" width="40%"
			enableBackground="new 0 0 32.5 32.5"
	 		xmlSpace="preserve">
				<g>
                    <path d="M28.797,30.333c0,0,1.267-7.802-0.695-12.532c0,0-1.052-3.812-1.047-4.88c0,0-10.998,7.271-15.262,6.646
                    c0,0,5.652-3.786,6.574-5.764c0,0-8.708,6.06-11.602,5.592c0,0,2.903-1.972,3.517-3.033c0,0-6.86,1.346-7.041,8.354L2.457,30.5
                    c0,0-1.462-7.059-1.947-8.69c-1.496-5.032,0.56-13.64,2.804-15.376c0,0,4.141-7.146,18.912-4.953c0,0,7.833,0.381,10.534,10.955
                    c0.631,2.469-0.364,8.006-1.172,10.104c-0.801,2.082-2.878,9.356-2.878,9.356L28.797,30.333z M13.5,29.02
                    c1.369-0.999,1.818-4.941,1.922-6.128c0.019-0.199-0.052-0.397-0.194-0.545c-0.142-0.148-0.341-0.232-0.551-0.232H5.165
                    c-0.21,0-0.409,0.084-0.551,0.232c-0.142,0.146-0.212,0.346-0.195,0.545c0.104,1.187,0.554,5.129,1.923,6.128
                    c1.023,0.744,2.844,0.824,3.579,0.824S12.476,29.764,13.5,29.02z M13.848,23.548c-0.244,1.937-0.755,3.966-1.254,4.328
                    c-0.365,0.267-1.333,0.533-2.673,0.533c-1.341,0-2.309-0.269-2.673-0.533c-0.498-0.362-1.009-2.394-1.254-4.328H13.848z
                    M26.617,29.02c1.368-0.999,1.817-4.941,1.922-6.128c0.019-0.199-0.054-0.397-0.193-0.545c-0.143-0.148-0.342-0.232-0.551-0.232
                    h-9.514c-0.209,0-0.408,0.084-0.551,0.232c-0.143,0.146-0.212,0.346-0.193,0.545c0.104,1.187,0.554,5.129,1.922,6.128
                    c1.022,0.744,2.845,0.824,3.579,0.824S25.592,29.764,26.617,29.02z M26.964,23.548c-0.243,1.937-0.755,3.966-1.254,4.328
                    c-0.364,0.267-1.333,0.533-2.673,0.533c-1.341,0-2.309-0.269-2.674-0.533c-0.497-0.362-1.01-2.394-1.254-4.328H26.964z
                    M18.959,24.198c0-0.396-0.335-0.718-0.748-0.718h-3.465c-0.414,0-0.748,0.321-0.748,0.718s0.334,0.718,0.748,0.718h3.465
                    C18.625,24.916,18.959,24.594,18.959,24.198z"/>
				</g>
			</svg>
		</div>
	);
}
