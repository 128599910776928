import React from "react";
import "./Parametre.css";
import play from "../../asset/image/play.png";
import "../../asset/css/global.css";
export default class BoardingScore extends React.Component {
    render() {
        return (
            <div>
                <div className='scoreResult'>
                    {!isNaN(this.props.lvl) && (
                        <p>
                            NIVEAU {this.props.lvl}
                        </p>
                    )}
                    {isNaN(this.props.lvl) && <p>{this.props.lvl}</p>}
                    <p style={{ margin: 5 }}>
                        <span>SCORE</span>
                    </p>
                    <p>{this.props.score}</p>
                    {this.props.onNextLevel && (
                        <img 
                            src={play} 
                            alt='play' 
                            width={50} 
                            style={{ cursor: "pointer" }} 
                            onClick={this.props.onNextLevel} 
                        />
                    )}
                    <div onClick={this.props.onQuit} className='quit'>
                        <p style={{ fontSize: 20 }}>QUITTER</p>
                    </div>
                </div>
            </div>
        );
    }
}
