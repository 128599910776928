import React from 'react';

export default function HandSvg(){
    return(
		<div className="logotech">
			<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
				width="40%" height="40%" viewBox="0 0 424.225 424.225" xmlSpace="preserve">
			<g>
				<g>
					<rect x="119.579" y="401.738" width="184.571" height="22.487"/>
					<path d="M304.15,390.987H119.579v-39.023c25.954,19.056,57.945,30.347,92.542,30.347c34.375,0,66.176-11.161,92.029-29.996
						V390.987z"/>
					<path d="M267.341,28.15c0-15.523,12.631-28.15,28.137-28.15c15.538,0,28.159,12.638,28.159,28.15v151.521l-56.296-54.399v-11.368
						V98.178V28.15z"/>
					<path d="M228.444,103.559c10.541,0,20.168,5.976,24.978,15.296c-1.146,0.145-2.259,0.565-3.14,1.447
						c-24.063,24.065-24.063,63.197,0,87.259l5.627,5.629c-2.814,12.809-14.311,22.079-27.465,22.079
						c-15.538,0-28.161-12.635-28.161-28.149v-21.652v-53.753C200.283,116.177,212.906,103.559,228.444,103.559z"/>
					<path d="M278.072,235.406c-0.231-0.031-0.441-0.127-0.673-0.127h-22.352c4.001-3.779,7.308-8.305,9.439-13.533l13.657,13.67
						C278.113,235.406,278.092,235.406,278.072,235.406z"/>
					<path d="M161.383,103.559c15.524,0,28.144,12.633,28.144,28.144v53.754c0,15.531-12.63,28.153-28.144,28.153
						c-15.526,0-28.146-12.636-28.146-28.153v-53.754C133.232,116.177,145.857,103.559,161.383,103.559z"/>
					<path d="M94.324,48.954c15.536,0,28.158,12.632,28.158,28.145v54.599v53.753c0,21.455,17.449,38.899,38.896,38.899
						c11.223,0,21.347-4.768,28.452-12.399c1.139,9.123,5.482,17.261,11.832,23.307h-87.458c-2.974,0-5.375,2.399-5.375,5.376
						c0,2.961,2.402,5.375,5.375,5.375h142.829l-47.572,26.987c-2.575,1.454-3.483,4.745-2.027,7.313c1,1.742,2.801,2.719,4.683,2.719
						c0.897,0,1.811-0.221,2.646-0.693l65.278-37.05c0.205-0.117,0.33-0.305,0.51-0.441c0.314-0.231,0.599-0.452,0.851-0.735
						c0.231-0.262,0.377-0.535,0.557-0.828c0.168-0.283,0.34-0.579,0.462-0.914c0.114-0.346,0.158-0.715,0.221-1.081
						c0.021-0.226,0.125-0.431,0.125-0.667c0-0.109-0.062-0.194-0.062-0.299c-0.011-0.116,0.042-0.231,0.021-0.337l22.993,22.982
						c1.044,1.049,2.426,1.575,3.8,1.575c1.376,0,2.75-0.526,3.802-1.575c2.108-2.101,2.108-5.49,0-7.602l-55.429-55.416
						c-19.508-19.512-19.807-51.006-1.003-70.948c0.031,0.113,0.031,0.215,0.073,0.328c0.036,0.103,0.026,0.226,0.067,0.328
						c0.06,0.113,0.158,0.205,0.211,0.315c0.262,0.522,0.561,1.013,0.981,1.419c0.01,0.01,0.021,0.026,0.031,0.045l99.781,96.421
						c-0.651,43.234-20.194,81.949-50.73,108.279c-0.84,0.395-1.507,1.025-2.047,1.769c-25.281,21.009-57.755,33.671-93.117,33.671
						c-80.479,0-145.942-65.481-145.942-145.934v-24.489V77.109C66.179,61.586,78.802,48.954,94.324,48.954z"/>
				</g>
			</g>
			</svg>
		</div>
	);
}
