import React from "react";
import "./GameBoard.css";
export default class GameBoard extends React.Component {
    render() {
        const { bigger } = this.props;
        return (
            <div
                className='ball'
                style={{
                    position: "absolute",
                    top: this.props.coordY,
                    left: this.props.coordX,
                    backgroundColor: bigger ? "black" : "#cd4442",
                    borderColor: bigger ? "black" : "#cd4442"
                }}
            />
        );
    }
}
