import React from 'react';

const CrapoSvg = () => (
	<div className="logotech">
		
		<svg viewBox="0 -25 576 576" width="40%" xmlns="http://www.w3.org/2000/svg"><path d="m446.53 97.43c-6.86-37.2-39.34-65.43-78.53-65.43-39.23 0-71.72 28.29-78.54 65.54-162.71 15.42-289.96 152.58-289.46 319.44.11 34.92 29.08 63.02 64 63.02h304c8.84 0 16-7.16 16-16 0-17.67-14.33-32-32-32h-79.49l35.8-48.33c24.14-36.23 10.35-88.28-33.71-106.6-23.89-9.93-51.55-4.65-72.24 10.88l-32.76 24.59c-7.06 5.31-17.09 3.91-22.41-3.19-5.3-7.08-3.88-17.11 3.19-22.41l34.78-26.09c36.84-27.66 88.28-27.62 125.13 0 10.87 8.15 45.87 39.06 40.8 93.21l118.53 125.94h90.38c8.84 0 16-7.16 16-16 0-17.67-14.33-32-32-32h-53.63l-98.52-104.68 154.44-86.65a58.16 58.16 0 0 0 29.71-50.73c0-21.4-11.72-40.95-30.48-51.23-40.56-22.22-98.99-41.28-98.99-41.28zm-78.53 38.57c-13.26 0-24-10.75-24-24 0-13.26 10.74-24 24-24 13.25 0 24 10.74 24 24 0 13.25-10.75 24-24 24z"/></svg>
		
	</div>
);

export default CrapoSvg;
