// * * * * * *
// Style
import "./harlem.css";

// * * * * * *
// Audio
import hth from "../assets/HTH.mp3";

// * * * * * *
// Libs
import manager from "./libs/WarriorManager";
import Quest from "./libs/Quest";
import Clue from "./libs/Clue";
import AudioWrapper from "./libs/AudioWrapper"

import SHA1 from "sha1";

/**
 * HarlemShake - quête secondaire
 * Konami code déclenchant une danse des éléments de la page à la façon Harlem Shake
 * Musique utilisée : Highway to Hell - AC/DC
 */
export default class HarlemShake {
    constructor(ref, hello) {
        this.isReady = true; // permet de savoir si une danse est déjà en cours
        this.parentNode = document.getElementById("root"); // afin de récupérer les nodes de la page
        this.current = 0; // nombre de touches pressées d'affilées correctement pour le code
        this.konamiCode = [38, 38, 40, 40, 37, 39, 37, 39, 66, 65]; // code complet à effectuer
        this.audio = new AudioWrapper(hth); // conteneur de la musique
        this.audio.currentTime = 48;
        if (hello) {
            this.hello = hello;
        }
        this.doneEvent = false; //bolean qui permet de savoir si le easter egg a déjà été activé
        this.cssClasses = ["im_drunk", "im_baked", "im_trippin", "im_blown"]; // classes css à appliquer pour faire danser les éléments
        document.addEventListener("keydown", event => this.onKeyDown(event)); // on écoute les touches pressées

        this.quest = new Quest(
            "Un semblant de tradition les réunissant tous",
            SHA1("Chausson de danse en acier trempé"),
            new Clue(2, "Un semblant de tradition les réunissant tous", "COMPLETED !", 2)
        );
        //si on a déjà fait cet easter egg, on ne peut pas le refaire
        if (localStorage.getItem("coddingMan1")) {
            const listQuest = JSON.parse(localStorage.getItem("quest"));
            if (listQuest) {
                listQuest.forEach(element => {
                    if (this.quest.name === element.name) {
                        this.doneEvent = true;
                    }
                });
            }
        }
    }

    /**
     * Fonction appelé lorsque l'évènement keydown est lancé
     * Si l'objet est prêt, alors on écoute les touches pressées
     * Si la touche pressées correspond à celle du tableau à l'index 'current' alors on augmente current
     * Si la touche pressées correspond à la dernière, alors on lance la fonction de danse
     */
    onKeyDown = event => {
        //si le easter egg a déjà été fait, on ne peut rien faire
        if (!this.doneEvent) {
            if (this.isReady) {
                if (this.current < this.konamiCode.length - 1) {
                    this.konamiCode[this.current] === event.keyCode ? this.current++ : (this.current = 0);
                } else if (this.current === this.konamiCode.length - 1 && this.konamiCode[this.current] === event.keyCode) {
                    this.isReady = false;
                    this.doTheHarlemRedone();
                    this.current = 0;
                } else {
                    this.current = 0;
                }
            }
        }
    };

    /**
     * Fonction appelé à la fin de la danse pour remettre la piste en position initiale
     */
    resetAudio = () => {
        this.audio.pause();
        this.audio.currentTime = 48;
    };

    /**
     * Fonction de danse
     * On récupère tous les éléments descendants de parentNode et on vérifie la compatibilité
     * On lance la musique et à tous les éléments compatibles on y ajoute une classe css au hasard
     * Après 24,75s on arrête tout et enlève les classes css
     */
    doTheHarlemRedone = () => {
        let allElements = this.parentNode.getElementsByTagName("*");

        let minWidth = 30,
            minHeight = 30,
            maxWidth = window.innerWidth / 2,
            maxHeight = window.innerHeight / 2;
        let elementsCompatible = [];
        for (let idx = 0; idx < allElements.length; idx++) {
            let elem = allElements[idx];
            if (elem.offsetHeight > minHeight && elem.offsetHeight < maxHeight && elem.offsetWidth > minWidth && elem.offsetWidth < maxWidth) {
                elementsCompatible.push(elem);
            }
        }
        elementsCompatible[0].className += " mw-harlem_shake_me im_first";
        this.audio.play();
        setTimeout(() => {
            elementsCompatible[0].className = elementsCompatible[0].className.replace("mw-harlem_shake_me im_first", "").trim();
            elementsCompatible.forEach(element => {
                this.addRandomCssClass(element);
            });
        }, 5800);
        setTimeout(() => {
            this.isReady = true;
            this.resetAudio();
            elementsCompatible.forEach(element => {
                this.removeCssClass(element);
            });
            this.completed();
        }, 24750);
    };

    /**
     * Ajoute une classe css au hasard parmis le tableau cssClasses en plus de la classe 'mw-harlem_shake_me' à la node en paramètre
     */
    addRandomCssClass = node => {
        node.className += ` mw-harlem_shake_me ${this.cssClasses[Math.floor(Math.random() * this.cssClasses.length)]}`;
    };

    /**
     * Enlève les classes css précédemment ajoutées à la node en paramètre
     */
    removeCssClass = node => {
        for (let idx = 0; idx < this.cssClasses.length; idx++) {
            node.className = node.className.replace(this.cssClasses[idx], "");
        }
        node.className = node.className.replace("mw-harlem_shake_me", "");
        node.className = node.className.trim();
    };

    completed = () => {
        manager.completed(this.quest);
        //on prends l'ensemble des quêtes completés par l'utilisateur précédement
        let listQuest = JSON.parse(localStorage.getItem("quest"));
        if (listQuest) {
            //on push la quête complétés dans le tableau des quêtes
            listQuest.push(this.quest);
            //on stocke ces changements dans le local storage
            localStorage.setItem("quest", JSON.stringify(listQuest));
        }
        //on signale que l'easter egg a été effectué une fois
        if (this.doneEvent === false) {
            this.doneEvent = true;
            if (this.hello) {
                this.hello();
            }
        }
    };
}
