import React, { useEffect, useRef, useState } from "react";
import { NavHashLink as Link } from "react-router-hash-link";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import { Input, Textarea, MultiSelection } from "./FormComponents";
import { competencesList } from "./competencesList/list";
import MCaptcha from "./captcha/MCaptcha";

import {
  faEnvelope,
  faUser,
  faIndustry,
  faUserTie,
  faFileAlt,
  faHeart,
  faRobot
} from "@fortawesome/free-solid-svg-icons";

import 'react-toastify/dist/ReactToastify.css';
import '../css/form.css'


const patternMail = /^(([^<>()[\].,;:\s@"]+(\.[^<>()[\].,;:\s@"]+)*)|(".+"))@(([^<>()[\].,;:\s@"]+\.)+[^<>()[\].,;:\s@"]{2,})$/i;

/**
 * @prop typeFormulaire : "contact" ou "candidat" 
 */
const Form = ({ typeFormulaire }) => {

  const initContact = {
    name: "",
    mail: "",
    message: "",
    interest: [],
    company: "",
    profession: "",
    agreementCheck: false
  }

  const [contact, setContact] = useState(initContact);

  const [mCaptcha, setMCaptcha] = useState({});

  const handleChange = (e) => {
    const targetName = e.target.name;
    const targetValue = e.target.value;
    setContact({ ...contact, [targetName]: targetValue });
  };

  const handleInterestChange = (arrayOfInterestsObj) => {
    setContact({ ...contact, interest: [...arrayOfInterestsObj] });
  }

  const handleAgreement = () => {
    setContact({ ...contact, agreementCheck: !contact.agreementCheck });
  };

  const handleReset = e => {
    e.preventDefault();
    resetForm();
  };

  const getMCaptchaUrl = async () => {
    try {
      const response = await axios.get('/captcha');
      const { widgetUrl } = response.data;
      setMCaptcha({ widgetUrl });
    } catch {
      toast.error('Erreur lors de la récupération de la configuration du captcha. Veuillez nous contacter à contact@coddity.com');
    }
  };

  const resetForm = () => {
    // On remet à zéro le state
    setContact(initContact);
    setMCaptcha({});
  };

  const checkError = () => {
    
    // Champ nom commun à tous
    if (contact.name.length < 2) {
      return "Un nom de plus d'un caractère est requis";
    }
    if (contact.name.length > 50) {
      return "Un nom de plus de 50 caractères ? Quelle famille !";
    }

    // Champ mail commun à tous
    if (patternMail.test(contact.mail) === false) {
      return "Votre email n'a pas la bonne forme.";
    }
    // Champ message commun à tous
    if (!contact.message) {
      return "Un message ?";
    }

    // Validation mCaptcha
    if (!mCaptcha.token) {
      return "Merci de vous occuper du contrôle sécu (captcha)";
    }

    if (!contact.agreementCheck) {
      return "Vous devez consentir à nous communiquer ces données. Promis, c'est juste pour vous recontacter.";
    }

    return "";
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // En cas d'erreur sur le formulaire, s'arrêter
    let error = checkError();
    if (error !== "") {
      toast.warn(error);
      return;
    }

    let interest = contact.interest.map(obj => obj.name);

    const formData = {
      ...contact,
      type: typeFormulaire,
      interest,
      mCaptchaToken: mCaptcha.token
    }

    toast.info("Envoi de vos coordonnées et de votre message...");
    
    try {
      // Envoi en POST du formulaire
      const response = await axios.post('/contact', formData);
      toast.success("Message bien pris en compte, on vous recontacte rapidement !");
      resetForm();
    } catch (err) {
      if (err.response && err.response.status !== 500 && err.response.data.error) {
        // Erreur prise en charge
        toast.error(`Erreur : ${err.response.data.error}`);
      } else {
        // Erreur non maîtrisée catchée
        toast.error("Erreur, merci de réessayer, et sinon vous pouvez envoyer un mail à contact@coddity.com.");
      }
      setMCaptcha({});
    }
  }

  useEffect(() => {
    if (!mCaptcha.widgetUrl) {
      getMCaptchaUrl();
    }
  }, [mCaptcha]);
  
  return (
    <form
      className="grid-s-12 grid-m-10 grid-m-offset-1 grid-l-6 grid-l-offset-3"
      method="POST"
    >
      <ToastContainer
        position="top-center"
        autoClose={5000}
        closeOnClick
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />

      <div className="row" style={{ marginTop: "20px" }}>
        <div className="grid-s-12 grid-m-6">
          <Input
            type="text"
            name="name"
            handleChange={handleChange}
            required
            label={{ icon: faUser, text: "Votre nom" }}
            value={contact.name}
          />
          <Input
            type="mail"
            name="mail"
            handleChange={handleChange}
            required
            label={{ icon: faEnvelope, text: "Email" }}
            value={contact.mail}
          />
          
          { typeFormulaire === "contact" &&
            <>
                <Input
                  type="text"
                  name="company"
                  handleChange={handleChange}
                  required
                  label={{ icon: faIndustry, text: "Votre société" }}
                  value={contact.company}
                />
                <Input
                  type="text"
                  name="profession"
                  handleChange={handleChange}
                  required
                  label={{ icon: faUserTie, text: "Votre fonction" }}
                  value={contact.profession}
                />
            </>
          } { typeFormulaire === "candidat" &&
            <MultiSelection
              data={competencesList}
              userInterest={contact.interest}
              textField='name'
              valueFied='value'
              onChange={handleInterestChange}
              label={{ icon: faHeart, text: "Intérêts" }}
            />
          }

        </div>
        <div className="grid-s-12 grid-m-6">
          <div className="group">
            <Textarea
              name="message"
              handleChange={handleChange}
              required
              label={{ icon: faFileAlt, text: "Votre message" }}
              value={contact.message}
              />
          </div>
        </div>
      </div>
        
      <div className="row">
        <div className="group">
          <div className="agreement">
            <label>
              <input
                type="checkbox"
                checked={contact.agreementCheck}
                onChange={handleAgreement}
              />{" "}
              J'autorise <span className="red">C</span>oddity à utiliser mes
              données personnelles pour me contacter dans le cadre de ma demande
              indiquée dans ce formulaire.
            </label>
            <p style={{ fontSize: "12px" }}>
              Voir notre politique de traitement des données personnelles{" "}
              <Link to="/mentions-legales" className="pointer">
                {" "}
                ici
              </Link>
              .
            </p>

            <div id="captcha" className="captcha">
              <MCaptcha
                widgetUrl={mCaptcha.widgetUrl}
                onValidate={(token) => {
                  setMCaptcha((previous) => ({ ...previous, token }))
                }}
                disabled={!!mCaptcha.token}
              />
            </div>

            <div className="buttons">
              {/* <!-- First submit button disabled and hidden to prevent submit on Enter keypress --> */}
              <button type="submit" disabled style={{display: "none"}} aria-hidden="true"></button>

              <button className="btn sendbtn" type="submit" onClick={handleSubmit}>Envoyer</button>
              <button className="btn resetbtn" onClick={handleReset}>Vider</button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
}

export default Form;