import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// Composant externe Multiselect de react-widgets
import { Multiselect } from 'react-widgets';
import 'react-widgets/dist/css/react-widgets.css';
// Override du style par défaut de Multiselect
import "../css/custoMultiSelect.css";

/**
 * Input du formulaire
 */
export const Input = ({
  type,
  name,
  handleChange,
  value,
  required,
  label,
  className
}) => (
  <div className={`group ${className ?? ''}`}>
    <input
      type={type}
      name={name}
      onChange={handleChange}
      value={value}
      required={required}
      className="input"
    />
    <span className="bar" />
    <label className="labelclass">
      <FontAwesomeIcon icon={label.icon} style={{ marginRight: "10px" }} />
      {label.text}
    </label>
  </div>
);

/**
 * Textarea pour écrire le message du formulaire
 */
export const Textarea = ({ name, handleChange, value, required, label }) => (
  <div className="group">
    <textarea
      name={name}
      onChange={handleChange}
      value={value}
      required={required}
      className="input"
    />
    <span className="textareabar" />
    <label className="labelclass">
      <FontAwesomeIcon icon={label.icon} style={{ marginRight: "10px" }} />
      {label.text}
    </label>
  </div>
);

/**
 * Composant pour le dropdown des "Intérêts" du formulaire
 * Utilse le composant Multiselect de react-widget
 */
export const MultiSelection = ({ userInterest, data, textField, valueField, onChange, label }) => (
  <div className="group">
     <Multiselect
      className={!!userInterest.length ? "textarea-valid" : ""}
      data={data}
      value={userInterest}
      textField={textField}
      valueField={valueField}
      onChange={onChange}
    />
    <span className="bar" />
    <label className="labelclass">
        <FontAwesomeIcon icon={label.icon} style={{ marginRight: "10px" }} />
        {label.text}
    </label>
  </div>
);

