import React from "react";
import "./Menu.css";
import plus from "../../asset/image/plus.jpg";
import ball from "../../asset/image/newBall.png";
import life from "../../asset/image/life.jpg";
import viseur from "../../asset/image/viseur3.png";
import fire from "../../asset/image/fire.png";
import retour from "../../asset/image/retour.png";
import retourHover from "../../asset/image/retourHover.png";
import sky from "../../asset/image/sky.jpg";
import prairie from "../../asset/image/backgrounnd.jpg";
import BossSprite from "../../asset/image/boss.png";
import Matthieu from "../../asset/image/boss2.png";
import code from "../../asset/image/code.jpg";
//musique du menu (à définir)
//import prelude from "../../asset/sound/prelude.mp3"
//les commentaire avec la variable audio sont à décommenter lorsqu'on aura trouvé une musique libre de droit

const NB_BOSS_SKIN = 2;
const NB_BACKGROUND = 4;
export default class Menu extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            type: "Menu",
            hoverExit: false,
            background: 0,
            bossSkin: 0
        };
    }

    componentDidMount() {
        /* this.audio=new Audio(prelude);
        this.audio.loop=true;
        this.audio.play(); */
    }

    componentWillUnmount() {
        /* this.audio.pause();
        this.audio=null; */
    }
    showRules = () => {
        this.setState({ type: "Rules" });
    };
    showMenu = () => {
        this.setState({ type: "Menu", hoverExit: false });
    };

    param = () => {
        this.setState({ type: "Param" });
    };

    moreBackground = () => {
        this.setState(prevState => ({
            background: prevState.background === NB_BACKGROUND - 1 ? 0 : prevState.background + 1
        }));
    };

    lessBackground = () => {
        this.setState(prevState => ({
            background: prevState.background === 0 ? NB_BACKGROUND - 1 : prevState.background - 1
        }));
    };

    moreBoss = () => {
        this.setState(prevState => ({
            bossSkin: prevState.bossSkin === NB_BOSS_SKIN - 1 ? 0 : prevState.bossSkin + 1
        }));
    };

    lessBoss = () => {
        this.setState(prevState => ({
            bossSkin: prevState.bossSkin === 0 ? NB_BOSS_SKIN - 1 : prevState.bossSkin - 1
        }));
    };

    selectImage = () => {
        const { background } = this.state;
        switch (background) {
            case 1:
                return sky;
            case 2:
                return prairie;
            case 3:
                return code;
            default:
        }
    };

    selectBoss = () => {
        const { bossSkin } = this.state;
        switch (bossSkin) {
            case 0:
                return BossSprite;
            case 1:
                return Matthieu;
            default:
        }
    };

    start = () => {
        const { background, bossSkin } = this.state;
        //this.audio.pause();

        this.props.start(background, bossSkin);
    };
    render() {
        if (this.state.type === "Rules") {
            return (
                <div>
                    <div className='rules'>
                        <p className='first'>Voici les règles</p>
                        <p className='first'>
                            Le but du jeu est très simple : il suffit de détruire toute les briques présentes sur le
                            terrain grâce à votre balle. Durant votre partie, vous pourrez ramasser plusieurs bonus :
                        </p>
                        <div className='bonusRule'>
                            <div className='bonusBorder'>
                                <img src={plus} alt='plus' height={15} width={15}></img>
                            </div>
                            <p>
                                Champi : agrandit votre raquette jusqu'à ce que vous perdiez une vie ou changiez de niveau.
                            </p>
                        </div>
                        <div className='bonusRule'>
                            <div className='bonusBorder'>
                                <img src={ball} alt='ball' height={15} width={15}></img>
                            </div>
                            <p>Extra ball : vous donne une balle en plus.</p>
                        </div>
                        <div className='bonusRule'>
                            <div className='bonusBorder'>
                                <img src={life} alt='ball' height={15} width={15}></img>
                            </div>
                            <p>Extra life : vous donne une vie supplémentaire.</p>
                        </div>
                        <div className='bonusRule'>
                            <div className='bonusBorder'>
                                <img src={viseur} alt='viseur' height={15} width={15}></img>
                            </div>
                            <p>
                                Sniper : vous pouvez choisir la direction de votre balle lorsqu'elle touche votre
                                raquette. Attention, vous n'avez que trois coups.
                            </p>
                        </div>
                        <div className='bonusRule'>
                            <div className='bonusBorder'>
                                <img src={fire} alt='fire' height={15} width={15}></img>
                            </div>
                            <p>
                                Magnum : votre balle devient noire et fait un maximum de dégâts en transperçant
                                plusieurs briques, pendant 5 secondes.
                            </p>
                        </div>
                    </div>
                    <div className='exit' onClick={this.showMenu}>
                        <img
                            src={!this.state.hoverExit ? retour : retourHover}
                            alt='exit'
                            onMouseOver={() => {
                                this.setState({ hoverExit: true });
                            }}
                            onMouseLeave={() => {
                                this.setState({ hoverExit: false });
                            }}
                        ></img>
                    </div>
                </div>
            );
        } 
        else if (this.state.type === "Menu") {
            return (
                <div className='menu'>
                    <div className='textContainer' onClick={this.start}>
                        <p>JOUER</p>
                    </div>
                    <div className='textContainer' onClick={this.showRules}>
                        <p>RÈGLES</p>
                    </div>
                    <div className='textContainer' onClick={this.param}>
                        <p>PARAMÈTRES</p>
                    </div>
                    <div className='exit' onClick={this.props.dontShow}>
                        <img
                            src={!this.state.hoverExit ? retour : retourHover}
                            alt='exit'
                            onMouseOver={() => {
                                this.setState({ hoverExit: true });
                            }}
                            onMouseLeave={() => {
                                this.setState({ hoverExit: false });
                            }}
                        ></img>
                    </div>
                </div>
            );
        } 
        else if (this.state.type === "Param") {
            const { background } = this.state;
            const image = this.selectImage();
            const boss = this.selectBoss();
            return (
                <div className='menu'>
                    <div className='selectionParam'>
                        <h1>PARAMÈTRES</h1>
                        <p>Fond d'écran</p>
                        <div className='choose'>
                            <div className='moins' onClick={this.lessBackground}></div>
                            <div className='backgroundSelect'>
                                {background !== 0 && <img src={image} alt='background' width={160} height={160}></img>}
                            </div>
                            <div className='plus' onClick={this.moreBackground}></div>
                        </div>
                        <p>Skin du Boss</p>
                        <div className='choose'>
                            <div className='moins' onClick={this.lessBoss}></div>
                            <div className='backgroundSelect'>
                                <img src={boss} alt='boss' width={160} height={160}></img>
                            </div>
                            <div className='plus' onClick={this.moreBoss}></div>
                        </div>
                    </div>
                    <div className='exit' onClick={this.showMenu}>
                        <img
                            src={!this.state.hoverExit ? retour : retourHover}
                            alt='exit'
                            onMouseOver={() => {
                                this.setState({ hoverExit: true });
                            }}
                            onMouseLeave={() => {
                                this.setState({ hoverExit: false });
                            }}
                        ></img>
                    </div>
                </div>
            );
        }
    }
}
