import Warrior from './Warrior.js'

/**
 * Manager de la classe Warrior
 * Cette classe doit être la seule à pouvoir manipuler la classe Warrior 
 * afin que la variable warrior soit unique et qu'il n'y ait pas de conflits
 */
class WarriorManager {
    constructor() {
        // l'instance unique de warrior
        this.warrior = new Warrior();
        // la variable warrior a-t-elle était créée
        this.created = false;
    }

    /**
     * Ajoute les objets à l'inventaire du warrior et les indices au journal !
     * @param {Quest} quest la quête complétée avec tous ses objets
     */
    completed(quest, bool = true) {
        // Vérifie que Quest est bien un objet
        if (typeof quest === "object") {
            // On vérifie que la quête n'a pas déjà été faite
            if (bool) {
                console.log(`Completed : ${quest.name}`);
            }
            // On compte le nombre d'objets ajoutés (les duplicatas ne sont pas comptés)
            let nb = 0;
            for (let idx = 0; idx < quest.objects.length; idx++) {
                // On ajoute l'objet en vérifiant qu'il n'existe pas déjà dans l'inventaire 
                if (this.warrior.addItem(quest.objects[idx]) === "Item added") {
                    nb++;
                }
            }
            if (bool) {
                // On affiche dans la console le nombre d'objets ajoutés
                console.log(`Vous avez ${nb < 2 ? 'un nouvel objet' : `${nb} nouveaux objets`} dans votre inventaire. Tapez 'inventaire()' pour les lister.`)

            }

            // On compte le nombre d'indices ajoutés (les duplicatas ne sont pas comptés)
            nb = 0;
            for (let idx = 0; idx < quest.clues.length; idx++) {
                if (this.warrior.addClue(quest.clues[idx]) !== null) {
                    nb++;
                }
            }
            // Si une quête a été ajoutée dans le journal alors on affiche une phrase dans la console
            if (nb > 0) {
                if(bool) {
                    console.log("Votre journal de quêtes a été mis à jour. Pour le voir, tapez 'journal()'.");
                }
                
            }
        }

        this.synchronizeWarriors();
    }

    /**
     * Synchronise la variable warrior de l'objet avec la variable globale window.warrior
     */
    synchronizeWarriors() {
        // on vérifie si la variable warrior existe
        if (typeof window.warrior === "undefined") {
            // on regarde si on l'a déjà créé, si oui on sermonne l'utilisateur qui l'a supprimé
            if (this.created) {
                console.log("C'est pas très malin d'avoir supprimé ton personnage...");
                setTimeout(() => { console.log("...") }, 1000);
                setTimeout(() => {
                    console.log("Bon allez tiens je t'en refais un nouveau. Mais attention, la prochaine fois il perdra tous ses objets !")
                    window.warrior = new Warrior();
                }, 2000)
            }
            // sinon on instancie la variable à celle du manager
            else {
                window.warrior = this.warrior;
            }
        }
    }
}

// on exporte une instance de WarriorManager
const manager = new WarriorManager();
export default manager;