import React from 'react';

export default function FaceSVG(){
    return(
        <div className="logotech">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" 
                x="0px" y="0px" width="40%" height="40%" viewBox="0 0 581.4 581.4" 
                enableBackground="new 0 0 581.4 581.4" xmlSpace="preserve">
                <g>
                    <path d="M549.3,244.7l-47.699-17.5c-5.4-18-13.2-34.9-22.801-50.6l7.801-16.8c8.6-18.6,4.699-40.7-9.801-55.2
                        s-36.6-18.4-55.199-9.8l-16.801,7.8c-15.699-9.6-32.6-17.4-50.6-22.8l-17.5-47.7c-7.1-19.3-25.4-32.1-46-32.1
                        c-20.5,0-38.9,12.8-46,32.1l-17.5,47.7c-18,5.4-34.9,13.2-50.6,22.8l-16.8-7.8c-18.7-8.6-40.7-4.7-55.2,9.8s-18.4,36.6-9.8,55.2
                        l7.8,16.8c-9.6,15.7-17.4,32.6-22.8,50.6l-47.7,17.5C12.8,251.8,0,270.1,0,290.7c0,20.5,12.8,38.9,32.1,46l47.7,17.5
                        c5.4,18,13.2,34.9,22.8,50.6l-7.8,16.801c-8.6,18.6-4.7,40.699,9.8,55.199c9.4,9.4,21.9,14.301,34.6,14.301c7,0,14-1.5,20.6-4.5
                        l16.8-7.801c15.7,9.601,32.6,17.4,50.6,22.801l17.5,47.699c7.1,19.301,25.4,32.101,46,32.101c20.5,0,38.9-12.8,46-32.101
                        l17.5-47.699c18-5.4,34.9-13.2,50.6-22.801l16.801,7.801c6.6,3,13.6,4.5,20.6,4.5c12.7,0,25.2-5,34.6-14.301
                        c14.5-14.5,18.4-36.6,9.801-55.199L478.8,404.8c9.601-15.699,17.4-32.6,22.801-50.6l47.699-17.5c19.301-7.1,32.101-25.4,32.101-46
                        C581.4,270.2,568.601,251.8,549.3,244.7z M283.5,345.7c-18.2,21-42.5,32.6-68.3,32.6s-50-11.6-68.3-32.6
                        c-37.6-43.4-37.6-113.9,0-157.2c2.2-2.5,5.8-2.5,7.9,0l128.6,148.101c1.1,1.199,1.7,2.899,1.7,4.6
                        C285.2,342.9,284.5,344.5,283.5,345.7z M434.5,345.7c-18.2,21-42.5,32.6-68.3,32.6s-50-11.6-68.3-32.6c-1.101-1.2-1.7-2.9-1.7-4.6
                        c0-1.7,0.6-3.4,1.7-4.601l128.6-148.1c2.2-2.5,5.8-2.5,8,0C472.101,231.9,472.101,302.4,434.5,345.7z"/>
                </g>
            </svg>
        </div>
    );
}
