import React from 'react';

export default function OctoprintSvg(){
	return(
		<div className="logotech">
            <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                viewBox="0 0 213.563 213.563" enableBackground="new 0 0 213.563 213.563" xmlSpace="preserve"
                height="40%" width="40%">
                <g>
                    <path d="M158.339,68.332l-38.012,38.013c-1.654-3.222-3.822-6.247-6.518-8.942c-2.664-2.664-5.678-4.848-8.933-6.526l38.011-38.011
                        c2.86,2.232,5.615,4.632,8.221,7.238C153.701,62.696,156.103,65.454,158.339,68.332z M73.816,137.395
                        c3.925,3.925,9.143,6.086,14.693,6.086c5.55,0,10.768-2.162,14.692-6.086c8.102-8.102,8.102-21.284,0-29.386
                        c-3.924-3.924-9.143-6.086-14.692-6.086c-5.551,0-10.77,2.162-14.694,6.086C65.715,116.111,65.715,129.294,73.816,137.395z
                        M210.116,114.561c-0.008-0.007-0.077-0.077-0.084-0.084c-2.236-2.236-5.21-3.467-8.372-3.467c-3.163,0-6.137,1.232-8.372,3.468
                        l-9.503,9.503l-0.132-0.005l24.608-57.434c1.208-2.818,0.578-6.088-1.59-8.257l-8.536-8.536l-73.886,73.887
                        c-0.229,8.848-3.702,17.628-10.44,24.366c-6.759,6.758-15.743,10.48-25.3,10.48c-9.558,0-18.542-3.722-25.301-10.48
                        c-13.949-13.95-13.949-36.648,0-50.599c6.542-6.541,15.17-10.227,24.384-10.457l73.869-73.869l-8.536-8.536
                        c-2.167-2.167-5.435-2.797-8.257-1.59L62.994,37.946c-7.599,2.284-14.888,5.58-21.663,9.855c-1.929,1.217-3.198,3.244-3.452,5.51
                        c-0.2,1.794,0.254,3.583,1.252,5.05L24.168,73.324c-1.466-0.997-3.257-1.451-5.049-1.251c-2.267,0.253-4.294,1.523-5.511,3.451
                        C3.064,92.233-1.597,112.311,0.485,132.059c2.112,20.051,11.143,38.959,25.425,53.242c16.712,16.711,38.932,25.915,62.568,25.916
                        c0.002,0,0.001,0,0.003,0c16.769,0,33.094-4.708,47.207-13.613c1.929-1.217,3.198-3.245,3.452-5.511
                        c0.2-1.793-0.255-3.582-1.252-5.049l14.962-14.963c1.467,0.998,3.254,1.455,5.051,1.252c2.266-0.253,4.293-1.523,5.51-3.452
                        c3.652-5.788,6.583-11.985,8.784-18.429l13.854,0.497c0.091,0.003,0.183,0.005,0.273,0.005c2.092,0,3.991-0.868,5.357-2.266
                        c0.041-0.039,0.085-0.073,0.125-0.113l18.29-18.291c2.236-2.236,3.469-5.21,3.469-8.373S212.33,116.775,210.116,114.561z"/>
                </g>
            </svg>
		</div>
	);
}
