import React from 'react';

const PpsSvg = () => (

   <div className="logotech">
      <svg
         width="40%"
         viewBox="0 -3 16 16"
         version="1.1"
         id="svg5">
      <defs
         id="defs2" />
      <g
         id="layer1"
         transform="translate(-126.56055,-32.3753)">
         <g
            aria-label="PPS"
            id="text113">
            <path
               d="m 126.73695,32.926517 0.4961,-0.132292 q 0,0.352778 0.011,0.396875 0.62839,-0.418924 1.76389,-0.418924 1.2237,0 1.83004,0.771702 0.60634,0.760678 0.60634,2.138718 0,1.223699 -0.59532,2.028474 -0.59531,0.804775 -1.79696,0.804775 -0.19844,0 -1.5875,-0.132291 0.022,0.804775 0.0882,2.502519 -0.0992,0.341754 -0.38585,0.341754 -0.19844,0 -0.38585,-0.165364 -0.17639,-0.154341 -0.22049,-0.385852 0.0331,-0.926042 0.0662,-3.604951 0.0441,-2.678909 0.11024,-4.145143 z m 0.60634,1.003213 0.0882,3.439586 q 0.60634,0.33073 1.40009,0.33073 0.76068,0 1.28985,-0.573265 0.52916,-0.573264 0.52916,-1.400088 0,-0.892969 -0.55121,-1.60955 -0.55122,-0.716581 -1.37804,-0.716581 -0.67248,0 -1.37804,0.529168 z"
               id="path297" />
            <path
               d="m 132.52472,32.926517 0.49609,-0.132292 q 0,0.352778 0.011,0.396875 0.62838,-0.418924 1.76389,-0.418924 1.2237,0 1.83003,0.771702 0.60634,0.760678 0.60634,2.138718 0,1.223699 -0.59531,2.028474 -0.59531,0.804775 -1.79696,0.804775 -0.19844,0 -1.58751,-0.132291 0.0221,0.804775 0.0882,2.502519 -0.0992,0.341754 -0.38585,0.341754 -0.19844,0 -0.38585,-0.165364 -0.17639,-0.154341 -0.22049,-0.385852 0.0331,-0.926042 0.0661,-3.604951 0.0441,-2.678909 0.11024,-4.145143 z m 0.60634,1.003213 0.0882,3.439586 q 0.60634,0.33073 1.40009,0.33073 0.76068,0 1.28984,-0.573265 0.52917,-0.573264 0.52917,-1.400088 0,-0.892969 -0.55121,-1.60955 -0.55122,-0.716581 -1.37804,-0.716581 -0.67249,0 -1.37804,0.529168 z"
               id="path299" />
            <path
               d="m 137.99278,40.21359 0.15434,-0.617362 q 0.95912,0.518143 1.88516,0.518143 0.66146,0 1.12448,-0.264583 0.47404,-0.275608 0.47404,-0.881946 0,-0.540191 -0.39687,-0.981164 -0.39688,-0.451997 -0.95912,-0.793751 -0.56224,-0.341754 -1.12448,-0.694532 -0.56224,-0.363802 -0.95911,-0.881945 -0.39688,-0.529167 -0.39688,-1.168578 0,-0.782726 0.56224,-1.422136 0.56224,-0.650435 1.35599,-0.650435 0.60634,0 1.34497,0.242535 0.74965,0.242535 1.25677,0.48507 l -0.13229,0.440972 q -1.25677,-0.429948 -2.11667,-0.429948 -0.52917,0 -0.90399,0.341754 -0.37483,0.341754 -0.37483,0.881945 0,0.4079 0.20946,0.738629 0.20946,0.33073 0.54019,0.573265 0.34176,0.242535 0.74966,0.474046 0.41892,0.23151 0.82682,0.496094 0.4079,0.264583 0.73863,0.573264 0.34175,0.308681 0.55122,0.782727 0.20946,0.463021 0.20946,1.04731 0,0.826824 -0.52917,1.355991 -0.52917,0.518143 -1.37804,0.518143 -0.7276,0 -1.55443,-0.198438 -0.8158,-0.209462 -1.15755,-0.48507 z"
               id="path301" />
         </g>
      </g>
      </svg>
   </div>
);

export default PpsSvg;