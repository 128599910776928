export const competencesList = [
    {
        name: "Créatif(ve)",
        value: "creative"
    }, {
        name: "Fort(e) en algo",
        value: "algo"
    }, {
        name: "Multi-techno",
        value: "multitech"
    }, {
        name: "Curieux(se)",
        value: "curious"
    }, {
        name: "Auto-didacte",
        value: "autodidact"
    }, {
        name: "Fiable",
        value: "reliable"
    }, {
        name: "Passionné(e)",
        value: "passionate"
    }, {
        name: "Workaholic",
        value: "Workaholic"
    }, {
        name: "Fainéant(e)",
        value: "lazy"
    }, {
        name: "Thé",
        value: "tea"
    }, {
        name: "Café",
        value: "coffee"
    }, {
        name: "Chocolat",
        value: "chocolate"
    }, {
        name: "Opportuniste",
        value: "opportunist"
    }, {
        name: "Sans gluten",
        value: "glutenfree"
    }, {
        name: "Exceptionnel(le)",
        value: "exception"
    }, {
        name: "Moustachu(e)",
        value: "mustach"
    }, {
        name: "Cravate",
        value: "tie"
    }, {
        name: "Noeud papillon",
        value: "bowtie"
    }, {
        name: "Lamborghini",
        value: "car"
    }, {
        name: "Vélo",
        value: "bike"
    }, {
        name: "Métro",
        value: "underground"
    }, {
        name: "Trottinette électrique",
        value: "scooter"
    }, {
        name: "Sérieux(se)",
        value: "serious"
    }, {
        name: "Intelligent(e)",
        value: "brainy"
    }, {
        name: "Brillant(e)",
        value: "shiny"
    }, {
        name: "Modeste",
        value: "modest"
    }, {
        name: "Dôté(e) de bonhomie",
        value: "happyfeeling"
    }, {
        name: "Débrouillard(e)",
        value: "resourceful"
    }, {
        name: "Ouvert(e) d'esprit",
        value: "openminded"
    }, {
        name: "Drôle",
        value: "lustig"
    }, {
        name: "Sarcastique",
        value: "sarcasm"
    }, {
        name: "Enigmatique",
        value: "enigma"
    }]