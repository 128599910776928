import React, { useState } from 'react';

const AtelierSvg = () => {
  
  const [active, setActive] = useState(false);

  return(
    <div className="logotech">
      <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="40%" viewBox="0 0 300 300" xmlSpace="preserve"
        onMouseEnter={() => setActive(true)}
        onMouseLeave={() => setActive(false)}
        >
        <path d="M58.39,45.751c0,1.644-1.333,2.977-2.977,2.977c-1.647,0-2.98-1.333-2.98-2.977c0-1.647,1.333-2.98,2.98-2.98
        C57.057,42.771,58.39,44.104,58.39,45.751z"/>
        <path d="M245.265,45.751c0,1.644-1.333,2.977-2.978,2.977c-1.646,0-2.979-1.333-2.979-2.977c0-1.647,1.333-2.98,2.979-2.98
        C243.932,42.771,245.265,44.104,245.265,45.751z"/>
        <path d="M152.208,45.751c0,1.644-1.333,2.977-2.977,2.977c-1.647,0-2.98-1.333-2.98-2.977c0-1.647,1.333-2.98,2.98-2.98
        C150.875,42.771,152.208,44.104,152.208,45.751z"/>
        <path d="M296.668,123H4.168c-1.795,0-3.25,1.205-3.25,3s1.455,3,3.25,3h48.323c-1.047,0-1.785,0.545-1.785,1.805
        c0,1.795,1.455,2.195,3.25,2.195h11.462L24.741,267.654c-0.521,1.721,0.449,3.818,2.166,4.339c1.731,0.524,3.536-0.779,4.055-2.5
        L35.812,253h68.834l4.886,16.592c0.425,1.402,1.715,2.567,3.109,2.567c0.312,0,0.63,0.085,0.946-0.01
        c1.717-0.521,2.687-2.841,2.166-4.558L75.076,133h11.465c1.795,0,3.25-0.4,3.25-2.195c0-1.26-0.738-1.805-1.785-1.805h129.361
        c-1.048,0-1.784,0.545-1.784,1.805c0,1.795,1.454,2.195,3.25,2.195h11.464l-40.676,134.654c-0.521,1.721,0.451,3.818,2.168,4.339
        c1.729,0.527,3.532-0.786,4.053-2.5l4.85-16.493h68.834l4.888,16.592c0.425,1.402,1.714,2.567,3.11,2.567
        c0.311,0,0.629,0.085,0.942-0.01c1.718-0.521,2.688-2.841,2.168-4.558L239.958,133h11.461c1.796,0,3.25-0.4,3.25-2.195
        c0-1.26-0.736-1.805-1.784-1.805h43.783c1.797,0,3.25-1.205,3.25-3S298.465,123,296.668,123z M54.849,191.245l8.663,4.777
        l-12.143,6.69L54.849,191.245z M102.674,246H37.785l10.877-35.102l21.585-11.528l21.558,11.333L102.674,246z M68.873,196.022
        c0-0.762,0.615-1.375,1.374-1.375s1.374,0.613,1.374,1.375c0,0.758-0.615,1.374-1.374,1.374S68.873,196.78,68.873,196.022z
        M89.103,202.7l-12.121-6.678l8.649-4.768L89.103,202.7z M83.703,184.895l-13.456,7.414l-13.467-7.42l13.467-44.363L83.703,184.895z
        M219.731,191.245l8.661,4.777l-12.143,6.69L219.731,191.245z M267.555,246h-64.889l10.876-35.102l21.585-11.528l21.557,11.333
        L267.555,246z M233.643,196.022c0-0.762,0.615-1.375,1.374-1.375s1.374,0.613,1.374,1.375c0,0.758-0.615,1.374-1.374,1.374
        S233.643,196.78,233.643,196.022z M253.983,202.7l-12.121-6.678l8.648-4.768L253.983,202.7z M248.584,184.895l-13.457,7.414
        l-13.466-7.42l13.466-44.363L248.584,184.895z"/>
        <g>
          <rect x="97" y="55" width="6" height="7"/>
          <polygon points="94,55 85.412,55 85.374,55.148 84.371,57.348 82.885,57.824 83.866,58.463 83.542,59.163 81.971,59.525 
          83.146,60.016 82.752,60.857 81.159,60.839 82.216,61 94,61 	"/>
          <rect x="106" y="55" width="6" height="7"/>
          <rect x="125" y="55" width="5" height="7"/>
          <rect x="116" y="55" width="5" height="7"/>
        </g>
        <g>
          <polygon points="220.61,55 201.291,55 201.793,61 219.833,61 	"/>
          <polygon points="195.718,61 195.286,55 174.847,55 175.545,61 	"/>
          <polygon points="133,61 168.829,61 168.227,55 133,55 	"/>
          <path d="M251.165,29H47.291C41.914,29,39,34.144,39,39.521v12.458C39,57.354,41.914,61,47.291,61h13.503l0.988-6H47.291
          C45.499,55,45,53.77,45,51.978V39.521C45,37.729,45.499,35,47.291,35h203.874c1.793,0,1.835,2.729,1.835,4.521v12.458
          c0,1.792-0.042,3.022-1.835,3.022h-12.781l1.161,6h11.62c5.376,0,8.835-3.646,8.835-9.022V39.521C260,34.144,256.541,29,251.165,29
          z"/>
          <polygon points="231.607,55 227.713,55 227.088,61 232.62,61 	"/>
        </g>
        <g className={ active ? "saw sawmove":"saw" }>
          <path d="M65.626,41.295l-1.184,6.24c-0.372,1.971,1.034,3.891,3.148,4.294l15.021,2.854c2.11,0.4,4.12-0.87,4.497-2.841
          l1.184-6.243c0.375-1.968-1.035-3.891-3.145-4.291l-15.022-2.854C68.016,38.051,66.004,39.324,65.626,41.295z M82.704,43.456
          c1.556,0.302,2.631,1.53,2.399,2.758l-0.371,1.942c-0.231,1.228-1.682,1.98-3.24,1.685l-11.09-2.107
          c-1.562-0.295-2.634-1.53-2.399-2.755l0.368-1.945c0.232-1.225,1.683-1.98,3.244-1.682L82.704,43.456z"/>
          <path d="M82.898,58.456L80.851,57.7l2.78-0.961l-2.05-0.752l-2.841-0.612c-0.819-0.216-1.949-0.454-3.498-0.781l-5.735-1.222
          c-5.589-1.19-5.596-1.146-5.885,0.664l-9.07,52.025c-0.08,0.489,2.196,0.847,3.38,1.098c0,0,0.108,0.028,0.27,0.032
          c0.312,0.038,0.791,0.089,0.896,0.073c1.012-0.178,2.923-1.038,2.923-1.038l-2.016-0.746c0.003-0.009,0.007-0.019,0.01-0.025
          l2.739-0.946l-1.99-0.736c0.003-0.016,0.013-0.032,0.019-0.047l2.701-0.937l-1.974-0.727c0.009-0.019,0.019-0.042,0.028-0.063
          l2.679-0.93l-1.965-0.72c0.013-0.022,0.022-0.048,0.032-0.073l2.659-0.92l-1.948-0.717c0.013-0.025,0.025-0.051,0.035-0.083
          l2.65-0.914l-1.945-0.717c0.013-0.032,0.028-0.06,0.041-0.089l2.635-0.911l-1.937-0.714c0.013-0.035,0.029-0.063,0.045-0.098
          l2.621-0.908l-1.93-0.708c0.013-0.035,0.029-0.066,0.048-0.104l2.612-0.905l-1.92-0.708c0.016-0.038,0.032-0.07,0.044-0.108
          l2.609-0.901l-1.92-0.705c0.02-0.038,0.032-0.073,0.048-0.114l2.6-0.898l-1.911-0.705c0.02-0.038,0.035-0.076,0.051-0.114
          l2.593-0.898l-1.904-0.702c0.016-0.041,0.032-0.083,0.051-0.121l2.586-0.895l-1.907-0.698c0.019-0.041,0.038-0.085,0.057-0.127
          l2.577-0.889l-1.898-0.705c0.019-0.041,0.038-0.083,0.054-0.127l2.577-0.889l-1.897-0.698c0.022-0.044,0.035-0.085,0.057-0.127
          l2.571-0.889l-1.892-0.698c0.016-0.047,0.035-0.089,0.054-0.13l2.574-0.895l-1.895-0.695c0.019-0.041,0.038-0.085,0.057-0.13
          l0.444-0.156l0.717,0.207l1.809-0.625l-1.885-0.695c0.019-0.048,0.038-0.092,0.06-0.137l2.559-0.885l-1.886-0.695
          c0.02-0.047,0.038-0.092,0.058-0.136l2.558-0.886l-1.885-0.695c0.022-0.048,0.041-0.092,0.06-0.14l2.558-0.886L75.17,71.5
          c0.019-0.044,0.038-0.092,0.057-0.14l2.552-0.882l-1.875-0.692c0.019-0.047,0.038-0.098,0.06-0.146l2.549-0.882l-1.879-0.686
          c0.019-0.054,0.041-0.098,0.061-0.146l2.552-0.882l-1.879-0.695c0.019-0.044,0.041-0.092,0.063-0.143l2.542-0.879l-1.876-0.692
          c0.02-0.048,0.042-0.099,0.061-0.143l2.549-0.882l-1.879-0.692c0.022-0.051,0.041-0.098,0.063-0.146l2.548-0.879l-1.879-0.692
          c0.022-0.051,0.042-0.099,0.06-0.146l2.546-0.882l-1.873-0.692c0.022-0.057,0.045-0.108,0.058-0.143L82.898,58.456z"/>
        </g>
        <g>
          <polygon points="95.149,76.4 94.076,76.404 95.155,81.114 96.377,81.11 97.443,76.4 96.371,76.404 96.469,53.832 95.247,53.834 	
          "/>
          <path d="M94.358,53.028h0.863l1.226-0.003h0.885c0.581-0.003,1.054-0.479,1.054-1.07l0.051-14.079
          c0.003-0.587-0.466-1.066-1.047-1.063l-2.977-0.003c-0.578,0.003-1.05,0.486-1.054,1.076l-0.051,14.076
          C93.305,52.552,93.778,53.028,94.358,53.028z M97.062,37.756c0.007-0.206,0.172-0.384,0.381-0.381
          c0.207-0.003,0.381,0.165,0.375,0.381l-0.048,13.578c-0.003,0.216-0.171,0.381-0.381,0.381c-0.206,0.003-0.375-0.168-0.378-0.384
          M95.441,37.759c0.006-0.209,0.171-0.387,0.381-0.384c0.203-0.003,0.377,0.168,0.375,0.381l-0.028,7.687l-0.02,5.891
          c0,0.216-0.171,0.381-0.381,0.384c-0.209,0-0.381-0.175-0.381-0.384l0.025-5.887 M93.816,37.756
          c0.003-0.206,0.168-0.381,0.375-0.381c0.209-0.003,0.378,0.171,0.381,0.384l-0.051,13.574c-0.003,0.213-0.168,0.387-0.378,0.387
          c-0.209,0-0.381-0.171-0.374-0.387L93.816,37.756z"/>
        </g>
        <g>
          <path d="M105.397,53.536c-0.045-0.07-0.099-0.111-0.156-0.111l-0.651,0.003c-0.12,0-0.212,0.168-0.212,0.378l-0.054,20.893
          c0,0.038,0.003,0.073,0.01,0.111l0.346,1.289c0.032,0.159,0.118,0.273,0.21,0.273c0.003,0,0.006,0,0.006,0
          c0.096-0.003,0.181-0.121,0.207-0.289l0.301-1.374l0.058-20.903C105.461,53.701,105.436,53.606,105.397,53.536z"/>
          <path d="M103.379,53.028h0.863l1.225-0.003h0.886c0.581-0.003,1.054-0.479,1.054-1.07l0.051-14.079
          c0.003-0.587-0.467-1.066-1.047-1.063l-2.977-0.003c-0.578,0.003-1.051,0.486-1.054,1.076l-0.051,14.076
          C102.325,52.552,102.798,53.028,103.379,53.028z M106.083,37.756c0.006-0.206,0.171-0.384,0.381-0.381
          c0.206-0.003,0.381,0.165,0.374,0.381l-0.047,13.578c-0.003,0.216-0.171,0.381-0.381,0.381c-0.207,0.003-0.375-0.168-0.377-0.384
          M104.461,37.759c0.006-0.209,0.171-0.387,0.381-0.384c0.203-0.003,0.378,0.168,0.375,0.381l-0.029,7.687l-0.019,5.891
          c0,0.216-0.172,0.381-0.381,0.384c-0.21,0-0.381-0.175-0.381-0.384l0.025-5.887 M102.836,37.756
          c0.003-0.206,0.168-0.381,0.375-0.381c0.209-0.003,0.377,0.171,0.381,0.384l-0.051,13.574c-0.003,0.213-0.168,0.387-0.377,0.387
          c-0.21,0-0.381-0.171-0.375-0.387L102.836,37.756z"/>
        </g>
        <path d="M214.738,73.63c-0.231,2.583,0.852,4.977,2.691,6.535l0.2-2.193l0.419-5.078l4.615-2.181l4.186,2.907l-0.418,5.078
        l-0.197,2.234c2.076-1.212,3.555-3.371,3.783-5.944c0.295-3.33-1.59-6.341-4.469-7.652c0.016-0.066,0.035-0.133,0.041-0.2
        l2.45-27.536c0.124-1.409-0.917-2.66-2.326-2.784c-1.412-0.127-2.659,0.92-2.783,2.33l-2.45,27.536
        c-0.007,0.067,0,0.133,0.003,0.203C217.418,67.663,215.034,70.3,214.738,73.63z M225.612,37.937
        c0.489,0.041,0.851,0.473,0.806,0.961c-0.044,0.482-0.476,0.844-0.961,0.803c-0.486-0.044-0.848-0.479-0.803-0.962
        C224.698,38.251,225.13,37.893,225.612,37.937z"/>
        <g className={ active ? "axe axemove":"axe" }>
          <g>
            <path d="M235.372,86.887c-0.067-0.438-0.47-0.743-0.911-0.679l-5.018,0.768c-0.441,0.07-0.736,0.476-0.67,0.914l2.098,13.781
            c0.066,0.432,0.477,0.736,0.914,0.664l5.021-0.765c0.438-0.063,0.736-0.473,0.666-0.908L235.372,86.887z"/>
            <path d="M259.306,100.217l-13.429-0.286l-2.304-15.107l12.769-4.062c0,0,2.621,3.926,3.462,9.426
            C260.553,95.126,259.306,100.217,259.306,100.217z"/>
          </g>
          <path d="M233.001,36.693l-1.59,0.244c-0.876,0.133-1.476,0.952-1.343,1.825l9.486,63.127c0.134,0.876,0.949,1.479,1.828,1.342
          l1.581-0.238c0.879-0.137,1.485-0.952,1.352-1.832l-9.489-63.124C234.692,37.166,233.877,36.556,233.001,36.693z M232.671,40.301
          c-0.498,0.076-0.962-0.267-1.038-0.762c-0.076-0.498,0.264-0.965,0.766-1.041c0.498-0.073,0.961,0.267,1.037,0.765
          C233.509,39.762,233.169,40.225,232.671,40.301z"/>
        </g>
        <g>
          <path d="M201.707,76.366c-1.638,0.857-7.211,0.521-10.842-2.228c-1.168-0.882-2.031-1.917-2.58-3.11
          c1.508,0.936,4.513,2.266,7.729,0.733c0.187-0.092,0.323-0.263,0.361-0.466l0.641-3.466l4.316-0.413
          c0.261,0.555,0.787,1.479,1.736,2.345c0.108,0.092,0.207,0.175,0.312,0.251c0.752,0.574,1.476,0.705,2.037,0.66l0.822,4.005
          C205.332,74.88,203.631,75.356,201.707,76.366z"/>
          <polygon points="205.976,69.415 207.477,75.617 210.863,75.061 209.223,68.768 	"/>
          <path d="M199.025,37.943c-0.045-0.673-0.625-1.181-1.302-1.13l-1.577,0.108c-0.676,0.051-1.181,0.635-1.133,1.301l2.139,28.209
          l3.951-0.336L199.025,37.943z M197.207,40.365c-0.505,0.042-0.939-0.339-0.975-0.841c-0.038-0.499,0.336-0.937,0.841-0.971
          c0.501-0.038,0.937,0.339,0.975,0.838C198.082,39.892,197.708,40.33,197.207,40.365z"/>
        </g>
        <g>
          <polygon points="113.303,65.419 112.23,65.422 113.31,70.132 114.531,70.129 115.598,65.419 114.525,65.422 114.624,53.739 
          113.401,53.743 	"/>
          <path d="M112.513,52.937h0.863l1.226-0.003h0.885c0.581-0.003,1.054-0.479,1.054-1.069l0.051-14.079
          c0.003-0.587-0.466-1.066-1.047-1.063l-2.977-0.003c-0.578,0.003-1.05,0.485-1.054,1.076l-0.051,14.076
          C111.459,52.46,111.932,52.937,112.513,52.937z M115.217,37.664c0.007-0.207,0.172-0.384,0.381-0.381
          c0.207-0.003,0.381,0.165,0.375,0.381l-0.048,13.578c-0.003,0.216-0.171,0.381-0.381,0.381c-0.206,0.003-0.375-0.168-0.378-0.384
          M113.595,37.667c0.006-0.209,0.171-0.387,0.381-0.384c0.203-0.003,0.377,0.168,0.375,0.381l-0.028,7.687l-0.02,5.891
          c0,0.216-0.171,0.381-0.381,0.384c-0.209,0-0.381-0.174-0.381-0.384l0.025-5.888 M111.97,37.664
          c0.003-0.207,0.168-0.381,0.375-0.381c0.209-0.003,0.378,0.171,0.381,0.384l-0.051,13.575c-0.003,0.212-0.168,0.387-0.378,0.387
          c-0.209,0-0.381-0.171-0.374-0.387L111.97,37.664z"/>
        </g>
        <g>
          <polygon points="122.228,72.386 121.155,72.389 122.234,77.099 123.456,77.096 124.522,72.386 123.45,72.389 123.548,53.739 
          122.326,53.743 	"/>
          <path d="M121.438,52.937h0.863l1.226-0.003h0.885c0.581-0.003,1.054-0.479,1.054-1.069l0.051-14.079
          c0.003-0.587-0.466-1.066-1.047-1.063l-2.977-0.003c-0.578,0.003-1.05,0.485-1.054,1.076l-0.051,14.076
          C120.384,52.46,120.857,52.937,121.438,52.937z M124.142,37.664c0.007-0.207,0.172-0.384,0.381-0.381
          c0.207-0.003,0.381,0.165,0.375,0.381l-0.048,13.578c-0.003,0.216-0.171,0.381-0.381,0.381c-0.206,0.003-0.375-0.168-0.378-0.384
          M122.52,37.667c0.006-0.209,0.171-0.387,0.381-0.384c0.203-0.003,0.377,0.168,0.375,0.381l-0.028,7.687l-0.02,5.891
          c0,0.216-0.171,0.381-0.381,0.384c-0.209,0-0.381-0.174-0.381-0.384l0.025-5.888 M120.895,37.664
          c0.003-0.207,0.168-0.381,0.375-0.381c0.209-0.003,0.378,0.171,0.381,0.384L121.6,51.242c-0.003,0.212-0.168,0.387-0.378,0.387
          c-0.209,0-0.381-0.171-0.374-0.387L120.895,37.664z"/>
        </g>
        <g>
          <path d="M132.083,53.536c-0.045-0.07-0.099-0.111-0.156-0.111l-0.651,0.003c-0.12,0-0.212,0.168-0.212,0.378l-0.054,15.32
          c0,0.038,0.003,0.073,0.01,0.111l0.346,0.622c0.032,0.159,0.118,0.273,0.21,0.273c0.003,0,0.006,0,0.006,0
          c0.096-0.003,0.181-0.121,0.207-0.289l0.301-0.708l0.058-15.33C132.146,53.701,132.121,53.606,132.083,53.536z"/>
          <path d="M130.064,53.028h0.863l1.225-0.003h0.886c0.581-0.003,1.054-0.479,1.054-1.07l0.051-14.079
          c0.003-0.587-0.467-1.066-1.047-1.063l-2.977-0.003c-0.578,0.003-1.051,0.486-1.054,1.076l-0.051,14.076
          C129.011,52.552,129.483,53.028,130.064,53.028z M132.769,37.756c0.006-0.206,0.171-0.384,0.381-0.381
          c0.206-0.003,0.381,0.165,0.374,0.381l-0.047,13.578c-0.003,0.216-0.171,0.381-0.381,0.381c-0.207,0.003-0.375-0.168-0.377-0.384
          M131.146,37.759c0.006-0.209,0.171-0.387,0.381-0.384c0.203-0.003,0.378,0.168,0.375,0.381l-0.029,7.687l-0.019,5.891
          c0,0.216-0.172,0.381-0.381,0.384c-0.21,0-0.381-0.175-0.381-0.384l0.025-5.887 M129.521,37.756
          c0.003-0.206,0.168-0.381,0.375-0.381c0.209-0.003,0.377,0.171,0.381,0.384l-0.051,13.574c-0.003,0.213-0.168,0.387-0.377,0.387
          c-0.21,0-0.381-0.171-0.375-0.387L129.521,37.756z"/>
        </g>
        <g className={ active ? "wrench wrenchmove":"wrench" }>
          <g>
            <polygon points="168.242,71.437 179.328,70.158 179.325,70.151 		"/>
            <path d="M176.354,82.758c2.862-0.635,4.434-3.926,4.631-6.379c0.158-2.016-0.34-3.926-1.308-5.539l-4.637,0.59L176.354,82.758z"/>
            <path d="M169.537,82.599l-1.295-11.163l11.083-1.285l0.003,0.006l3.019-0.349c-0.526-2.682-2.555-3.996-5.681-4.247
            c-4.663-0.372-10.201,0.508-11.229,4.935c-0.104,0.448-0.355,2.888-0.396,3.364C164.751,77.508,166.315,82.748,169.537,82.599z"/>
          </g>
          <path d="M170.02,67.815c-0.048-0.435,0.26-0.835,0.698-0.892l4.599-0.559c0.438-0.051,0.832,0.26,0.889,0.698l0.193,1.631
          c0.058,0.435-0.254,0.832-0.688,0.882l-4.604,0.562c-0.436,0.051-0.829-0.254-0.883-0.695L170.02,67.815z"/>
          <polygon points="170.489,67.485 175.529,66.901 175.773,69.018 170.733,69.608 	"/>
          <g>
            <polygon points="175.244,69.488 175.646,69.443 175.291,66.673 174.885,66.714 		"/>
            <polygon points="174.237,69.608 174.641,69.561 174.282,66.781 173.882,66.828 		"/>
            <polygon points="173.19,69.729 173.597,69.685 173.234,66.892 172.835,66.939 		"/>
            <polygon points="172.175,69.853 172.574,69.799 172.216,67.003 171.807,67.05 		"/>
            <polygon points="171.108,69.97 171.515,69.926 171.152,67.12 170.743,67.162 		"/>
          </g>
          <path d="M171.146,65.203c0,0,0.876-0.209,1.485-0.27c0.618-0.061,1.504-0.025,1.504-0.025c0.546-0.057,0.946-0.546,0.889-1.085
          l-2.605-26.121c-0.058-0.543-0.54-0.943-1.089-0.886l-2.983,0.295c-0.552,0.057-0.945,0.542-0.892,1.082l2.612,26.121
          C170.118,64.86,170.604,65.26,171.146,65.203z M169.972,38.289c0.562-0.06,1.066,0.355,1.124,0.92
          c0.057,0.565-0.355,1.066-0.921,1.124c-0.564,0.057-1.066-0.355-1.126-0.923C168.994,38.848,169.404,38.343,169.972,38.289z"/>
        </g>
      </svg>
    </div>
  );
}

export default AtelierSvg;