import React from 'react';

const OcarinaSvg = () => (
	<div className="logotech">								 
		<svg viewBox="0 0 24 24" width="40%" xmlns="http://www.w3.org/2000/svg">
			<path d="m20 12c-3.2-1.6-6.6-2.3-9.2-2.7l-1.1-3.4c-.4-1.1-2-1.1-2.4 0l-1 3.1c-2.4.4-4.3 2.5-4.3 5
				0 2.8 2.2 5 5 5 0 0 7 0 13-3 0 0 2-1 2-2s-2-2-2-2m-15 2c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1c0
				.5-.4 1-1 1m2 3c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1c0 .5-.4 1-1 1m2-3c-.6 0-1-.4-1-1s.4-1 1-1 1
				.4 1 1c0 .5-.4 1-1 1m2 3c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1c0 .5-.4 1-1 1m5-3c-.6 0-1-.4-1-1s.4-1 1-1 1
				.4 1 1c0 .5-.4 1-1 1m3 1c-.6 0-1-.4-1-1s.4-1 1-1 1 .4 1 1c0 .5-.4 1-1 1z"/>
		</svg>
			
	</div>
);

export default OcarinaSvg;