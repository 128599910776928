import React from 'react';

export default function PlasmaballSvg(){
    return(
    <div className="logotech">
        <svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
        width="40%" height="40%" viewBox="0 0 437.07 437.07" style={{enableBackground:'new 0 0 437.07 437.07'}} xmlSpace="preserve">
            <path d="M353.803,341.928c39.766-37.317,62.462-89.529,62.462-144.198C416.265,88.702,327.564,0,218.536,0
            C109.506,0,20.805,88.702,20.805,197.73c0,54.67,22.697,106.885,62.467,144.202L65.955,425.03c-0.613,2.947,0.132,6.013,2.031,8.349
            c1.898,2.335,4.749,3.691,7.759,3.691h285.587c3.01,0,5.86-1.356,7.759-3.691c1.899-2.335,2.645-5.402,2.031-8.349L353.803,341.928z
            M40.805,197.73c0-9.951,0.835-19.708,2.415-29.218l30.312,38.843l-31.779,8.613C41.13,209.938,40.805,203.852,40.805,197.73z
            M70.159,100.009l49.26,51.942c0.647,0.682,1.388,1.27,2.198,1.746l37.194,21.804c-2.586,6.925-4.008,14.414-4.008,22.23
            c0,1.409,0.062,2.803,0.153,4.19l-59.469,1.062l-46.272-59.296C54.207,128.084,61.3,113.416,70.159,100.009z M311.52,46.327
            l-6.405,77.041l-42.92,28c-9.099-8.573-20.703-14.511-33.593-16.565l-0.06-23.243l44.318-83.057
            C286.541,32.904,299.502,38.918,311.52,46.327z M282.183,201.008l48.639-17.371l65.281,21.297
            c-1.289,31.716-11.052,62.27-27.919,88.618l-38.337-39.451c-0.916-0.942-2.009-1.695-3.216-2.215l-52.703-22.701
            C278.715,220.79,281.663,211.217,282.183,201.008z M354.809,170.425l34.887-20.614c3.065,10.935,5.112,22.29,6.023,33.96
            L354.809,170.425z M326.412,163.974l-46.508,16.61c-1.312-4.688-3.132-9.164-5.424-13.351l45.659-29.788
            c2.585-1.687,4.246-4.472,4.502-7.547l2.23-26.825l41.8-0.356c5.572,8.774,10.392,18.068,14.379,27.79L326.412,163.974z
            M241.536,44.726l-20.35-24.692c10.845,0.159,21.457,1.292,31.754,3.32L241.536,44.726z M231.35,63.813l-21.637,40.549
            c-0.777,1.457-1.182,3.083-1.178,4.733l0.066,25.682c-7.39,1.162-14.361,3.598-20.684,7.077l-17.586-65.382
            c-0.719-2.672-2.513-4.927-4.955-6.228l-42.156-22.463c21.661-13.817,46.501-23.073,73.156-26.402L231.35,63.813z M92.094,223.047
            l67.461-1.205c1.982,4.831,4.538,9.366,7.588,13.52l-58.464,11.91c-1.992,0.406-3.813,1.41-5.221,2.877l-37.342,38.934
            c-9.867-16.466-17.034-34.451-21.168-53.257L92.094,223.047z M181.821,221.444l21.033-0.375c5.521-0.099,9.918-4.655,9.819-10.177
            c-0.098-5.461-4.555-9.821-9.994-9.821c-0.061,0-0.122,0-0.183,0.001l-27.514,0.491c-0.11-1.264-0.178-2.54-0.178-3.832
            c0-4.12,0.585-8.104,1.655-11.885l3.158,1.852c1.59,0.932,3.33,1.375,5.048,1.375c3.433,0,6.775-1.769,8.637-4.944
            c2.793-4.765,1.194-10.891-3.569-13.684l-3.564-2.089c5.883-6.476,13.681-11.172,22.486-13.215l0.021,7.957
            c0.015,5.514,4.488,9.974,9.999,9.974c0.009,0,0.019,0,0.027,0c5.522-0.014,9.988-4.503,9.974-10.026l-0.02-7.85
            c15.905,3.784,28.467,16.272,32.352,32.139l-17.695,6.32c-5.201,1.857-7.912,7.58-6.054,12.781c1.46,4.09,5.31,6.64,9.417,6.64
            c1.116,0,2.252-0.188,3.363-0.585l10.857-3.877c-4.848,18.867-22.001,32.852-42.36,32.852
            C203.159,241.462,189.62,233.478,181.821,221.444z M218.536,261.462c1.118,0,2.229-0.03,3.333-0.087v66.815h-6.67v-66.815
            C216.304,261.431,217.416,261.462,218.536,261.462z M241.869,257.019c7.021-2.773,13.435-6.75,18.984-11.689l56.039,24.138
            l39.394,40.538c-5.229,6.417-10.929,12.497-17.075,18.184h-97.342V257.019z M354.022,82.842l-25.487,0.217l1.935-23.272
            C339.013,66.733,346.898,74.454,354.022,82.842z M105.264,60.875l46.881,24.98l18.728,69.631c-0.755,0.851-1.485,1.724-2.194,2.614
            l-35.723-20.941L82.291,83.737C89.243,75.442,96.932,67.784,105.264,60.875z M77.613,305.989l38.118-39.743l69.556-14.169
            c3.134,1.925,6.452,3.574,9.912,4.94v71.172H97.86C90.446,321.329,83.684,313.896,77.613,305.989z M88.043,417.07l14.354-68.881
            h232.28l14.354,68.881H88.043z"/>
        </svg>
    </div>
    );
}