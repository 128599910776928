import React, { useState } from 'react';

const WebdevSvg = () => {
   
    const [active, setActive] = useState(false);

    return(
        <div className="logotech">
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                                width="40%" viewBox="0 -25 300 300" enableBackground="new 0 -25 300 300" xmlSpace="preserve"
                                onMouseEnter={() => setActive(true)}
                                onMouseLeave={() => setActive(false)}
                                >
                <g>
                    <g>
                        <g>
                        <path fill="#231F20" d="M266.735,0.069H32.993c-18.206,0-32.964,14.746-32.964,32.938v131.738
                        c0,18.188,14.759,32.934,32.964,32.934h233.743c18.205,0,32.964-14.745,32.964-32.934V33.007
                        C299.699,14.814,284.94,0.069,266.735,0.069z M149.864,182.478c-4.796,0-8.687-3.889-8.687-8.689
                        c0-4.791,3.891-8.685,8.687-8.685c4.795,0,8.687,3.894,8.687,8.685C158.551,178.589,154.659,182.478,149.864,182.478z
                        M284.498,142.302c0,7.198-5.836,13.033-13.033,13.033H28.259c-7.196,0-13.029-5.835-13.029-13.033V29.388
                        c0-7.198,5.833-13.029,13.029-13.029h243.206c7.197,0,13.033,5.831,13.033,13.029V142.302z"/>
                        <rect x="86.89" y="248.862" fill="#231F20" width="125.945" height="4.11"/>
                        <path fill="#231F20" d="M185.693,235.01c-7.871-8.648-8.686-26.01-8.686-26.01h-27.144h-27.145c0,0-0.812,17.361-8.685,26.01
                        C104.41,245.58,86.89,246,86.89,246h62.974h62.971C212.835,246,195.315,245.58,185.693,235.01z"/>
                        </g>
                    </g>
                    <g className={ active ? "webdevtext webdevtextmove":"webdevtext" }>
                        <text x="112" y="110"
                                fontFamily="Helvetica"
                                fontSize="70"
                                fontWeight="bold"
                                fill="#231F20">
                            {`{ }`}
                        </text>
                    </g>
                </g>
            </svg>
        </div>
    );
}

export default WebdevSvg;