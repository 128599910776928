// Librairies
import React from "react";
// CSS
import "../css/JobItem.css";
// Icones
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBriefcase, faMapMarkerAlt, faCalendarDays } from '@fortawesome/free-solid-svg-icons';

/**
 * Template de la box présentant une offre d'emploi
 * Au clic, le PDF de l'offre s'ouvre dans un nouvel onglet
 */
export const JobItem = ({ contract, title, file, url, date, location = "Paris" }) => (
    <a className="jobItem" href={file ? file : url} target = "_blank">
        <h3 className="jobItemTitle">{title}</h3>
        <div className="jobItemInfo">
            {contract &&
                <div><FontAwesomeIcon icon={faBriefcase} />{" "}{contract}</div>
            }
            <div><FontAwesomeIcon icon={faMapMarkerAlt} />{" "}{location}</div>
            {date &&
                <div><FontAwesomeIcon icon={faCalendarDays} />{" "}{date}</div>
            }
        </div>
    </a>
);
