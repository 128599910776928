import React from "react";
import styled, { css, keyframes } from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";

class Start extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            shouldAppear: true
        };
        this.reference = React.createRef();
    }

    handleClick = () => {
        this.props.onClick();
        this.setState({
            shouldAppear: false
        });
    };

    render() {
        return (
            <div>
                {this.state.shouldAppear ? (
                    <Wrapper isVisible={this.state.shouldAppear}>
                        <FontAwesomeIcon icon={faPlayCircle} size='3x' onClick={this.handleClick} />
                    </Wrapper>
                ) : null}
            </div>
        );
    }
}

const appearAnim = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
    position: fixed;
    cursor: pointer;
    z-index: 2;
    top: 50%;
    left: 50%;
    ${props =>
        props.isVisible
            ? css`
                  display: inline;
                  animation: ${appearAnim} 1s ease;
              `
            : null};
`;

export default Start;
