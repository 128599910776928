import React from 'react';

const MusicSvg = () => (
	<div className="logotech">
		<svg fill="none" viewBox="0 0 48 48" width="40%" xmlns="http://www.w3.org/2000/svg">
			<path d="m0 0h48v48h-48z" fill="#fff" fillOpacity=".01"/>
			<g stroke="#000" strokeLinecap="round" strokeLinejoin="round" strokeWidth="4">
				<path d="m5 42h5"/><path d="m5 36h5"/><path d="m5 30h5"/>
				<path d="m5 24h5"/><path d="m16 42h5"/><path d="m16 36h5"/>
				<path d="m16 30h5"/><path d="m16 24h5"/><path d="m16 18h5"/>
				<path d="m16 12h5"/><path d="m16 6h5"/><path d="m27 42h5"/>
				<path d="m38 42h5"/><path d="m27 36h5"/><path d="m38 36h5"/>
				<path d="m27 30h5"/><path d="m38 30h5"/><path d="m38 24h5"/>
				<path d="m38 18h5"/>
			</g>
		</svg>
		

	</div>
);

export default MusicSvg;
