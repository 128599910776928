import React from 'react';

const HalSvg = () => (
    <div className="logotech">
        <svg
            xmlns="http://www.w3.org/2000/svg"
            width="40%"
            id="HAL_9000"
            version="1.1"
            viewBox="0 -10 131 131"
            xmlSpace="preserve"> 
            <g transform="translate(-42.711311,-53.961311)">
                <path
                    strokeWidth="0.26458332"
                    d="m 108.51131,53.961311 c -30.687163,0 -55.562495,24.875332 -55.562495,55.562499 0,30.68717 24.875332,55.5625 55.562495,55.5625 30.68717,0 55.5625,-24.87533 55.5625,-55.5625 0,-30.687167 -24.87533,-55.562499 -55.5625,-55.562499 z m 0,104.179689 c -26.850705,0 -48.617182,-21.76648 -48.617182,-48.61719 0,-26.850709 21.766477,-48.617186 48.617182,-48.617186 26.85071,0 48.61719,21.766477 48.61719,48.617186 0,26.85071 -21.76648,48.61719 -48.61719,48.61719 z m 0,-90.289064 c -23.014247,0 -41.67187,18.657623 -41.67187,41.671874 0,23.01425 18.657623,41.67188 41.67187,41.67188 23.01425,0 41.67188,-18.65763 41.67188,-41.67188 0,-23.014251 -18.65763,-41.671874 -41.67188,-41.671874 z M 86.059832,96.318457 79.210828,90.878359 c 1.555485,-2.439723 3.410479,-4.672541 5.510477,-6.647921 l 5.426075,6.824663 c -1.575594,1.567656 -2.952221,3.336131 -4.087548,5.263356 z M 91.059663,79.49572 c 3.848365,-2.240757 8.167159,-3.762111 12.771437,-4.383088 l 1.03293,8.621448 c -3.58113,0.502179 -6.931286,1.730375 -9.889592,3.534833 z m 17.451647,36.9734 c -3.83645,0 -6.94531,-3.10885 -6.94531,-6.94531 0,-3.83646 3.10886,-6.94531 6.94531,-6.94531 3.83646,0 6.94531,3.10885 6.94531,6.94531 0,3.83646 -3.10885,6.94531 -6.94531,6.94531 z m 13.53688,-29.199942 c -2.95831,-1.804723 -6.3082,-3.032654 -9.88933,-3.534833 l 1.03267,-8.621448 c 4.60428,0.620977 8.92307,2.142331 12.77143,4.383087 z m 8.91487,9.049279 c -1.13559,-1.927225 -2.51222,-3.6957 -4.08755,-5.263356 l 5.42608,-6.824663 c 2.09999,1.97538 3.95472,4.208198 5.51021,6.647921 z"
                />
            </g>
        </svg>
    </div>
)

export default HalSvg;